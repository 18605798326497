import React from 'react'
import classesButton from "./ButtonFilter.module.scss";
const ButtonFilter = ({label,onClick, classes}) => {

    const rootClass = classes && classes.root ? classes.root : null;

    return (
        <div className={`${classesButton.containerButton} ${rootClass} `}>
            { label && <label>{label}</label>}

            <i className={`icon-note`} onClick={onClick} />

            
        </div>
    )
}


export default ButtonFilter;