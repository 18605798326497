import React from 'react'
import classes from './Grid.module.scss'

export const Row = ({ children, grid, className }) => {

    const classesRow = grid ? classes[grid] : classes.Row
    return (
        <div className={`${classesRow} ${className}`}>
            {children}
        </div>
    )
}

export const Col = ({ children }) => {
    return (
        <div className={classes.Col}>
            {children}
        </div>
    )
}
