//DEV
// const BASE_URL = 'https://api.onesixthexpeditions.com/development/v1';
const BASE_URL = 'https://apiatria.knoknok.com.ar/development/v1';
// PROD
//const  BASE_URL = 'https://api.onesixthexpeditions.com/production/v1';
//Test data
//const BASE_URL = 'http://localhost:9000'

export default {
    BASE_URL: BASE_URL,
    login: `${BASE_URL}/login`,
    loginRecovery: `${BASE_URL}/login-recovery`,//post
    passwordReset: `${BASE_URL}/reset-password`,//put
    user: `${BASE_URL}/users`,
    logout: `${BASE_URL}/logout`,
    contact: `${BASE_URL}/contacts`,
    client: `${BASE_URL}/clients`,
    suppliers: `${BASE_URL}/suppliers`,
    destinations: `${BASE_URL}/destinations`,
    booking: `${BASE_URL}/booking`,
    bookingRates: `${BASE_URL}/booking-rates?id_booking=`,
    bookingRatesStates: `${BASE_URL}/booking-rates-states?state=1`,
    files: `${BASE_URL}/files`,



    // components service
    getRoles: `${BASE_URL}/users-roles`,
    countries: `${BASE_URL}/countries?state=1`,
    currencies: `${BASE_URL}/currencies?state=1`,
    client_types: `${BASE_URL}/client-types?state=1`,
    iva_conditions: `${BASE_URL}/iva-conditions?state=1`,
    document_types: `${BASE_URL}/document-types?state=1`,
    supplier_types: `${BASE_URL}/supplier-types?state=1`,
    languages: `${BASE_URL}/languages?state=1`,
    payment_methods: `${BASE_URL}/payment-methods?state=1`,
    relations_agency: `${BASE_URL}/relations-agency?state=1`,
    contact_active: `${BASE_URL}/contacts?state=1`,
    client_active: `${BASE_URL}/clients?state=1`,
    destination_active: `${BASE_URL}/destinations?state=1`,
    aiports: `${BASE_URL}/airports?state=1`,
    services: `${BASE_URL}/services`,
    acomodations_types:`${BASE_URL}/accomodation-types`,
    hotels: `${BASE_URL}/suppliers?state=1&id_supplier_type=1&id_destination=`, //GET,
    rates_types:`${BASE_URL}/rate-types?state=1`,
    rates_conditions:`${BASE_URL}/rate-conditions?state=1`,
    rate_duplicate:`${BASE_URL}/supplier-rates`,
    bed_types: `${BASE_URL}/bed-types?state=1`,
};