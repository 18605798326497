import { useFormikContext } from "formik";
import React, { useContext } from "react";
import { AddFormButton } from "../../../..";
import { Select } from "../../../../Select/Select";
import { context } from "../../../../../utils/CustomHook";
import { SettingInput } from "./SettingInput";
import classes from "./SettingsComponent.module.scss";

export default function SettingsComponent() {
    const { Languages, BedTypes } = useContext(context);
    const { values, setFieldValue } = useFormikContext();
    const mapToSelect = (arr) => arr?.map((l) => ({ value: l.id, label: l.name }));
    const optionsLanguages = mapToSelect(Languages);
    const optionsBedTypes = mapToSelect(BedTypes);
    const optionsBase = [
        { value: "DBL", label: "DBL" },
        { value: "SGL", label: "SGL" },
    ];

    const handleUpdateLanguage = (data) => {
        const selected = Languages?.find((l) => data.value === l.id);
        setFieldValue("language", selected);
    };

    const handleOnChange = (key) => (data) => {
        setFieldValue(key, data.target.value);
    };

    const handleAddNewRoom = () => {
        const newEmptyRoom = {
            rooms_number: values.setting_hotels.length + 1,
            base: "SGL",
            bedType: {
                code: "Single",
                name: "SGL",
                id: 1,
                state: 1,
            },
            paxs: [],
        };
        values.setting_hotels.push(newEmptyRoom);
        setFieldValue(values, "setting_hotels", values.setting_hotels);
    };

    const handleUpdateRoom = (index, key) => (data) => {
        const updatedSettings = values.setting_hotels.concat([]);
        updatedSettings[index][key] = +data.target.value;
        setFieldValue("setting_hotels", updatedSettings);
    };

    const handleUpdateRoomBedType = (index) => (data) => {
        const selected = BedTypes?.find((l) => data.value === l.id);
        values.setting_hotels[index].bedType = selected;
        setFieldValue("setting_hotels", values.setting_hotels);
    };

    const handleUpdateOptionsBase = (index) => (data) => {
        const selected = optionsBase?.find((l) => data.value === l.value);
        values.setting_hotels[index].base = selected.value;
    };

    const handleRemoveRoom = (index) => {
        values.setting_hotels.splice(index, 1);
        setFieldValue(values, "setting_hotels", values.setting_hotels);
    };

    return (
        <div className={classes.containerTableSettings}>
            <div className={classes.containerTitleSettings}>
                <h2>Settings</h2>
                <AddFormButton label={"Add Room"} handleOnClick={handleAddNewRoom} />
            </div>

            <div className={classes.col7}>
                <span>Language:</span>
                <div className={classes.language}>
                    <Select
                        className={classes.select}
                        onChange={handleUpdateLanguage}
                        defaultValue={optionsLanguages?.find((o) => o.value === values.language.id)}
                        options={optionsLanguages}
                    />
                </div>
            </div>
            {values.setting_hotels.map((s, index) => (
                <div key={index} className={classes.col7}>
                    <span>Hotels Rooms:</span>
                    <div className={classes.settingHotels}>
                        <div>
                            Rooms Number
                            <input
                                type="number"
                                defaultValue={s.rooms_number}
                                onChange={handleUpdateRoom(index, "rooms_number")}
                            />
                        </div>
                        <div>
                            Base
                            <Select
                                placeholder=""
                                defaultValue={optionsBase.find((o) => o.value === s.base)}
                                options={optionsBase}
                                onChange={handleUpdateOptionsBase(index)}
                            />
                        </div>
                        <div>
                            Bedding
                            <Select
                                placeholder=""
                                defaultValue={optionsBedTypes?.find((o) => o.value === s.bedType.id)}
                                options={optionsBedTypes}
                                onChange={handleUpdateRoomBedType(index)}
                            />
                        </div>
                        <span>
                            <i className={classes.deleteIcon} onClick={() => handleRemoveRoom(index)} />
                        </span>
                    </div>
                </div>
            ))}
            <SettingInput
                title={"Especial Travel Motive:"}
                placeholder="Special Motive..."
                value={values.travel_motive}
                onChange={handleOnChange("travel_motive")}
            />
            <SettingInput
                title={"Airport Board:"}
                placeholder="Airport board..."
                value={values.airport_board}
                onChange={handleOnChange("airport_board")}
            />
            <SettingInput
                title={"Observation"}
                placeholder="Observations..."
                value={values.observations}
                onChange={handleOnChange("observations")}
            />
        </div>
    );
}
