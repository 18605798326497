import React from 'react';
import classes from  './App.module.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import RouterApp from './Routes'



function App() {
  return (
    <div className={classes.App}>
      <RouterApp />
    </div>

  );
}

export default App;
