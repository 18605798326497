
import React, { useState, useEffect, useRef } from 'react';
import classesSelect from './SelectForm.module.scss';
import { Dropdown, FormControl, Form } from 'react-bootstrap';
import { useField } from 'formik';
import _ from 'lodash'
import Select, { components } from 'react-select';


const SelectForm = ({ hiddenBorder = false, innerRef, callback, disabled, classic, name,
    label, list, mode, updateSelected, getValue = null, isClearable, placeholder, className, style }) => {

    const [field, meta, helper] = useField(name);
    const [showOptions, setShowOptions] = useState(false);
    const [valueSelect, setValueSelect] = useState(() => {
        let value = field.value ? { label: field.value, value: field.value } : null;
        return field.value && !classic ? getValue(field.value) : value;
    });


    useEffect(() => {


        if (!valueSelect && field.value) {
            setValueSelect(_.find(list, element => JSON.stringify(element.value) === JSON.stringify(field.value)))
        }

    }, [list])



    const handlerSelectItem = (props) => {

        if (callback) {
            callback();
        }
        if (updateSelected) {
            updateSelected(props.value)
        }

        if (!_.isEmpty(props)) {
            helper.setValue(props.value);
            setValueSelect(props);
        } else {
            helper.setValue(null);
            setValueSelect(null)
        }
    }
    const customStyles = {
        menu: (provided, state) => {
            return {
                ...provided,
                // minHeight: 35,
                background: '#FFF',
            }
        },
        menuList: (provided, state) => ({
            ...provided,
            background: '#fff',
        }),
        ...style

    }

    const borderHidden = hiddenBorder ? classesSelect.hiddenBorder : "";

    return (
        <div className={`${classesSelect.containerSelect} ${borderHidden} ${className} ${classesSelect[meta.error && !showOptions ? "activeError" : null]}`}>
            <Select
                options={list}
                components={{ DropdownIndicator }}
                onChange={handlerSelectItem}
                ref={innerRef}
                isLoading={_.isEmpty(list)}
                onMenuOpen={() => setShowOptions(true)}
                onMenuClose={() => setShowOptions(false)}
                placeholder={placeholder}
                value={valueSelect}
                isClearable={isClearable}
                isDisabled={disabled}
                className={classesSelect.select}
                styles={customStyles}


            />

            {meta.error && !showOptions ? <label className={classesSelect[meta.error ? "errorActive" : null]} > {meta.error}</label>
                : null}
        </div>

    )
}

export default SelectForm;


const DropdownIndicator = (props) => {

    const openMenu = props.selectProps.menuIsOpen ? classesSelect.activeSelect : "";
    return (
        <components.DropdownIndicator className={classesSelect.indicador} {...props}>
            <i className={`icon-arrow ${openMenu}`} />
        </components.DropdownIndicator>
    );
};