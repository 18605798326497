import { Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import { serviceBooking } from "../../../../../../utils/API/apiService";
import { accountAddSchema } from "../../../../../../utils/validate/forms";
import { Select } from "../../../../../Select/Select";
import DateInput from "../../../../../TextInputs/DateInput/DateInput";
import TextInputForm from "../../../../../TextInputs/InputForm/TextInputForm";
import classes from "./FormAddAccount.module.scss";
import moment from "moment";

export default function FormAddAccount({ bookingId, formRef, suppliers, destinations, onSubmit, loading, closeModal }) {
    const mapToSelect = (arr) => arr?.map((l) => ({ value: l.id, label: l.name + ((l.description) ? ' - ' + l.description : '') }));
    const optionsDestinations = mapToSelect(destinations);

    return (
        <Formik
            innerRef={formRef}
            validationSchema={Yup.object().shape(accountAddSchema)}
            initialValues={{
                bookingId: bookingId,
                destinationId: null,
                supplierId: null,
                rateId: null,
                serviceId: null,
                service: null,
                rate: null,
                date: moment().format("YYYY-MM-DD"),
                accountName: "",
            }}
            onSubmit={async (data) => {
                closeModal();
                loading(true);
                const supplier = suppliers?.find(s => s.id === data?.supplierId)
                const rate = suppliers
                    ?.find((supplier) => supplier.id === data?.supplierId)
                    ?.rates?.find((rate) => rate.id === data.rateId);

                const dataRateSelected = (rate) ? JSON.parse(rate.data) : undefined
                const service = dataRateSelected?.services?.find((s, index) => index === data?.serviceId)

                const cost = parseFloat(service?.amount)
                const alicuota = parseFloat(rate?.porcentajeIVA)
                const markup = parseFloat(supplier?.supplierCommisions?.markup)
                // const sale = cost + (cost * alicuota / 100) + (cost * markup / 100)
                const sale = (cost + (cost * alicuota / 100)) / markup

                const rateData = JSON.parse(rate?.data);
                const rateType = rateData?.services[0]?.rateTypes?.name;
                const dataCreateAccount = {
                    booking_rate: {
                        id: null,
                        booking: { id: data.bookingId },
                        supplier_rate: { id: data.rateId },
                        rate_name: data.accountName,
                        booking_rate_state: { id: 1 },
                        date: data.date,
                        rate_data: JSON.stringify({ services: [], date: data.date, rateType }),
                        cost: cost,
                        alicuota: alicuota,
                        markup: markup,
                        sale: sale
                    },
                };
                
                serviceBooking("createBookingRates", dataCreateAccount)
                    .then(onSubmit)
                    .catch((e) => {})
                    .finally(() => loading(false));
            }}
        >
            {({ values, setFieldValue, handleReset }) => {
                const suppliersOptions = mapToSelect(
                    suppliers?.filter((s) => s?.destination?.id === values.destinationId)
                );
                const ratesOptions = mapToSelect(suppliers?.find(({ id }) => id === values?.supplierId)?.rates);

                const rateSelected = suppliers?.find(({ id }) => id === values?.supplierId)?.rates?.find(r => r.id === values.rateId)
                const dataRateSelected = (rateSelected) ? JSON.parse(rateSelected.data) : undefined

                const servicesOptions = dataRateSelected?.services?.map((s, index) => ({ value: index, label: s.name + ' - ' + s?.rateTypes?.name + ' - USD' + s.amount }))
                // const serviceSelected = dataRateSelected?.services?.find((s, index) => index === values?.serviceId)

                return (
                    <div className={classes.formAddAccount}>
                        <h7>Destination</h7>
                        <Select
                            title="Destination"
                            placeholder="Destination"
                            options={optionsDestinations}
                            onChange={(d) => {
                                handleReset();
                                setFieldValue("destinationId", d.value);
                            }}
                        />
                        {values?.destinationId && (
                            <>
                                <h7>Supplier</h7>
                                <Select
                                    placeholder="Supplier"
                                    options={suppliersOptions}
                                    onChange={(s) => {
                                        setFieldValue("supplierId", s.value);
                                    }}
                                />
                            </>
                        )}
                        {values?.supplierId && (
                            <>
                                <h7>Rate</h7>
                                <Select
                                    placeholder="Rate"
                                    options={ratesOptions}
                                    onChange={(r) => {
                                        setFieldValue("rateId", r.value);
                                        setFieldValue("accountName", r.label);
                                    }}
                                />
                            </>
                        )}
                        {values?.rateId && (
                            <>
                                <h7>Account Name</h7>
                                <TextInputForm name="accountName" />
                                <h7>Date</h7>
                                <label className={classes.dateFormAddAccount}>
                                    <DateInput
                                        placeholder="Date"
                                        onChagedDate={(date) => setFieldValue("date", date)}
                                        value={moment(values.date).toDate()}
                                    />
                                </label>
                            </>
                        )}
                        {values?.rateId && (
                            <>
                                <h7>Service</h7>
                                <Select
                                    placeholder="Service"
                                    options={servicesOptions}
                                    onChange={(s) => {
                                        setFieldValue("serviceId", s.value);
                                    }}
                                />
                            </>
                        )}
                    </div>
                );
            }}
        </Formik>
    );
}
