import React, { useContext, useEffect, useRef } from 'react';
import { useField, Formik, Form, useFormikContext, } from 'formik';
import * as Yup from 'yup';
import classes from "./Destinations.module.scss";
import { TextInputForm, Avatar, SwitchForm, SelectForm, ColorPikerForm } from "../../index";
import { context } from '../../../utils/CustomHook';
import _ from 'lodash';
import { TextLabelCompent, TextInfo } from '../../Text/FactoryText';
import { MONTHS } from '../../../utils/Constants/Date';

import { TextInput, Select } from '../../NewComponents/index';
import { Row, Col } from '../../Grid/Grid';


const Admin = ({ data }) => {
    const { Countries, Airports } = useContext(context);
    const selectRegionRef = useRef(null);

    const mapListSelect = (list, nameLabel) => {
        return _.map(list, element => ({ value: element, label: element[nameLabel] }))
    }
    const getElementList = (value, labelName = "name") => {
        return { value, label: value[labelName] }
    }


    useEffect(() => {

        if (_.has(data, "destinations.country") && Countries) {
            getRegionList(data.destinations);
        }

    }, [Countries])

    const getRegionList = ({ country }) => {
        let contrySelected = _.find(Countries, c => c.name === _.get(country, "name"));
        return contrySelected ? _.map(contrySelected.regions, city => ({ label: city.name, value: city })) : [];
    }

    const handleSelectCountry = (value) => {
        setFieldValue('destinations.country', value);
        selectRegionRef.current.handleClearValue();

    }


    const { setFieldValue } = useFormikContext();

    return (
        <div className={classes.containerForm} >

            <div className={classes.containerUser} >
                <Avatar classes={{ root: classes.avatarName }} color={data.destinations.color} initials={data.destinations.claim} />
                <h2>{data.destinations.name}</h2>
            </div>
            <Row grid={`grid-3`} >
                <Col>
                    <TextInput
                        label={"Destination Name:"}
                        placeholder={"Destination Name..."}
                        name={"destinations.name"}
                        type={"text"}
                        classNameLabel={`firstCol`}
                    />
                </Col>
                <Col >
                    <TextInput
                        label={"Code:"}
                        placeholder={"Code..."}
                        name={"destinations.claim"}
                        type={"text"}
                        maxLength={3}

                    />
                </Col>

                <Col >
                    <TextLabelCompent label={"Color:"} />
                    <div className={classes.containerColor}>
                        <ColorPikerForm name={"destinations.color"}>
                            <Avatar classes={{ root: classes.avatar }} color={data.destinations.color} />
                        </ColorPikerForm>
                    </div>
                </Col>

            </Row>

            <Row grid={`grid-3`}>
                <Col >
                    <TextLabelCompent firstColum label={"Region:"} />
                    <Select
                        placeholder={"Select City"}
                        disabled={!_.get(data, "destinations.country")}
                        list={getRegionList(data.destinations)}
                        ref={selectRegionRef}
                        onChange={value => setFieldValue("destinations.region", value)}
                        value={_.get(data, "destinations.region")}
                        clear
                    />
                </Col>
                <Col className={classes.componentWithLabel}>
                    <TextLabelCompent label={"Country:"} />
                    <Select
                        name={"destinations.country"}
                        placeholder={"Select Country"}
                        list={mapListSelect(Countries, "name")}
                        onChange={handleSelectCountry}
                        value={_.get(data, "destinations.country")}
                        clear={true}

                    />

                </Col>



                <TextInfo />

            </Row>

            <Row grid={`grid-3`}>
                <Col >
                    <TextLabelCompent firstColum label={"Airport:"} />
                    <Select
                        placeholder={"Select Airport"}
                        list={mapListSelect(Airports, "name")}
                        onChange={value => setFieldValue('destinations.airport', value)}
                        value={_.get(data, "destinations.airport")}
                        clear={true}

                    />
                </Col>
                <Col>
                    <TextInput
                        placeholder={"Distance from Airport..."}
                        name={"destinations.distance_from_airport"}
                        type={"text"}
                        label={'Distance from Airport:'}
                    />
                </Col>
                <TextInfo />
            </Row>
            <Row grid={`grid-3`}>
                <Col >
                    <TextInput
                        placeholder={"Location..."}
                        label={"Location:"}
                        name={"destinations.location"}
                        type={"text"}
                        classNameLabel={`firstCol`}
                    />
                </Col>
                <Col >
                    <TextInput
                        label={"Weather:"}
                        placeholder={"Weather..."}
                        name={"destinations.weather"}
                        type={"text"}
                    />
                </Col>
                <TextInfo />
            </Row>

            <Row className={`grid-3`} >
                <TextSeason className={`firstCol`} typeSeason={"HS"} label={"High Season:"} />
                <Col >
                    <TextLabelCompent label={"From:"} />
                    <Select
                        value={_.get(data, 'destinations.hs_from')}
                        placeholder={"Select Month"}
                        list={MONTHS}
                        clear
                        mode={'classic'}
                        onChange={value => setFieldValue('destinations.hs_from', value)}
                    />
                </Col>
                <Col >
                    <TextLabelCompent label={"To:"} />
                    <Select
                        name={"destinations.hs_to"}
                        placeholder={"Select Month"}
                        list={MONTHS}
                        clear
                        mode={'classic'}
                        value={_.get(data, 'destinations.hs_to')}
                        onChange={value => setFieldValue('destinations.hs_to', value)}
                    />
                </Col>
            </Row>

            <Row className={`grid-3`} >
                
                <TextSeason className={`firstCol`} typeSeason={"MS"} label={"Mid Season:"} />
                <Col >
                    <TextLabelCompent label={"From:"} />
                    <Select
                        name={"destinations.ms_from"}
                        placeholder={"Select Month"}
                        list={MONTHS}
                        clear
                        mode={'classic'}
                        value={_.get(data, 'destinations.ms_from')}
                        onChange={value => setFieldValue('destinations.ms_from', value)}

                    />
                </Col>
                <Col >
                    <TextLabelCompent label={"To:"} />
                    <Select
                        name={"destinations.ms_to"}
                        placeholder={"Select Month"}
                        list={MONTHS}
                        clear
                        mode={'classic'}
                        value={_.get(data, 'destinations.ms_to')}
                        onChange={value => setFieldValue('destinations.ms_to', value)}

                    />
                </Col>
            </Row>
            <Row className={`grid-3`} >
                <TextSeason className={`firstCol`} typeSeason={"LS"} label={"Low Season:"} />
                <Col >
                    <TextLabelCompent label={"From:"} />
                    <Select
                        name={"destinations.ls_from"}
                        placeholder={"Select Month"}
                        list={MONTHS}
                        classic={true}
                        clear
                        mode={'classic'}
                        value={_.get(data, 'destinations.ls_from')}
                        onChange={value => setFieldValue('destinations.ls_from', value)}
                    />
                </Col>
                <Col className={classes.componentWithLabel}>
                    <TextLabelCompent label={"To:"} />
                    <Select
                        name={"destinations.ls_to"}
                        placeholder={"Select Month"}
                        list={MONTHS}
                        mode={'classic'}
                        clear
                        value={_.get(data, 'destinations.ls_to')}
                        onChange={value => setFieldValue('destinations.ls_to', value)}
                    />
                </Col>
            </Row>


            <Row grid={`grid-3`} >

                <SwitchForm classNameLabel={`firstCol`} borderBottom label={'All Year'} name={"destinations.sall_year"} />
                <TextInfo />
                <TextInfo />
            </Row>
            <Row grid={`grid-3`} >
                <SwitchForm classNameLabel={`firstCol`} label={'Active Destination'} borderBottom name={"destinations.state"} />
                <TextInfo />
                <TextInfo />
            </Row>




        </div>

    )
}

export default Admin;


const TextSeason = ({ className, label, typeSeason }) => {
    return (
        <div className={`${className} ${classes.containerSeason}`}>
            <span>{typeSeason}</span>
            <span>{label}</span>

        </div>)
}
