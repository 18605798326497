const MONTHS = [
    {
        "value": "JANUARY",
        "label": "January",
    },
    {
        "value": "FEBRUARY",
        "label": "February",
    },
    {
        "value": "MARCH",
        "label": "March",
    },
    {
        "value": "APRIL",
        "label": "April",
    },
    {
        "value": "MAY",
        "label": "May",
    },
    {
        "value": "JUNE",
        "label": "June",
    },
    {
        "value": "JULY",
        "label": "July",
    },
    {
        "value": "AUGUST",
        "label": "August",
    },
    {
        "value": "SEPTEMBER",
        "label": "September",
    },
    {
        "value": "OCTOBER",
        "label": "October",
    },
    {
        "value": "NOVEMBER",
        "label": "November",
    },
    {
        "value": "DECEMBER",
        "label": "December",
    }
]
export {
    MONTHS
}
