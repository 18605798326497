

import React, { useEffect } from 'react';
import {
    useTable,
    useExpanded,
    useFilters,
    useGlobalFilter,
    useAsyncDebounce
} from 'react-table';
import classes from './Table.module.scss';



const Table = ({ loading = false, columns: userColumns, data, className = {}, oneFilter = undefined, allFilters = undefined, search = undefined, renderRowSubComponent = null }) => {


    const filterTypes = React.useMemo(
        () => ({
            // Add a new fuzzyTextFilterFn filter type.
            //  fuzzyText: fuzzyTextFilterFn,
            // Or, override the default text filter to use
            // "startWith"
            text: (rows, id, filterValue) => {
                return rows.filter((row) => {
                    const rowValue = row.values[id];
                    return rowValue !== undefined
                        ? String(rowValue)
                            .toLowerCase()
                            .startsWith(String(filterValue).toLowerCase())
                        : true;
                });
            }
        }),
        []
    );


    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state: { expanded },
        visibleColumns,
        setAllFilters,
        setFilter

    } = useTable(
        {
            columns: userColumns,
            data,
            filterTypes
        },
        useExpanded, // Use the useExpanded plugin hook
        useFilters, // useFilters!
    )

    useEffect(() => {
        if (oneFilter) {
            setFilter(oneFilter);
        }

    }, [oneFilter]);



    useEffect(() => {
        if (allFilters) {
            setAllFilters(allFilters);
        }

    }, [allFilters]);







    return (
        <table {...getTableProps()} className={`${className.table ? className.table : classes.table} ${loading && classes.loading}`}>
            <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                    prepareRow(row)
                    const rowProps = row.getRowProps();

                    return (
                        // Use a React.Fragment here so the table markup is still valid
                        <React.Fragment key={rowProps.key}>
                            <tr {...rowProps} className={`${row.isExpanded ? classes.expandedRow : ''}`} >
                                {row.cells.map(cell => {
                                    return (
                                        <td className={cell.column.className} {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                    );
                                })}
                            </tr >
                            {/* We could pass anything into this */}
                            {renderRowSubComponent && <>
                                {row.isExpanded &&
                                    renderRowSubComponent({ row, rowProps, visibleColumns })}
                            </>}
                        </React.Fragment>
                    );
                })}
            </tbody>
        </table >
    )
}


export default Table;