import React,{useState, useRef, useEffect} from 'react'
import { ChromePicker } from 'react-color';
import classesColor from './ColorPikerForm.module.scss';
import {useField} from 'formik'



export default function ColorPikerForm({ name,children}) {

    const [field,_,  helpers] = useField(name);
    const [color, setColor] = useState(field.value)
    const [showDisplayColor, setShowDisplayColor] = useState(false);
    const [position, setPosition] = useState({});
    const colorRef=useRef();

    const handleClick = () => {
        setShowDisplayColor(!showDisplayColor)
      };
    
    const handleClose = () => {
        setShowDisplayColor(false)
      };
      const handleChanged=(color, event)=>{
        setColor(color.hex);
        helpers.setValue(color.hex)
      }

      useEffect(()=>{
            if(colorRef.current){
                setPosition({
                    top: colorRef.current.offsetTop,
                    left: colorRef.current.offsetLeft + colorRef.current.offsetWidth + 10,
                })

            }


      },[color])

      const popover = {
        position: 'absolute',
        zIndex: '2',
        background: "rgba(0,0,0,0)",
        ...position
      }
      const cover = {
        position: 'fixed',
        background: "rgba(0,0,0,0)",
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
      }
    return (
        <>
            <div onClick={handleClick}   className={classesColor.containerColorPicker}  ref={colorRef}>
                {children}
            </div>
            <div>
                {showDisplayColor ? <div style={popover}>
                    <div style={cover} onClick={handleClose} />
                    <ChromePicker  disableAlpha={true} color={color}  onChange={ handleChanged} />
                </div> : null}

            </div>

        </>
    )
}



