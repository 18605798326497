import React, { useState, useEffect } from 'react'
import Classes from './Hide.module.scss'
import 'bootstrap/dist/css/bootstrap.min.css';
import { ModalForm } from '../index'

const Hide = ({ title, children, classes, disabled, titleModal, onPressAdd }) => {

    const [showTable, setShowTable] = useState(false);
    const [modalShow, setModalShow] = useState(false);

    const open = showTable ? Classes.showElement : null;

    const handleColapse = () => {
        if (!disabled) {
            setShowTable(!showTable)
        }
    }

    useEffect(() => {
        if (disabled) {
            setShowTable(false)
        }
    }, [disabled])

    const handleOpenModal = (e) => {
        if (onPressAdd) {
            onPressAdd();
        } else {
            setModalShow(true);
        }
        e.stopPropagation()
    }

    const handleCloseModal = (e) => {
        setModalShow(false)
    }
    return (
        <div >
            <div className={Classes.continerHeader} >
                <div className={`${Classes.link} ${Classes.containeHeader}`} disabled={disabled} onClick={handleColapse} >

                    <i className={`icon-arrow ${open} ${Classes.arrow}  `} />

                    <label >{title}</label>

                    <i className={Classes.add} onClick={handleOpenModal} />
                </div>
            </div>
            <div className={Classes.containerCollapse} style={{ height: showTable ? "10rem" : "0" }}  >
                {children[0]}
            </div>
            {onPressAdd ?
                <>{children[1]}</>
                : <ModalForm showModal={modalShow} closeModal={handleCloseModal} title={titleModal} >
                    {children[1]}
                </ModalForm>
            }
        </div>
    )
}

export default Hide