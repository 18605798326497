import React, { useState, useEffect } from "react";
import classes from "./Rates.module.scss";
import { Table, AddFormButton, Avatar, Loading } from "../../index";
import { RATES_TYPES } from "../../../utils/Constants";
import _ from "lodash";
import FactoryForms from "./FactoryForms/FactoryForms";
import moment from 'moment';
import NewTable from '../../Table/Table';
import { serviceSuppliers } from '../../../utils/API/apiService';



const Rates = ({ data, setFieldValue, loading }) => {

  const [showLoading, setShowLoading] = useState(false);
  const currency = _.get(data, "suppliers.currency.iso", "USD");

  const [tableData, setTableData] = useState(data.suppliers.rates);
  const [showForm, setShowForm] = useState(false);
  const [rate, setRate] = useState(null);




  const hadleEditRate = (row) => {
    setShowForm(true);
    setRate(row);
  }

  const getDate = (newDate) => {
    return typeof newDate === "object" ? moment(newDate).format("YYYY-MM-DD") : newDate;
  }

  const handleDuplicate = async (values) => {

    setShowLoading(true);

    const { inserted_id } = await serviceSuppliers('duplicateRates', { rate: { ...values, data: JSON.stringify(values.data) } });

    setTableData(preTableData => {
      let sizeRates = tableData.length;

      const newValue = { ...values, id: inserted_id };
      setFieldValue(`suppliers.rates[${sizeRates}]`, newValue);
      return [...preTableData, newValue]
    });

    setShowLoading(false)

  }

  const handleAddRates = () => {
    setShowForm(true)
    setRate(null);
  }


  const columns = React.useMemo(
    () => [
      {
        Header: ' ',
        columns: [
          {
            Header: ' ',
            accessor: (originalRow, rowIndex) => {
              return originalRow.destination;
            },
            className: classes.col1,
            id: 'detination',
            Cell: ({ row }) => {
              return (
                <span>
                  <Avatar classes={{ root: classes.avatar }} color={row.original.destination.color} initials={row.original.destination.claim} />
                </span>)
            },
            disableFilters: true,
          },
          {
            Header: ' ',
            accessor: 'name',
            className: classes.col2,
            Cell: ({ row }) => {
              return (
                <span className={classes.letterName}>{row.values.name}</span>
              )
            },
            disableFilters: true

          },
          {
            Header: ' ',
            accessor: 'init_date',
            className: classes.col3,
            Cell: ({ row }) => {
              return (
                <span >
                  {getDate(row.values.init_date)}
                </span>
              )
            },
            disableFilters: true

          },
          {
            Header: ' ',
            className: classes.col4,
            accessor: 'end_date',
            Cell: ({ row }) => {
              return (
                <span>{getDate(row.values.end_date)}</span>
              )
            },
            disableFilters: true

          },
          {
            Header: ' ',
            accessor: 'state',
            className: classes.col5,
            Cell: ({ row }) => {
              return (
                <span
                  className={`${classes.status} ${classes[!!row.values.state ? "status-active" : ""]
                    } `}
                />
              )
            },
            disableFilters: true
          },
          {
            Header: ' ',
            id: 'arrow',
            className: classes.col6,
            Cell: ({ row }) => {
              const { isExpanded, toggleRowExpanded } = row;
              const handleOpen = () => {
                toggleRowExpanded(!isExpanded);

              };
              return (
                <i className={`icon-arrow  ${classes.iconArrow} ${isExpanded ? classes.open : ''} `} onClick={handleOpen} />
              )
            },
            disableFilters: true

          },
          {
            Header: ' ',
            className: classes.col7,
            id: 'edit',
            Cell: ({ row }) => {
              return (
                <i className={`icon-edit  ${classes.iconEdit}`} onClick={() => hadleEditRate(row.original)} />
              )
            },
            disableFilters: true

          },
          {
            Header: ' ',
            id: 'duplica',
            className: classes.col7,
            Cell: ({ row }) => {

              return (
                <i className={`icon-duplicate  ${classes.iconEdit}`} onClick={() => handleDuplicate(row.original)} />
              )
            },
            disableFilters: true

          },

        ]
      },
    ], [])


  const handleRate = (values) => {


    const index = _.findIndex(data.suppliers.rates, rate => rate.id === values.id);
    const { init_date, end_date } = values;
    values.init_date = getDate(init_date);
    values.end_date = getDate(end_date)
    if (!values.id) {
      let sizeRates = data.suppliers.rates.length;
      // const newValue = { ...values, id: _.uniqueId('NEW') };
      const newValue = { ...values };
      setFieldValue(`suppliers.rates[${sizeRates}]`, newValue);
      setTableData([...tableData, newValue])
    } else {
      const copyData = _.clone(data.suppliers.rates);
      copyData[index] = values;
      setFieldValue("suppliers.rates", copyData);
      setTableData(copyData);
    }

    setShowForm(false);

  };


  const RowTableExtra = ({ row }) => {

    return (
      <>
        {row.original.data.services.map((service, index) => {

          return (
            <tr key={index} style={{ background: "#eee" }} className={classes.rowExtra}  >
              <td className={classes.col1} >

              </td>
              <td className={classes.col2} >
                <span>{service.name}</span>
              </td>

              <td className={classes.col3}>
                <span className={classes.letterName}> {`${currency}  ${_.get(service, 'amount')}`}</span>
              </td>
              <td className={classes.col4}>
                <span >{row.original.porcentajeIVA ? `(${_.get(row, 'original.porcentajeIVA')})%` : ``}</span>
              </td>
              <td className={classes.col5} >
                <span
                  className={`${classes.status} ${classes[!!service.state ? "status-active" : ""]
                    } `}
                />
              </td>

              <td className={classes.container}>
                <span className={classes.letterName}>
                  {_.get(row, 'original.rateCondition.name',' ')?.substr(0, 3)}
                </span>
              </td>

              <td colSpan={2} className={classes.containerBulk} >
                <span>
                  {_.get(service, 'rateTypes.name')} RATE
                </span>
              </td>

            </tr>)

        })}
      </>
    )
  }

  return (

    <div className={classes.containerView}>
      <Loading style={{ width: '100%', height: "100%" }} showLoading={showLoading} />
      <div className={classes.containerHeader}>
        
        <h2>{_.get(data, "suppliers.name")}</h2>
        <AddFormButton
          classes={{ root: classes.buttonAddRate }}
          label={"ADD RATES"}
          handleOnClick={handleAddRates}
        />
      </div>
      <div className={`${!_.isEmpty(tableData) ? classes.containerTable : ""}`}>

        {!_.isEmpty(tableData) &&
          <NewTable
            data={tableData}
            columns={columns}
            renderRowSubComponent={RowTableExtra}

          />

        }
      </div>
      <FactoryForms
        data={data}
        onSubmit={handleRate}
        showForm={showForm}
        closeForm={() => setShowForm(false)}
        rate={rate}
      />
    </div>
  );
};

export default Rates;
