import React from 'react';
import classesInput from './TextInputForm.module.scss';
import { useField } from 'formik';


const TextInputForm = React.memo((props) => {

    const [field, meta, helpers] = useField(props.name);
    return (
        <div className={`${classesInput.containerText} ${classesInput[meta.error ? "error" : null]}`}>
            <input
                {...props}
                {...field}
            />

            {meta.error ? <label className={classesInput[meta.error ? "errorActive" : null]} > {meta.error}</label> : null}


        </div>
    )
})


export default TextInputForm;