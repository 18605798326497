import React, { useState, useEffect, useRef, useContext } from 'react';
import { useFormik, Formik, Form } from 'formik';
import * as Yup from 'yup';
import classes from "./FormAddDestination.module.scss";
import { Comments, ButtonForm, TextInputForm, Avatar, SwitchForm, SelectForm, ColorPikerForm, FormAddDestinations } from "../../index";
import _ from 'lodash';
import { clientSchema } from '../../../utils/validate/forms';
import { sendNotification } from '../../../utils/helper/notification';
import { useGetCountries, context } from '../../../utils/CustomHook';
import { serviceDestinations } from '../../../utils/API/apiService';


import { Row } from '../../Grid/Grid';
import { TextInput, Select } from '../../NewComponents/index'


const FormAddDestination = ({ data, typeSubmit, closeModal, formRef }) => {

  const { dataServer, setDataServer, loading } = useContext(context);
  const { Countries, Airports } = useContext(context);



  const mapListSelect = (list, nameLabel = 'name') => {
    return _.map(list, element => ({ value: element, label: element[nameLabel] }))
  }


  const selectRegionRef = useRef();

  const initialValues = { destination: { color: "#7d7d7d" }, comments: [] };


  const getRegionList = ({ country }) => {
    let contrySelected = _.find(Countries, c => c.code === _.get(country, "code"));
    return contrySelected ? _.map(contrySelected.regions, city => ({ label: city.name, value: city })) : [];
  }


  const updateTable = (values) => {

    const index = _.findIndex(dataServer, item => item.id === values.id);
    if (index < 0) {
      setDataServer([...dataServer, values])
    }
    else {
      const newDataServer = dataServer;
      newDataServer[index] = values;
      setDataServer(newDataServer)
    }
  }

  const handleSubmitForm = async values => {

    const { destination, comments } = values
    try {
      closeModal();
      loading(true);

      let { inserted_id, response } = await serviceDestinations("createdDestinations", { destination, comments, event: "destination" });
      const user = JSON.parse(localStorage.getItem("userData"));
      values = {
        ...destination, region: { ...destination.region, country: destination.country },
        comments, id: inserted_id, user_create: user
      }
      sendNotification(response, "success", 2000)

      updateTable(values);
      loading(false);

    } catch (error) {
      sendNotification(error.message, "danger", 2000)
      loading(false);
      closeModal();

    }
  }



  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmitForm}
      //validationSchema={Yup.object().shape(clientSchema)}
      innerRef={formRef}
      validateOnChange={false}
      validateOnBlur={false}
      enableReinitialize={true}
    >
      {({ handleSubmit, handleChange, handleBlur, values, setFieldValue, errors }) => {


        const handleSelectCountry = (value) => {
          setFieldValue('destination.country', value);
          selectRegionRef.current.handleClearValue();

        }

        return (

          <div className={classes.containerForm}>
            <Row >
              <TextInput
                placeholder={"Name Destination..."}
                name={"destination.name"}
                type={"text"}
              />

              <Select
                placeholder={"Select Airports"}
                list={mapListSelect(Airports, "name")}
                onChange={value => setFieldValue("destination.airport", value)}
                value={_.get(values, "destination.airport")}
                clear={true}
              />
            </Row>
            <Row >

              <Select
                placeholder={"Select Country"}
                list={mapListSelect(Countries)}
                onChange={handleSelectCountry}
                value={_.get(values, "destination.country")}
                clear={true}

              />

              <Select
                placeholder={"Select Province..."}
                disabled={!_.get(values, "destination.country")}
                list={getRegionList(values.destination)}
                ref={selectRegionRef}
                onChange={value => setFieldValue("destination.region", value)}
                value={_.get(values, "destination.region")}
                clear
              />
            </Row >
            <Row >
              <SwitchForm borderBottom name={"destination.state"} label={"Active"} />
              <TextInput
                placeholder={"Claim..."}
                name={"destination.claim"}
                type={"text"}
                maxLength={3}
              />

            </Row >

            {/*  <Comments activeClient={{ valueClient: false }} values={values} name={"comments"} setFieldValue={setFieldValue}
              handleSubmitComment={handleSubmitComment} handleDeleteComment={handleDeleteComment} />
           <pre style={{ position: "absolute", top: "-50px", left: "-500px", color: "#fff" }}>{JSON.stringify(values, null, 2)}</pre>*/}
          </div>

        )

      }}

    </Formik>
  );
}
export default FormAddDestination;