import ServerConnect from './ServerConnect';


const login = async ({ user, password }) => {
    try {
        const res = await ServerConnect.login({
            user,
            password
        });
        if (res.status === 200) {
            localStorage.setItem('token', res.authentication);
            localStorage.setItem('userData', JSON.stringify(res.data));

            return Promise.resolve(res.data);
        }
        else {
            return Promise.reject(new Error(res.response));
        }
    } catch (error) {
        return Promise.reject({
            err: error
        })
    }
}

const loginRecover = async mail => {


    try {
        const res = await ServerConnect.loginRecover(
            mail
        );

        if (res.status === 200) {

            return Promise.resolve(res.data);
        }
        else {
            return Promise.reject(new Error(res.response));
        }

    } catch (error) {
        return Promise.reject({
            err: error
        })
    }

}

const resetPassword = async (password, token) => {


    try {
        const res = await ServerConnect.passwordReset(
            { password },
            token
        );

        if (res.status === 200) {

            return Promise.resolve(res.data);
        }
        else {
            return Promise.reject(new Error(res.response));
        }

    } catch (error) {
        return Promise.reject({
            err: error
        })
    }

}

const serviceUsers = async (service, user = null) => {

    try {

        const token = localStorage.getItem("token")
        const res = await ServerConnect[service]({ user, token })

        if (res.status === 200) {
            return res.data || res;
        } else {
            return Promise.reject(res.response);
        }


    } catch (error) {
        return Promise.reject({
            err: error
        })
    }

}

const serviceContact = async (service, contact = null) => {

    try {
        const token = localStorage.getItem("token")
        const res = await ServerConnect[service]({ contact, token })

        if (res.status === 200) {
            return res.data || res;
        } else {
            return Promise.reject(res.response || res);
        }


    } catch (error) {
        return Promise.reject({
            err: error
        })
    }

}

// servicio de clientes
const serviceClients = async (service, client = null) => {

    try {

        const token = localStorage.getItem("token")
        const res = await ServerConnect[service]({ client, token })

        if (res.status === 200) {
            return res.data || res;
        } else {
            return Promise.reject(res.response || res);
        }

    } catch (error) {
        return Promise.reject({
            err: error
        })
    }
}

const serviceSuppliers = async (service, supplier = null, id) => {

    try {

        const token = localStorage.getItem("token")

        const res = await ServerConnect[service]({ supplier, token, id })

        if (res.status === 200) {
            return res.data || res;
        } else {
            return Promise.reject(res.response || res);
        }

    } catch (error) {
        console.log(error)
        return Promise.reject({
            err: error
        })
    }
}

const serviceDestinations = async (service, destinations = null) => {

    try {

        const token = localStorage.getItem("token")
        const res = await ServerConnect[service]({ destinations, token })

        if (res.status === 200) {
            return res.data || res;
        } else {
            return Promise.reject(res.response || res);
        }

    } catch (error) {
        return Promise.reject({
            err: error
        })
    }
}

const serviceBooking = async (service, booking = null) => {

    try {

        const token = localStorage.getItem("token")
        const res = await ServerConnect[service]({ booking, token })

        if (res.status === 200) {
            return res.data || res;
        } else {
            return Promise.reject(res.response || res);
        }

    } catch (error) {
        return Promise.reject({
            err: error
        })
    }
}
//get Api for Components
const serviceComponents = async (service, data=null) =>{
    try{
        const token = localStorage.getItem("token");
        const res = await ServerConnect[service]({data,token})

        if (res.status === 200) {
            return res.data || res;
        } else {
            return Promise.reject(res.response || res);
        }
    
    }catch(error){
        return Promise.reject({
            err:error
        })
    }
}

const listElements = async (service) => {

    try {
        const token = localStorage.getItem("token")
        const res = await ServerConnect[service]({ token })

        if (res.status === 200) {
            return res.data || res;
        } else {
            return Promise.reject(res.response);
        }
    } catch (error) {
        return Promise.reject({
            err: error
        })
    }

}

export {
    login,
    loginRecover,
    serviceUsers,
    resetPassword,
    serviceContact,
    serviceClients,
    serviceSuppliers,
    serviceBooking,
    serviceDestinations,
    listElements,
    serviceComponents
}
