import React from 'react'
import classesButton from "./AddFormButton.module.scss";
const AddFormButton = ({ label, handleOnClick, classes }) => {

    const rootClass = classes && classes.root ? classes.root : null;

    return (
        <div className={`${classesButton.containerButton} ${rootClass} `}>
            {label && <label>{label}</label>}
            <button onClick={handleOnClick} />
        </div>
    )
}


export default AddFormButton;