import moment from 'moment';
/** name: generateNewGlobalValue
 *  just generate a global variable with a change listener
 * params: 
 *  defaultValue: just a defaultValue to set on the new globalVariable
 *  
 * RETURN: created global variable
*/

const generateNewGlobalValue = (defaultValue, listener = () => { }) => {
    return {
        aInternal: defaultValue,
        aListener: listener,
        set a(val) {
            this.aInternal = val;
            this.aListener(val);
        },
        get a() {
            return this.aInternal;
        },
        registerListener: function (listener) {
            this.aListener = listener;
        }
    }
}

/** name:getDateAndTime 
 *  
 * params: 
 *  
 *  
 * RETURN:  get current time and date
*/

const getDateAndTime = () => {
    const today = new Date();

    const time =  moment(today).format('LT').substr(-2) === "AM" ? 0 + moment(today).format('LT').substr(0,4): moment(today).format('LT').substr(0,5);
    const date = `${today.getFullYear()}-${today.getMonth()}-${today.getDate()}`;

    return { time, date };

}



export {
    generateNewGlobalValue,
    getDateAndTime 
}