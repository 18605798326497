
import classes from './Clients.module.scss';

import React, { useState, useEffect } from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';

import {
  Loading, SearchInput, Table, Avatar, ModalAddClient, ControlledTabs, AddFormButton, FormAddClient
} from "../../components";


import ModalTabs from '../../components/Modal/ModalTabsSuppliers'
import { serviceClients } from '../../utils/API/apiService';
import { context, useGetListsForClient } from '../../utils/CustomHook'
import _ from 'lodash';
import { Form } from 'react-bootstrap';

const Clients = (props) => {

  const [dataServer, setDataServer] = useState([]);
  const [search, setSearch] = useState("");
  const [showLoading, setShowLoading] = useState(true);
  const [showModal, setShowModal] = useState(false)
  const [rowSelected, setRowSelected] = useState(null);
  const [modalShow, setModalShow] = useState(false);


  const lists = useGetListsForClient();

  useEffect(() => {

    if (_.isEmpty(dataServer)) {
      serviceClients("getClients")
        .then(res => {
          setDataServer(res);
          setShowLoading(false)
        })
        .catch(error => { console.log(error) }
        )
    }

  }, [dataServer])

  const handleEdit = (data) => {
    setRowSelected(data);
    setShowModal(true)
  }

  const headers = [
    {
      key: "name",
      colSpan: 1,
      label: "NAME"

    },
    {
      key: "address",
      colSpan: 1,
      label: "ADDRESS"

    },
    {
      key: "country",
      colSpan: 1,
      label: "COUNTRY"

    },
    {
      key: "phone",
      colSpan: 1,
      label: "PHONE"

    },
    {
      key: "clientype",
      colSpan: 1,
      label: "CLIENT TYPE"
    },
    {
      key: "functions",
      colSpan: 2,
      label: ""

    }
  ]

  const fields = [
    {
      key: "name",
      decorator: (props) => (
        <span>{props.row.name}</span>
      ),
      classes: classes.col1
    },
    {
      key: "address",
      decorator: (props) => (
        <span>{props.row.address}</span>
      ),
      classes: classes.col2
    },

    {
      key: "country",
      decorator: (props) => (
        <span>{props.row.region.country.name}</span>
      ),
      classes: classes.col3
    },

    {
      key: "phone",
      decorator: (props) => (
        <span>{props.row.phone}</span>
      ),
      classes: classes.col4
    },

    {
      key: "clienType",
      decorator: (props) => (
        <span>{props.row.clientType.name}</span>
      ),
      classes: classes.col5
    },
    {
      key: "state",
      decorator: (props) => (
        <span className={`${classes.status} ${classes[!!props.row.state ? "status-active" : ""]} `} />
      ),
      classes: classes.col6
    },

    {
      key: "",
      decorator: (props) => (
        <span> <Avatar firstName={props.row.user_create.name} lastName={props.row.user_create.lastname}
          color={props.row.user_create.color} classes={{ root: classes.avatar }} /></span>
      ),
      classes: classes.col7
    },

    {
      key: "state",
      decorator: (props) => (
        <i className={`icon-edit ${classes.iconEdit}`} onClick={() => handleEdit(props.row)} />
      ),
      classes: classes.col8
    }
  ]
  const handleOpenModal = () => {
    setModalShow(true);
  }

  const handleCloseModal = () => {
    setModalShow(false);
  }

  return (

    <context.Provider
      value={{
        dataServer,
        setDataServer,
        loading: setShowLoading,
        handleFilterData: (e) => e,
        ...lists
      }}
    >

      <h2 className={classes.fontH2}>CLIENTS</h2>
      <Loading showLoading={showLoading} />
      <div className={classes.containerActionsElements}>
        <SearchInput classes={{ root: classes.searchInput }} handleSearch={(value) => setSearch(value)} placeholder={"Search...."} from_booking={false} />
        <AddFormButton
          classes={{ root: classes.addButtomPosition }}
          label={"ADD CLIENT"}
          handleOnClick={handleOpenModal}
        />
      </div>


      <ModalTabs title={"CLIENTS"} showModal={showModal} closeModal={() => setShowModal(false)} >
        <ControlledTabs data={rowSelected} />
      </ModalTabs>

      <ModalAddClient showModal={modalShow} closeModal={handleCloseModal} title={"CLIENT FORM"} showDelete className={{ modal: classes.modal }} >
        <FormAddClient />
      </ModalAddClient>

      <div className={classes.containerTable} >
        <Table headers={headers} fields={fields} search={search} sort={["name"]} />
      </div>
    </context.Provider>

  )
}

export default Clients;


