import React, { useEffect, useState } from 'react'
import { serviceUsers, listElements } from '../API/apiService';
import _ from 'lodash';


const context = React.createContext();

const useGetRolesUsers = () => {

    const [roles, setRoles] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {

        const fetchData = async () => {

            try {
                const res = await serviceUsers("getRolesUsers")
                setRoles(res);
                setLoading(true)

            } catch (error) {
                console.log(error)
            }
        };

        fetchData();

    }, [])


    return { roles, loading };
}
const useGetCountries = () => {

    const [countries, setCountries] = useState([]);

    useEffect(() => {

        const fetchData = async () => {

            try {
                const res = await listElements("getCountries");
                setCountries(res);

            } catch (error) {
                console.log(error)
            }
        };

        fetchData();

    }, []);
    return countries;
}


const useGetList = (nameList) => {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {

        const fetchData = async () => {

            try {
                const res = await listElements(nameList);
                setData(res);
                setLoading(false)

            } catch (error) {
                console.log(error)
                setLoading(false)
            }
        };

        fetchData();

    }, []);

    return { data, loading };
}

const useGetListsForClient = () => {

    const [lists, setList] = useState({});
    const nameLists = ["IvaConditions", "Countries", "Currencies", "DestinationActive", "Services",
        "ClientTypes", "PaymentMethods", "DocumentTypes", "Languages", "RelationsAgency", "SupplierTypes"]

    useEffect(() => {
        const fetchData = () => {
            const promises = []
            try {
                nameLists.forEach(nameList => {
                    promises.push(new Promise((resolve, reject) => resolve(listElements(`get${nameList}`))))
                })
                Promise.all(promises)
                    .then(result => {
                        const listsResult = {}
                        nameLists.forEach((key, index) => _.assign(listsResult, { [key]: result[index] }))
                        setList(listsResult)
                    })
            } catch (error) {
                console.log(error)
            }
        };
        fetchData();
    }, []);
    return lists;
}


const useGetListsForBooking = () => {

    const [lists, setList] = useState({});
    const nameLists = ["ContacsActive", "ClientsActive","Countries","Airports", "Languages", "BedTypes", "Suppliers", "BookingRatesStates", "Destinations"];
    useEffect(() => {
        const fetchData = () => {
            const promises = []
            try {
                nameLists.forEach(nameList => {
                    promises.push(new Promise((resolve, reject) => resolve(listElements(`get${nameList}`))))
                })
                Promise.all(promises)
                    .then(result => {
                        const listsResult = {}
                        nameLists.forEach((key, index) => _.assign(listsResult, { [key]: result[index] }))
                        setList(listsResult)
                    })
            } catch (error) {
                console.log(error)
            }
        };
        fetchData();
    }, []);
    return lists;
}

const useGetListsForSuppliers = () => {

    const [lists, setList] = useState({});
    const nameLists = ["IvaConditions", "Countries", "Currencies", "DestinationActive", "Services",
        "ClientTypes", "PaymentMethods", "DocumentTypes", "Languages", "RelationsAgency", "SupplierTypes",
        "AcomodationsTypes", "RateTypes", "RateConditions"]

    useEffect(() => {
        const fetchData = () => {
            const promises = []
            try {
                nameLists.forEach(nameList => {
                    promises.push(new Promise((resolve, reject) => resolve(listElements(`get${nameList}`))))
                })
                Promise.all(promises)
                    .then(result => {
                        const listsResult = {}
                        nameLists.forEach((key, index) => _.assign(listsResult, { [key]: result[index] }))
                        setList(listsResult)
                    })
            } catch (error) {
                console.log(error)
            }
        };
        fetchData();
    }, []);
    return lists;
}


const useGetListsForDestinations = () => {
    const [lists, setList] = useState({});
    const nameLists = ["Countries", "RelationsAgency", "Airports"]
    useEffect(() => {

        const fetchData = () => {

            const promises = []
            try {

                nameLists.forEach(nameList => {
                    promises.push(new Promise((resolve, reject) => resolve(listElements(`get${nameList}`))))
                })

                Promise.all(promises)
                    .then(result => {
                        const listsResult = {}
                        nameLists.forEach((key, index) => _.assign(listsResult, { [key]: result[index] }))
                        setList(listsResult)
                    })


            } catch (error) {
                console.log(error)
            }
        };

        fetchData();

    }, []);

    return lists;
}



export {
    useGetCountries,
    useGetRolesUsers,
    context,
    useGetList,
    useGetListsForClient,
    useGetListsForDestinations,
    useGetListsForSuppliers,
    useGetListsForBooking
}