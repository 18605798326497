import React, { useCallback, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import classes from './FileUpload.module.scss';
import { AddFormButton, ButtonForm } from '../index';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { sendNotification } from '../../utils/helper/notification';
import { getDateAndTime } from '../../utils/helper/helper';
import Uri from '../../utils/API/uris';
import moment from 'moment';
import { Header, Footer } from '../Modal/Components/Components'

import { useField } from 'formik'


const ModalUpload = ({ showModal, closeModal, callbak, id, entity }) => {

    const [files, setFiles] = useState([]);
    const [batchFiles, setBatchFiles] = useState([]);
    const data = { event: entity, model: { id } };
    const [isCompletedUploadFile, setIsCompletedUploadFile] = useState(false);
    const onDrop = useCallback(acceptedFiles => {
        setFiles([...files, ...acceptedFiles]);
        acceptedFiles.forEach((file, index) => {
        }, [files])
    })

    const handleConfirm = () => {
        callbak(batchFiles);
    }

    useEffect(() => {

        setIsCompletedUploadFile(batchFiles.length !== files.length);

    }, [batchFiles])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, maxSize: 100000000 })

    return (
        <Modal show={showModal}   className={classes.containerPortal}>
            <div className={classes.Modal}>

                <Header title={'ATTACH FILE'} onClose={closeModal} />
                <div className={classes.FileUpload}>

                    <div {...getRootProps()} className={classes.containerUploadFile}>
                        <input {...getInputProps()}
                        />

                        <i className={classes.iconAdd} />
                        {
                            isDragActive ?
                                <p>Drop the files here ...</p> :
                                <p>ADD FILES</p>
                        }


                    </div>


                    <div >
                        {files.map((file, index) => {

                            return (<File file={file}
                                key={file + index}
                                data={data}
                                callbak={(f) => setBatchFiles([...batchFiles, f])} />)
                        })}
                    </div>

                </div>
                <Footer >
                    <ButtonForm disabled={isCompletedUploadFile} handleClik={handleConfirm} type="button" label={"CONFIRM"} />
                    <ButtonForm handleClik={closeModal} type="button" label={"DELETE"} />

                </Footer>

            </div>
        </Modal>
    )
}

export default ModalUpload;


const File = ({ file, callbak, data }) => {

    const { date, time } = getDateAndTime();
    const [progress, setProgress] = useState(0);

    const token = localStorage.getItem("token")
    const options = {
        onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            let percent = Math.floor(loaded * 100) / total;
            setProgress(Math.round(percent));
        },
        headers: {
            'Accept': 'multipart/form-data',
            authentication: token,
        },
    }

    const user = JSON.parse(localStorage.getItem("userData"));

    const getUserAndDate = () => {
        return `${moment(date).format("L")}  ${time}
         ${user.name}  ${user.lastname}  has modified`
    }

    useEffect(() => {

        let formData = new FormData();
        formData.append('files[]', file);
        formData.append('data', JSON.stringify(data));

        axios.post(Uri.files, formData, options)
            .then(res => {
                callbak({
                    ...res.data[0].attachment
                });
                sendNotification("File uploaded successfully", "success", 2000);

            })
            .catch(err => {

            });


    }, [])

    return (
        <>
            <div className={classes.containerFile}>
                <div className={classes.fileName}>
                    {file.name}
                </div>
                <span>{getUserAndDate()}</span>

            </div>
            {progress < 100 && <ProgressBar now={progress} label={`${progress}%`} />}
        </>
    )
}