import React, { useState, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import Button from 'react-bootstrap/Button';
import { ButtonForm } from '../index'
import { get } from 'lodash'
import classes from './ModalForm.module.scss';
import { isEmpty } from "lodash";
import { Header, Footer, Modal, Errors } from './Components/Components'



const ModalForm = ({ showModal, closeModal, title, children, showDelete, className }) => {

    const [typeSubmit, setTypeSubmit] = useState("CREATED");

    const submitForm = () => {

        formRef.current.handleSubmit();

    }

    const formRef = React.createRef();

    useEffect(() => {
        if (!isEmpty(children.props.data)) {
            setTypeSubmit("UPDATED")
        } else {
            setTypeSubmit("CREATED");
        }

    }, [children.props.data])

    const newChildren = React.cloneElement(children, { closeModal, typeSubmit, formRef, Errors });

    return (
        <Modal show={showModal} sizes={'medium'}  >
            <div className={classes.modalBox} >

                <Header title={title} onClose={closeModal} />


                <div className={`${classes.formContainer} ${get(className, "modal")}`} >
                    {newChildren}
                </div>

                <Footer >
                    <ButtonForm handleClik={submitForm} type="button" label={"CONFIRM"} />

                </Footer>

            </div>

        </Modal>

    )
}








export default ModalForm;