import { useFormikContext } from "formik";
import React from "react";
import { TextInput } from "../../../../../NewComponents";
import DateInput from "../../../../../TextInputs/DateInput/DateInput";
import DateTime from "../../../../../TextInputs/DateTime/DateTime";
import classes from "../AccountRowDetail.module.scss";
import _ from "lodash";
import { Select } from "../../../../../Select/Select";
import moment from "moment";

export const AccountFlightForm = () => {
    const { values, setFieldValue } = useFormikContext();
    const parseTime = (time) => Date.parse(`01 Jan 2021 ${time} UTC-${(new Date).getUTCDate()}`);

    return (
        <div>
            <label className={classes.section}>
                Product Name{" "}
                <TextInput value={values?.rate_name} onChange={(e) => setFieldValue("rate_name", e.target.value)} />
            </label>
            <div className={classes.section}>
                Date
                <label className={classes.dateFormAddAccount}>
                    <DateInput
                        placeholder=""
                        onChagedDate={(date) => setFieldValue("rate_data.date", date)}
                        value={moment(values?.rate_data?.date).toDate()}
                    />
                </label>
                Hour
                <div className={classes.select}>
                    <DateTime
                        placeholder={""}
                        name={""}
                        onChagedDate={(value) => setFieldValue("rate_data.hour", value)}
                        value={parseTime(_.get(values, "rate_data.hour"))}
                    />
                </div>
            </div>
            <div className={classes.section}>
                Assosiated Flight
                <div className={classes.select}>
                    <Select
                        options={values.flights}
                        defaultValue={values.rate_data.flight}
                        onChange={(f) => {
                            setFieldValue("rate_data.flight", f);
                        }}
                    />
                </div>
            </div>
        </div>
    );
};
