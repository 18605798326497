import axios from 'axios';
import Uris from './uris.js';

const methods = {
    DELETE: 'DELETE',
    GET: 'GET',
    POST: 'POST',
    PUT: 'PUT'
};
export default class ServerConnect {

    // auth 
    static login = async values => await ServerConnect.fetchJson(Uris.login, methods.GET, { params: values });

    static loginRecover = async mail => await ServerConnect.fetchJson(Uris.loginRecovery, methods.POST, { body: mail });

    static passwordReset = async (values, token) =>
        await ServerConnect.fetchJson(Uris.passwordReset, methods.PUT, { body: values }, token);

    static logout = async token =>
        await ServerConnect.fetchJson(Uris.logout, methods.GET, null, token);

    //Users     
    static createdUser = async ({ user, token }) => await ServerConnect.fetchJson(Uris.user, methods.POST, { body: user }, token);

    static updatedUser = async ({ user, token }) => await ServerConnect.fetchJson(Uris.user, methods.PUT, { body: user }, token);

    static getUsers = async ({ token }) => await ServerConnect.fetchJson(Uris.user, methods.GET, {}, token);

    //Contacts
    static getContacts = async ({ token }) => await ServerConnect.fetchJson(Uris.contact, methods.GET, {}, token);

    static createdContact = async ({ token, contact }) => await ServerConnect.fetchJson(Uris.contact, methods.POST, { body: contact }, token);

    static updateContact = async ({ token, contact }) => await ServerConnect.fetchJson(Uris.contact, methods.PUT, { body: contact }, token);

    static deleteCommentContact = async ({ token, contact }) => await ServerConnect.fetchJson(Uris.contact, methods.DELETE, { body: contact }, token);

    //Client

    static deleteCommentClient = async ({ token, client }) => await ServerConnect.fetchJson(Uris.client, methods.DELETE, { body: client }, token);

    static updateClient = async ({ token, client }) => await ServerConnect.fetchJson(Uris.client, methods.PUT, { body: client }, token);

    static getClients = async ({ token }) => await ServerConnect.fetchJson(Uris.client, methods.GET, {}, token);

    static createdClient = async ({ token, client }) => await ServerConnect.fetchJson(Uris.client, methods.POST, { body: client }, token);

    //Suppliers

    static deleteCommentSupplier = async ({ token, supplier }) => await ServerConnect.fetchJson(Uris.suppliers, methods.DELETE, { body: supplier }, token);

    static getSuppliers = async ({ token }) => await ServerConnect.fetchJson(Uris.suppliers, methods.GET, {}, token);

    static createdSuppliers = async ({ token, supplier }) => await ServerConnect.fetchJson(Uris.suppliers, methods.POST, { body: supplier }, token);

    static updateSuppliers = async ({ token, supplier }) => await ServerConnect.fetchJson(Uris.suppliers, methods.PUT, { body: supplier }, token);

    static duplicateRates = async ({ token, supplier }) => await ServerConnect.fetchJson(Uris.rate_duplicate, methods.POST, { body: supplier }, token)

    //Destinations

    static deleteCommentDestinations = async ({ token, destinations }) => await ServerConnect.fetchJson(Uris.destinations, methods.DELETE, { body: destinations }, token);

    static getDestinations = async ({ token }) => await ServerConnect.fetchJson(Uris.destinations, methods.GET, {}, token);

    static createdDestinations = async ({ token, destinations }) => await ServerConnect.fetchJson(Uris.destinations, methods.POST, { body: destinations }, token);

    static updateDestinations = async ({ token, destinations }) => await ServerConnect.fetchJson(Uris.destinations, methods.PUT, { body: destinations }, token);

    static getHotels = async ({ token, id }) => await ServerConnect.fetchJson(`${Uris.hotels}${id}`, methods.GET, {}, token);

    //Booking

    static getBooking = async ({ token }) => await ServerConnect.fetchJson(Uris.booking, methods.GET, {}, token);
    
    static createdBooking = async ({ token, booking }) => await ServerConnect.fetchJson(Uris.booking, methods.POST, { body: booking }, token);
    
    static updateBooking = async ({token, booking }) => await ServerConnect.fetchJson(Uris.booking , methods.PUT, { body: booking }, token);//esto lo agregue pabloch
    
    static CreateCommentBooking = async ({token, booking }) => await ServerConnect.fetchJson(Uris.booking , methods.POST, { body: booking }, token);
    
    static deleteCommentBooking = async ({ token, booking }) => await ServerConnect.fetchJson(Uris.booking, methods.DELETE, { body: booking }, token);

    static getBookingRates = async ({ token, booking }) => await ServerConnect.fetchJson(`${Uris.bookingRates}${booking?.id}`, methods.GET, {}, token);
    
    static createBookingRates = async ({ token, booking  }) => await ServerConnect.fetchJson(`${Uris.bookingRates}0`, methods.POST, {body: booking }, token);
    
    static updateBookingRates = async ({ token, booking  }) => await ServerConnect.fetchJson(`${Uris.bookingRates}0`, methods.PUT, {body: booking }, token);

    static deleteBookingRates = async ({ token, booking  }) => await ServerConnect.fetchJson(`${Uris.bookingRates}0`, methods.DELETE, {body: booking }, token);
 
    static getBookingRatesStates = async ({ token }) => await ServerConnect.fetchJson(`${Uris.bookingRatesStates}`, methods.GET, {}, token);


    //get data for in component
    static delteFile = async ({ token, data }) => await ServerConnect.fetchJson(Uris.files, methods.DELETE, { body: data }, token);

    static getRolesUsers = async ({ token }) => await ServerConnect.fetchJson(Uris.getRoles, methods.GET, {}, token);

    static getCountries = async ({ token }) => await ServerConnect.fetchJson(Uris.countries, methods.GET, {}, token);

    static getCurrencies = async ({ token }) => await ServerConnect.fetchJson(Uris.currencies, methods.GET, {}, token);

    static getClientTypes = async ({ token }) => await ServerConnect.fetchJson(Uris.client_types, methods.GET, {}, token);

    static getSupplierTypes = async ({ token }) => await ServerConnect.fetchJson(Uris.supplier_types, methods.GET, {}, token);

    static getDocumentTypes = async ({ token }) => await ServerConnect.fetchJson(Uris.document_types, methods.GET, {}, token);

    static getLanguages = async ({ token }) => await ServerConnect.fetchJson(Uris.languages, methods.GET, {}, token);

    static getRelationsAgency = async ({ token }) => await ServerConnect.fetchJson(Uris.relations_agency, methods.GET, {}, token);

    static getIvaConditions = async ({ token }) => await ServerConnect.fetchJson(Uris.iva_conditions, methods.GET, {}, token);

    static getPaymentMethods = async ({ token }) => await ServerConnect.fetchJson(Uris.payment_methods, methods.GET, {}, token);

    static getContacsActive = async ({ token }) => await ServerConnect.fetchJson(Uris.contact_active, methods.GET, {}, token);

    static getClientsActive = async ({ token }) => await ServerConnect.fetchJson(Uris.client_active, methods.GET, {}, token);

    static getBedTypes = async ({ token }) => await ServerConnect.fetchJson(Uris.bed_types, methods.GET, {}, token);


    static getDestinationActive = async ({ token }) => await ServerConnect.fetchJson(Uris.destination_active, methods.GET, {}, token);

    static getAirports = async ({ token }) => await ServerConnect.fetchJson(Uris.aiports, methods.GET, {}, token);

    static getServices = async ({ token }) => await ServerConnect.fetchJson(Uris.services, methods.GET, {}, token);

    static getRateTypes = async ({ token }) => await ServerConnect.fetchJson(Uris.rates_types, methods.GET, {}, token);

    static getRateConditions = async ({ token }) => await ServerConnect.fetchJson(Uris.rates_conditions, methods.GET, {}, token);

    static getAcomodationsTypes = async ({ token }) => await ServerConnect.fetchJson(Uris.acomodations_types, methods.GET, {}, token);

    //manager http conection
    static async fetchJson(endpoint, method, { params = null, body = null }, authorization = null) {
       
        const rawResponse = await axios({
            url: endpoint,
            method,
            params,
            headers: {
                'content-type': 'application/json',
                authentication: authorization,
            },
            data: body

        });

        if (rawResponse.data.status === 304 && rawResponse.data.response === "Expired token") {

            localStorage.clear();
            const URLactual = window.location;
            window.location.replace(`${URLactual.origin}/login`);
        }


        return rawResponse.data;
    }

}

