import React, { useState, useRef, useEffect, useContext } from 'react'
import { ModalForm } from '../../../index'
import { RATES_TYPES } from '../../../../utils/Constants'
import { Formik, useFormikContext } from 'formik';
import { TextInputForm, SelectForm, SwitchForm, Checkbox, TextInputArea } from '../../../index';
import { TextLabelCompent } from '../../../Text/FactoryText';
import { context } from '../../../../utils/CustomHook';
import {TextInput} from '../../../NewComponents/index'
import DatePicker from 'react-datepicker'
import classes from './FactoryForm.module.scss'
import 'react-datepicker/dist/react-datepicker.css'
import _ from 'lodash';
import TextAddServices from '../../../TextInputs/TextAddServices/TextAddServices';
import TextAddBases from '../../../TextInputs/TextAddBases/TextAddBases';
import *  as  Yup from 'yup';

import { Select } from '../../../NewComponents/index'
const FactoryForms = ({ data, onSubmit, rate, showForm, closeForm }) => {


    const handleSubmit = (values) => {
        const { porcentajeIVA } = values;
        onSubmit({ ...values, porcentajeIVA: porcentajeIVA ? porcentajeIVA : "0.00" })
    }

    const getForm = (data, rate) => {
        return <FormHotel initialValues={rate} onSubmit={handleSubmit} />;
    }

    const title = _.isEmpty(rate) ? "ADD RATES" : "EDIT RATES";

    return (
        <ModalForm showModal={showForm} closeModal={closeForm} title={title} className={{ containerPortal: classes.containerPortal }}>
            {getForm(data, rate)}
        </ModalForm>
    )
}

export default FactoryForms;


const ChecboxOptions = ({ options, name, isMultiple = false }) => {

    const { setFieldValue, values } = useFormikContext()

    const [optionsValues, setOptionsValues] = useState(isMultiple ? _.get(values, name, []) : _.get(values, name));

    const handleSelectValues = (e, option) => {
        if (e.target.checked) {
            isMultiple ? setFieldValue(name, [...optionsValues, option]) : setFieldValue(name, option);
        } else {
            isMultiple ? setFieldValue(name, optionsValues.filter(op => !_.isEqual(op, option))) : setFieldValue(name, null);
        }

    }


    useEffect(() => {

        setOptionsValues(_.get(values, name, []));


    }, [JSON.stringify(_.get(values, name))])


    return (
        <div className={classes.cointainerCheckbox}>
            {options.map((option) => {
                const checked = isMultiple ? optionsValues.some(op => _.isEqual(op, option.value)) : _.isEqual(optionsValues, option.value);
                const disabled = isMultiple ? false : optionsValues && !checked;

                return (
                    <div className={classes.containerCheck} key={JSON.stringify(option.value)}>
                        <label>{option.label}</label>
                        <Checkbox checked={checked} onChange={(e) => handleSelectValues(e, option.value)} />
                    </div>
                )
            })}
        </div>
    )
}
const FormHotel = ({ initialValues, onSubmit, formRef }) => {


    const today = new Date();

    const { IvaConditions, DestinationActive, RateConditions, RateTypes, Services } = useContext(context);


    const valuesForm = _.isEmpty(initialValues) ? {
        data: {
            services: [],
            all_days: false

        },
        refund_iva: true,
        date_create: today,
        state: true,
    } : initialValues;



    const defaulDate = (name) => {

        const dateDestructured = _.get(initialValues, name, "").split("-");
        const date = _.has(initialValues, "init_date") ? new Date(dateDestructured[0], dateDestructured[1] - 1, dateDestructured[2]) : null;
        return date;

    }

    const mapListSelect = (list, nameLabel) => {
        return _.map(list, element => ({ value: element, label: element[nameLabel] }))
    }
    const getElementList = (value, labelName = "name") => {
        return value.id ? { value, label: value[labelName] } : null;
    }

    const notPercentage = [1, 5];

    const updatePercentage = (value, setFieldValue) => {
        if ( value && notPercentage.includes(value.id)) {
            setFieldValue('porcentajeIVA', null);
        }
    }

    const handleOnchageAllDays = (value, setFieldValue) => {

        if (value) {

            setFieldValue('data.aplicationDate', ["MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY", "SUNDAY"]);
        } else {

            setFieldValue('data.aplicationDate', []);
        } 
    }

    return (
        <Formik
            initialValues={valuesForm}
            onSubmit={onSubmit}
            innerRef={formRef}
            validateOnChange={false}
            validateOnBlur={false}
            enableReinitialize={true}
        >

            {({ handleSubmit, handleChange, handleBlur, values, setFieldValue, errors }) => {
                return (

                    <div className={classes.containerForm}>

                        <TextInput
                            placeholder={"Name..."}
                            name={"name"}
                            type={"text"}
                        ></TextInput>

                        <TextInputArea
                            name={'description'}
                            placeholder={'Description...'}
                            className={classes.containerTextArea}
                            borderBottom={true}
                        />

                        <div className={`${classes.row} `}>
                            <Select
                                name={"service"}
                                placeholder={"Service..."}
                                list={mapListSelect(Services, "name")}
                                value={_.get(values, 'service')}
                                onChange={value => setFieldValue('service', value)}
                                clear={true}
                            />
                            <Select
                                placeholder={"Selecciona Destino"}
                                list={mapListSelect(DestinationActive, "name")}
                                value={_.get(values, 'destination')}
                                onChange={value => setFieldValue('destination', value)}
                                clear={true}
                            />

                        </div>
                        <div className={classes.row} >
                            <DateInput
                                placeholder="Select Date of init"
                                onChagedDate={(date) => setFieldValue('init_date', date)}
                                value={defaulDate("init_date")}
                            />

                            <DateInput
                                placeholder="Select Date of end"
                                value={defaulDate("end_date")}
                                onChagedDate={(date) => setFieldValue('end_date', date)} />
                        </div>

                        <div className={classes.row}>
                            <Select hiddenBorder={true}
                                placeholder={"Condicion de IVA"}
                                list={mapListSelect(IvaConditions, "name")}
                                onChange={value => {
                                    setFieldValue('ivaCondition', value)
                                    updatePercentage(value, setFieldValue)
                                }}
                                value={_.get(values, 'ivaCondition')}
                                clear={true}
                            />
                            <Select
                                name={"rateCondition"}
                                placeholder={"Select Rates Conditions"}
                                list={mapListSelect(RateConditions, "name")}

                                value={_.get(values, 'rateCondition')}
                                onChange={value => setFieldValue('rateCondition', value)}
                                clear={true}
                            />

                        </div>


                        <div className={`${classes.row} ${classes.borderBottom}`}>

                            {!notPercentage.includes(_.get(values, 'ivaCondition.id')) &&
                                <ChecboxOptions
                                    name={"porcentajeIVA"}
                                    options={[
                                        {
                                            value: "21.00",
                                            label: "21%"
                                        },
                                        {
                                            value: "10.50",
                                            label: "10.50%"
                                        }
                                    ]}

                                />
                            }

                            <SwitchForm  name={"refund_iva"} label={"Aplica Reintegro IVA"} />
                        </div>

                        <div className={`${classes.containerItem} ${classes.borderBottom}`}>

                            <TextAddServices name={"data.services"} rateTypes={RateTypes} />
                        </div>

                        <TextInputArea
                            name={'aditional_information'}
                            placeholder={'Aditional Description...'}
                            className={classes.containerTextArea}
                            borderBottom={true}
                        />


                        <div className={classes.row}>
                            <SwitchForm className={`${classes.switch}`} name={"data.all_days"} label={"All Days"} onChange={(value) => handleOnchageAllDays(value, setFieldValue)} />

                            <SwitchForm  name={"state"} label={"Active"} className={`${classes.switch}`} />

                        </div >


                        <ChecboxOptions
                            isMultiple={true}
                            name={"data.aplicationDate"}
                            options={[
                                {
                                    value: "MONDAY",
                                    label: "Lu"
                                },
                                {
                                    value: "TUESDAY",
                                    label: "Ma"
                                },
                                {
                                    value: "WEDNESDAY",
                                    label: "Mi"
                                },
                                {
                                    value: "THURSDAY",
                                    label: "Ju"
                                },
                                {
                                    value: "FRIDAY",
                                    label: "Vi"
                                },
                                {
                                    value: "SATURDAY",
                                    label: "Sa"
                                },
                                {
                                    value: "SUNDAY",
                                    label: "Do"
                                }

                            ]}

                        />


                    </div>

                )

            }}

        </Formik >
    )
}




const DateInput = ({ onChagedDate, placeholder, value }) => {

    const [date, setDate] = useState(value);
    const handleOnChangeDate = (date) => {
        setDate(date);
        onChagedDate(date)
    }

    return (
        <div className={classes.containerDatePicker}>
            <DatePicker
                className={classes.dates}
                dateFormat="dd-MM-yyyy"
                selected={date}
                onChange={handleOnChangeDate}
                placeholderText={placeholder}
            /> </div>
    )

}
