import React, { useState, useEffect } from 'react';
import { ButtonForm } from '../index'
import { get } from 'lodash'
import classes from './ModalForm.module.scss';
import { isEmpty } from "lodash";
import { Header, Footer,Modal } from './Components/Components'

const ModalForm = ({ showModal, closeModal, title, children, showDelete, className }) => {

    const [typeSubmit, setTypeSubmit] = useState("CREATED");

    const submitForm = () => {
        formRef.current.handleSubmit();

    }

    const formRef = React.createRef();

    useEffect(() => {
        if (!isEmpty(children.props.data)) {
            setTypeSubmit("UPDATED")
        }

    }, [children.props.data])



    const newChildren = React.cloneElement(children, { closeModal, typeSubmit, formRef });


    return (
        <Modal show={showModal} sizes={'medium'}  >
            <div className={classes.modalBox} >

                <Header title={title} onClose={closeModal} />

                <div className={`${classes.formContainer} ${get(className, "modal")}`} >
                    {newChildren}
                </div>

                <Footer style={{ border: "hidden" }}>
                    <ButtonForm handleClik={submitForm} type="button" label={"CONFIRM"} />
                </Footer>

            </div>

        </Modal>

    )
}

export default ModalForm;