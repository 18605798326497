import React, { useCallback, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import classes from './FileUpload.module.scss';
import { AddFormButton, ButtonForm } from '../index';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { sendNotification } from '../../utils/helper/notification';
import { getDateAndTime } from '../../utils/helper/helper';
import Uri from '../../utils/API/uris';
import moment from 'moment';
import { remove, get } from 'lodash';

import { useField } from 'formik';
import ModalUpload from './ModalUpload'

const FileUpload = (props) => {

    const [showModal, setShowModal] = useState(false);

    const [field, meta, helpers] = useField(props.name);
    const updateValues = (values) => {
        helpers.setValue([...field.value, ...values]);
        setShowModal(false);
    }

    return (
        <div>
            <AddFormButton label={"ATTACH DOCUMENT"} classes={{ root: classes.buttonAdd }} handleOnClick={() => setShowModal(true)} />
            {showModal && <ModalUpload callbak={updateValues} showModal={showModal} {...props} closeModal={() => setShowModal(false)} />}
        </div>
    )
}
export default FileUpload;