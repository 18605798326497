
import classes from './ControlledDestinations.module.scss';
import React, { useState, useEffect, useContext } from 'react';
import Tabs from '../../Tabs/Tabs'
import { useFormik, Formik, Form } from 'formik';
import { Content, Info, Hotels } from '../../TabsView/Destinations'
import { serviceDestinations } from '../../../utils/API/apiService';
import { context } from '../../../utils/CustomHook';
import { sendNotification } from '../../../utils/helper/notification';
import 'bootstrap/dist/css/bootstrap.min.css';
import _ from 'lodash';
import Comments from '../../Comments/Comments';


const ControlledDestinations = ({ data, closeModal, formRef }) => {
  const [key, setKey] = useState('info');

  const keys = [
    { name: "INFO", key: "info" },
    { name: "CONTENT", key: "content" },
    { name: "HOTELS", key: "hotels" }
  ]

  const tranforInitialData = (values) => {
    return { destinations: { ..._.omit(values, "comments"), country: _.get(values, "region.country") }, ..._.pick(values, "comments") }
  }
  const initialValues = _.isEmpty(data) ? { destinations: { color: "#7d7d7d" }, comments: [] } : tranforInitialData(data);
  const { dataServer, loading, setDataServer } = useContext(context);

  const updataData = (destinations, comments) => {
    const copyDataServer = _.cloneDeep(dataServer)
    copyDataServer[_.findIndex(dataServer, cli => cli.id === destinations.id)] = {
      ...destinations, region: {
        ...destinations.region,
        country: destinations.country
      }, comments
    }
    setDataServer(copyDataServer);
  }

  const handleSubmitComment = async (comment, values) => {
    let { comments, destinations: destination } = values
    await serviceDestinations("createdDestinations", { destination, event: "comment", comment })

    comments.push(comment);
    updataData(destination, comments);

  }
  const handleDeleteComment = async (comment, values) => {
    const { destinations, comments } = values

    await serviceDestinations("deleteCommentDestinations", { comment })

    updataData(destinations, comments)
  }
  const handleSubmitForm = async (values) => {
    try {
      loading(true);
      closeModal();
      let { destinations:destination, comments } = values
      const { response } = await serviceDestinations("updateDestinations", { destination, event: "destinations", comments });

      updataData(destination, comments);

      sendNotification(response, "success", 2000);

      loading(false);
    } catch (error) {
      loading(false);
      sendNotification(error.message, "danger", 2000)
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmitForm}
      //  validationSchema={Yup.object().shape(contactSchema)}
      innerRef={formRef}
      validateOnChange={false}
      validateOnBlur={false}
      enableReinitialize={true}
    >

      {({ handleSubmit, handleChange, handleBlur, values, setFieldValue, errors }) => {

        return (
          <>

            <Tabs
              className={classes.Tabs}
              tabs={keys}
              goTo={(value) => {
                setKey(value);
              }}
              selected={key}
              classLetter={classes.letterTabs}
              classesTabs={classes.Tabs}
            />
            <div className={`${classes.containerForm} scrollbar `} >
            {key === "info" &&
              <Info data={values} setFieldValue={setFieldValue} />
            }
            {key === "content" &&
              <Content data={values} setFieldValue={setFieldValue} />
            }

            {key === "hotels" &&
              <Hotels data={values} setFieldValue={setFieldValue} />
            }
            <Comments values={values} name={"comments"} setFieldValue={setFieldValue}
              handleSubmitComment={handleSubmitComment} handleDeleteComment={handleDeleteComment} />
          </div>

          </>
        )
      }}
    </Formik>
  );
}

export default ControlledDestinations;