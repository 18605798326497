import React,{useEffect, useState} from 'react';
import Toast from 'react-bootstrap/Toast'
import Alert from 'react-bootstrap/Alert'
import {generateNewGlobalValue} from '../../utils/helper/helper';
import classes from './Snackbars.module.scss'

import _ from 'lodash';
const Snackbars = () => {


    const [notification, setNotification] = useState({});
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const addNewNotificate = (value) =>{
        setNotification(value);
        setOpenSnackbar(true);
        setTimeout(()=> setOpenSnackbar(false), value.duration );
        
    }

    useEffect(() => {
        
        if(!window.globalNotification){
            window.globalNotification = generateNewGlobalValue(null,addNewNotificate);
            
        }
        return () => {
            delete window.globalNotification;
        }

    },[notification])


    return (
        <Toast className={classes.container} show={openSnackbar} delay={notification.duration}>
            <Alert variant={notification.type} >{_.get(notification,"message","")}</Alert>
        </Toast>
    )
}


export default Snackbars;