import React, { useContext } from 'react';
import { useFormik, Formik, Form, useFormikContext } from 'formik';
import classes from "./FormAddBooking.module.scss";
import _ from 'lodash';
import { sendNotification } from '../../../utils/helper/notification';
import { context } from '../../../utils/CustomHook';
import { serviceBooking } from '../../../utils/API/apiService';
import DateInput from '../../TextInputs/DateInput/DateInput'

import { Row, Col } from '../../Grid/Grid';
import { TextInput, Select } from '../../NewComponents/index'

import { TextLabelCompent } from '../../Text/FactoryText';


const Formclient = ({ closeModal, formRef, setShowLoading, dataServer, setDataServer }) => {

    const { ContacsActive, ClientsActive } = useContext(context);

    const mapListSelect = (list, nameLabel = 'name') => {
        return _.map(list, element => ({ value: element, label: element[nameLabel] }))
    }

    const updateTable = (values) => {

        const index = _.findIndex(dataServer, item => item.id === values.id);
        if (index < 0) {
            setDataServer([...dataServer, values])
        }
        else {
            const newDataServer = dataServer;
            newDataServer[index] = values;
            setDataServer(newDataServer)
        }
    }

    const handleSubmitForm = async values => {
        closeModal();
        const { booking } = values
        try {
            setShowLoading(true);
            let { inserted_id, response } = await serviceBooking("createdBooking", { booking, event: "booking" });
            sendNotification(response, "success", 2000)
            setDataServer([...dataServer, values])
            window.location.reload();
            updateTable(booking);
            setShowLoading(false);
        } catch (error) {
            sendNotification(error.message, "danger", 2000)
            setShowLoading(false);
            closeModal();

        }
    }

    const getListNumber = _.range(100).map(number => ({ label: number, value: number }));


    return (

        <Formik
            initialValues={{}}
            onSubmit={handleSubmitForm}
            //validationSchema={}
            innerRef={formRef}
            validateOnChange={false}
            validateOnBlur={false}
            enableReinitialize={false}
        >

            {({ handleSubmit, handleChange, handleBlur, values, setFieldValue, errors }) => {

                return (

                    <div className={classes.containerForm}>

                        <Row >
                            <TextLabelCompent label="Client" />
                            <Select
                                placeholder={"Select Client"}
                                list={mapListSelect(ClientsActive)}
                                onChange={value => setFieldValue("booking.client", value)}
                                value={_.get(values, "client")}
                                clear
                            />

                        </Row >

                        <Row >
                            <TextLabelCompent label="Seller" />
                            <Select
                                placeholder={"Select Seller"}
                                list={mapListSelect(ContacsActive)}
                                onChange={value => setFieldValue("booking.seller", value)}
                                value={_.get(values, "seller")}
                                clear

                            />
                        </Row >

                        <Row >
                            <TextLabelCompent label="Reference" />
                            <TextInput
                                placeholder={"Reference..."}
                                name={"booking.reference"}
                                type={"text"}
                            />
                        </Row >

                        <Row >
                            <Col>
                                <TextLabelCompent label="Adults Paxs" />
                                <Select
                                    placeholder={"Select..."}
                                    list={getListNumber}
                                    onChange={value => setFieldValue("booking.adult_paxs", value)}
                                    value={_.get(values, "booking.adult_paxs")}

                                    search={false}
                                />

                            </Col>
                            <Col>
                                <TextLabelCompent label="Child Paxs" />
                                <Select
                                    placeholder={"Select..."}
                                    list={getListNumber}
                                    onChange={value => setFieldValue("booking.child_paxs", value)}
                                    value={_.get(values, "booking.child_paxs")}
                                    search={false}
                                />
                            </Col>

                        </Row >

                        <Row >
                            <DateInput
                                placeholder="Select Date of init"
                                onChagedDate={(date) => setFieldValue('booking.date_in', date)}
                                value={null}
                            />
                            <DateInput
                                placeholder="Select Date of out"
                                onChagedDate={(date) => setFieldValue('booking.date_out', date)}
                                value={null}
                            />

                        </Row >



                    </div>

                )

            }}

        </Formik>
    );
}
export default Formclient;