import React, { useContext, useEffect, useRef } from 'react';
import { useFormik, Formik, Form, } from 'formik';
import classes from "./Suppliers.module.scss";
import { FileView, TextInputArea, FileUpload } from "../../index";
import { context } from '../../../utils/CustomHook';
import _ from 'lodash';




const Legal = ({ data }) => {
    return (
        <div className={classes.containerLegal} >
            <div className={classes.containerText}>
                <div className={classes.containerHead}>
                    <h2>{data.suppliers.name}:</h2>
                </div>
                <TextInputArea className={classes.containerTextArea} cols={"100"} rows={"18"} name={"suppliers.legalDescription"} />

            </div>

            <div className={classes.containerFiles} >
                {data.suppliers.attachments.map((file, index) => <FileView type={"suppliers"} file={file} index={index} key={file.id} />)}
            </div>

            <FileUpload name={"suppliers.attachments"} entity={"supplier"} id={data.suppliers.id} />
        </div>
    )
}


export default Legal;