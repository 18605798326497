import React from "react";
import RSelect from "react-select";

export const Select = (props) => (
    <RSelect
        placeholder=""
        {...props}
        styles={{
            control: (base) => ({
                ...base,
                border: 0,
                // This line disable the blue border
                boxShadow: "none",
            }),
        }}
    />
);
