import React, { useState, useEffect, useContext } from 'react'
import { Table, Loading } from '../../index'
import classesView from './Destinations.module.scss'
import _ from 'lodash'
import { serviceSuppliers } from '../../../utils/API/apiService';


const Hotels = (props) => {
    const [dataServer, setDataServer] = useState([]);
    const [loading, setLoading] = useState(true);
    const [response,setResponse] =  useState(false);
    useEffect(() => {
        if (_.isEmpty(dataServer) && !response ) {
            serviceSuppliers("getHotels",_,props.data.destinations.id)
                .then(res => {
                    setDataServer(res);
                    setLoading(false);
                    setResponse(true);
                })
                .catch(error => {
                    setResponse(true);
                    console.log(error) }
                )
        }
    }, [dataServer])

    const fieldsHotels = [
        {
            key: "name",
            decorator: (props) => (
                <span>{props.row.name}</span>
            ),
            classes: classesView.col1
        },
        {
            key: "website",
            decorator: (props) => (
                <span>{props.row.website}</span>
            ),
            classes: classesView.col2
        },
        {
            key: "phone",
            decorator: (props) => (
                <span>{props.row.phone}</span>
            ),
            classes: classesView.col3
        }
    ]

    return (
        <div className={classesView.containerView}>
            <Loading showLoading={loading} className={classesView.containerLoading} />
            {!loading &&
                <div className={classesView.containerRowHide}>
                   {response && _.isEmpty(dataServer) ? <span>Not Found Hotels</span>
                   :<Table key={JSON.stringify(dataServer)} data={dataServer} fields={fieldsHotels} />}
                </div>
            }
        </div>
    )
}

export default Hotels