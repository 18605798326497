import React from 'react'
import Classes from './AddTags.module.scss'
import { TextInputForm } from '../../../index'
import { Formik } from 'formik'
import { TextInput } from '../../../NewComponents/index'

const FormAddTags = ({data, closeModal, formRef, setFieldValue }) => {
   
    const handleSubmitForm = async (values) => {
        setFieldValue(`destinations.destinationTags[${data.destinations.destinationTags.length}]`,values);
        closeModal();
    }

    return (
        <Formik
          initialValues = {{}}
          onSubmit={handleSubmitForm}
          //validationSchema
          innerRef = {formRef}
        >
        {({values, setFieldValue})=>{
        return (
            <div className={Classes.containerForm} >
                <div className={Classes.RowTwoColumn}>
                    <TextInput
                    placeholder = {"Name..."}
                    name = {"name"}
                    type = {"text"}
                    />
                </div>
            </div>
            )
        }}
            </Formik>
    )
}

export default FormAddTags