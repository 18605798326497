import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import classesSwitch from './Switch.module.scss';
import _ from 'lodash';
import { useField } from 'formik'



const SwitchForm = ({ name, label, classNameLabel, borderBottom = false, onChange = null, className, labelStyle }) => {

    const [field, meta, helper] = useField(name);

    const [value, setValue] = useState(_.get(field, "value", true));

    const handleOnChanged = (e) => {
        helper.setValue(e.target.checked);
        setValue(e.target.checked);
        if (onChange) {
            onChange(e.target.checked);
        }
    }


    return (
        <div className={`${classesSwitch.containerSwitch} ${className} ${borderBottom && classesSwitch.borderBottom}`}  >
            {label && <span className={classNameLabel} style={{ display: label ? null : "none", ...labelStyle }} >{label}</span>}


            <input
                
                className={classesSwitch["apple-switch"]}
                checked={value}
                type="checkbox"
                onChange={handleOnChanged}
                name={name}

            ></input>

            {/*
                <Form.Check
                type="switch"
                label=""
                defaultChecked={value}
                name={name}
                isValid
                onChange={handleOnChanged}
                className={classesSwitch.switch}
            />
            */}

        </div>


    )

}


export default SwitchForm;