import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import { ButtonForm } from '../index'

import Tabs from '../Tabs/Tabs'
import { get } from 'lodash'
import classes from './ModalTabs.module.scss';
import { isEmpty } from "lodash";
import { Header, Footer,Modal } from './Components/Components';

const ModalTabs = ({ showModal, closeModal, title, children, showDelete, className }) => {

    const [typeSubmit, setTypeSubmit] = useState("CREATED");

    const submitForm = () => {

        formRef.current.handleSubmit();

    }

    const formRef = React.createRef();
    /*
        useEffect(() => {
            if (!isEmpty(children.props.data)) {
                setTypeSubmit("UPDATED")
            }
    
        }, [children.props.data])
    
    */

    const newChildren = React.cloneElement(children, { closeModal, typeSubmit, formRef });

    return (
        <Modal show={showModal}   sizes={'large'}   >
            <div className={classes.modalBox} >
                <Header title={title} onClose={closeModal} />
                <div className={`${classes.containerTabs} ${get(className, "modal")}`} >
                    {newChildren}
                </div>

                <Footer >
                    <ButtonForm handleClik={submitForm} type="button" label={"CONFIRM"} />
                </Footer>
            </div>

        </Modal>

    )
}

export default ModalTabs;


