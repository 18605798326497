import React,{useState} from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import classes from './DateInput.module.scss'
import moment from 'moment'
import { times } from 'lodash';


const DateInput = ({ classNameDate, className, onChagedDate, placeholder, value, formatValue="YYYY-MM-DD",formatInput="dd-MM-yyyy" }) => {

    const [date, setDate] = useState(value);
    const [startDate, setStartDate ] = useState(value);
    const handleOnChangeDate = (date) => {
        //const {Time} = date
        setDate(date);
        onChagedDate(moment(date).format(formatValue))
    }

    return (
        <div className={[classes.containerDatePicker, className]}>
            <DatePicker
                className={[classes.dates, classNameDate]}
                dateFormat={formatInput}
                selected={date}
                onChange={handleOnChangeDate}
                placeholderText={placeholder}
            /> 
               
        </div>
    )

}

export default DateInput;
