import React, { useContext } from "react";
import GoogleMapReact from "google-map-react";
import classes from "./Location.module.scss";
import Avatar from "../../Avatar/Avatar";
import { useFormikContext } from "formik";
import { context } from "../../../utils/CustomHook/index";
import Axios from "axios";
import moment from "moment";


const GEOCODE_API = "https://maps.googleapis.com/maps/api/geocode/json";
const URL = `${GEOCODE_API}?&key=${process.env.REACT_APP_MAP_KEY}`;

const getSuppliersByAccount = (Suppliers, accounts) => () => 
    accounts
        .filter((account) => account?.supplier?.service?.code === "ACC")
        .filter((account) => {
            return account.accounts.some(({ rate_data: { nights = 0, date } }) => {
                const formatDate = "YYYY-MM-DD";
                const startDate = moment(date, formatDate);
                const endDate = moment(startDate).add(nights, "days");
                return moment().isBetween(startDate, endDate);
            });
        })
        .map((account) => account?.supplier?.id)
        ?.map((id) => Suppliers.find((s) => s.id === id));



const getLocationsByAdress = async ({address, text}) => {
    const response = await Axios.get(`${URL}&address=${address}`);
    if(response.status !== 200 && response.data.status !== "OK" ) return;
    return response.data.results.map(r => ({...r?.geometry.location, text}))
}


const Location = () => {
    const { Suppliers, loading } = useContext(context);
    const [ locations, setLocations] = React.useState([])
    const { values } = useFormikContext();
    const suppliers = React.useMemo(getSuppliersByAccount(Suppliers, values.accounts), [values.accounts, Suppliers]);
    const showLoader = () => loading(true)  
    const hideLoader = () => loading(false)  

    React.useEffect(() => {
        showLoader()
        const addresses = suppliers.map(({ name, region, address }) => ({
            address: encodeURI(`${region.country.name} ${region.name} ${address}`),
            text: name.substr(0, 2).toUpperCase(),
        }));
        Promise.all(addresses.map(getLocationsByAdress))
            .then((locations) => setLocations(locations.flat()))
            .finally(hideLoader);
    }, [suppliers]);


    return (
        <div className={classes.container}>
            {!!locations?.length ? (
                <GoogleMapReact
                    bootstrapURLKeys={{ key: process.env.REACT_APP_MAP_KEY }}
                    defaultCenter={locations[0]}
                    defaultZoom={17}
                >
                    {locations?.map(({ text, lat,lng }) => (
                        <Avatar initials={text} lat={lat} lng={lng} color={"#ACC"} />
                    ))}
                </GoogleMapReact>
            ): <div className={classes.notData}>No data to display</div> 
            }
        </div>
    );
};
export default Location;
