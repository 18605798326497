import React, { useEffect } from 'react';
import classesView from './AddContact.module.scss';
import { FieldArray } from 'formik';
import { useGetList } from '../../../utils/CustomHook/index';
import { AddFormButton, SelectForm } from '../../index';
import { Select } from '../../NewComponents/index';
import { TextLabelCompent } from '../../Text/FactoryText'
import _ from 'lodash'
const AddContact = ({ values, name, firstColum }) => {

    const { data: contacts, loading } = useGetList("getContacsActive")

    const mapListSelect = (list) => {
        return _.map(list, element => ({ value: element, label: `${element.name} ${element.lastname} - ${element.work_area} ` }))
    }


    return (
        <FieldArray
            name={name}
            render={arrayHelpers => (
                <div className={classesView.containerAddComponent} >
                    {_.get(values, name, []).map((contact, index) => {


                        const handleContactAdd = index === _.get(values, name, []).length - 1 ? () => arrayHelpers.push({}) : null;
                        const filterContacts = (list) => {
                            return _.filter(list, element => !_.some(_.compact(_.get(values, name)), contact => contact.id === element.value.id))
                        }
                        return (
                            < div className={classesView.containerContact} key={index} style={{ width: "100%" }} >

                                <div className={classesView.componentWithLabel}>
                                    <TextLabelCompent firstColum={firstColum} label={'Contact:'} />
                                    <Select
                                        placeholder={"Select Contact"}
                                        list={mapListSelect(contacts)}
                                        clear={true}
                                        handleFilterList={filterContacts}
                                        onChange={value => arrayHelpers.form.setFieldValue(`${name}[${index}]`, value)}
                                        value={contact}
                                        loading={loading}

                                    />
                                </div>


                                {/*
                                    <button className={classesView.removeButtonContact} type="button" onClick={() => arrayHelpers.remove(index)}>
                                        -
                                    </button>*/}
                                {handleContactAdd && <AddFormButton label={"Add Contact"} classes={{ root: classesView.addButtonContact }}
                                    type="button"
                                    handleOnClick={handleContactAdd} />
                                }

                            </div>
                        )
                    }
                    )}
                    {_.get(values, name, []).length === 0 && <AddFormButton label={"Add Contact"} type="button" handleOnClick={() => arrayHelpers.push({})}
                        classes={{ root: classesView.addButtonContact }} />}
                </div>
            )
            }
        />)

}

export default AddContact;