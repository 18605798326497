import React, { useContext, useEffect, useRef } from 'react';
import { useField, Formik, Form, useFormikContext, } from 'formik';
import * as Yup from 'yup';
import classes from "./FormClients.module.scss";
import { TextInputForm, Avatar, SwitchForm, SelectForm } from "../../index";
import { isEmpty, get } from 'lodash';
import { context } from '../../../utils/CustomHook';
import _ from 'lodash';
import { TextLabelCompent } from '../../Text/FactoryText';
import { Row, Col } from '../../Grid/Grid'

import { TextInput, Select } from '../../NewComponents/index';
import { TextInfo } from '../../Text/FactoryText'



const FormClients = ({ data }) => {

    const { Countries, Currencies, ClientTypes, IvaConditions } = useContext(context);
    const { DocumentTypes, Languages, RelationsAgency, PaymentMethods } = useContext(context);
    const selectRegionRef = useRef(null);

    const mapListSelect = (list, nameLabel) => {
        return _.map(list, element => ({ value: element, label: element[nameLabel] }))
    }
  


    useEffect(() => {

        if (_.has(data, "client.country") && Countries) {
            getRegionList(data.client);
        }

    }, [Countries])

    const getRegionList = ({ country }) => {
        let contrySelected = _.find(Countries, c => c.code === _.get(country, "code"));
        return contrySelected ? _.map(contrySelected.regions, city => ({ label: city.name, value: city })) : [];
    }

    const { setFieldValue } = useFormikContext();

    const handleSelectCountry = (value) => {
        setFieldValue('client.country', value);
        selectRegionRef.current.handleClearValue();

    }

    return (
        <div className={classes.containerForm} >

            <div className={classes.containerUser} >
                <h2>{data.client.name}</h2>
            </div>
            <Row grid={'grid-3'}>
                <Col>
                    <TextLabelCompent firstColum label={"Tipo de Cliente:"} />
                    <Select
                        list={mapListSelect(ClientTypes, "name")}
                        placeholder={"Selecciona Tipo De Cliente"}
                        onChange={value => setFieldValue('client.clientType', value)}
                        value={_.get(data, 'client.clientType')}
                        clear={true}
                    />
                </Col>
                <Col className={classes.componentWithLabel}>
                    <TextInput
                        label={'Razón Social'}
                        placeholder={"Razon Social..."}
                        name={"client.name"}
                        type={"text"}
                    />
                </Col>

                <TextInfo />

            </Row>

            <Row grid={'grid-3'} >
                <TextInput
                    label={"Direccion de Facturacion:"}
                    placeholder={"Direccion de Facturacion..."}
                    name={"client.address"}
                    type={"text"}
                    classNameLabel={`firstCol`}
                />
                <Col >

                    <TextLabelCompent  label={"Ciudad:"} />
                    <Select
                        placeholder={"Select City"}
                        disabled={!_.get(data, "client.country")}
                        list={getRegionList(data.client)}
                        ref={selectRegionRef}
                        onChange={value => setFieldValue("client.region", value)}
                        value={_.get(data, "client.region")}
                        clear={true}

                    />
                </Col>
                <Col >
                    <TextLabelCompent label={"Pais:"} />
                    <Select
                        placeholder={"Select Country"}
                        list={mapListSelect(Countries, "name")}
                        onChange={handleSelectCountry}
                        value={_.get(data, "client.country")}
                        clear={true}

                    />

                </Col>
            </Row>



            <Row >
                <TextLabelCompent firstColum label={"Relacion Emisor Receptor:"} />
                <Select
                    list={mapListSelect(RelationsAgency, "name")}
                    placeholder={"Select..."}
                    onChange={value => setFieldValue("client.relationAgency", value)}
                    value={_.get(data, "client.relationAgency")}
                    clear={true}

                />
            </Row>
            <Row >
                <TextLabelCompent firstColum label={"Condicion IVA:"} />
                <Select
                    list={mapListSelect(IvaConditions, "name")}
                    placeholder={"Select..."}
                    onChange={value => setFieldValue("client.ivaCondition", value)}
                    value={_.get(data, "client.ivaCondition")}
                    clear={true}

                />
            </Row>

            <Row grid={`grid-3`}>
                <Col>
                    <TextLabelCompent firstColum label={"Idioma:"} />
                    <Select
                        name={"client.language"}
                        placeholder={"Select Languaje"}
                        list={mapListSelect(Languages, "name")}
                        value={_.get(data, 'client.language')}
                        onChange={value => setFieldValue("client.language", value)}
                        clear

                    />
                </Col>
                <Col >
                    <TextLabelCompent label={"Moneda:"} />
                    <Select
                        placeholder={"Select Currency"}
                        list={mapListSelect(Currencies, "iso")}
                        value={_.get(data, 'client.currency')}
                        onChange={value => setFieldValue("client.currency", value)}
                        clear
                    />
                </Col>
                <TextInfo />

            </Row>
            <Row grid={`grid-3`} >
                <Col>
                    <TextLabelCompent firstColum label={"Documento:"} />
                    <Select

                        placeholder={"Select Document Type"}
                        list={mapListSelect(DocumentTypes, "name")}
                        value={_.get(data, 'client.documentType')}
                        onChange={value => setFieldValue("client.documentType", value)}
                        clear
                    />
                </Col>
                <TextInput
                    label={"Numero:"}
                    placeholder={"Numero..."}
                    name={"client.document"}
                    type={"text"}
                />
                <TextInfo />

            </Row>
            <Row grid={`grid-3`}>
                <Col >
                    <TextLabelCompent firstColum label={"Forma de Pago:"} />
                    <Select
                        placeholder={"Select Payment Methods"}
                        list={mapListSelect(PaymentMethods, "name")}
                        value={_.get(data, 'client.paymentMethod')}
                        onChange={value => setFieldValue("client.paymentMethod", value)}
                        clear
                    />
                </Col>
                <Col >
                    <TextInput
                        placeholder={"Vencimiento..."}
                        name={"client.expiration"}
                        type={"text"}
                        label={"Vencimiento:"}
                    />
                </Col>

                <TextInfo />

            </Row>

            <div className={classes.containerCommisions}>
                <h3>Comission</h3>
                <div className={classes.rowComission}>
                    <Col >
                        <TextLabelCompent firstColum label={"Services"} />
                        <TextInput
                            placeholder={""}
                            name={"client.clientCommisions.services"}
                            type={"text"}
                            maxLength={6}
                            append={"%"}
                        />
                    </Col>
                    <Col >
                        <TextLabelCompent label={"Cruices"} />
                        <TextInput
                            placeholder={""}
                            name={"client.clientCommisions.cruices"}
                            type={"text"}
                            maxLength={6}
                            append={"%"}
                        />
                    </Col>
                </div>

                <div className={classes.rowComission}>
                    <Col >
                        <TextLabelCompent firstColum label={"Hotels"} />
                        <TextInput
                            placeholder={""}
                            name={"client.clientCommisions.hotels"}
                            type={"text"}
                            maxLength={6}
                            append={"%"}
                        />
                    </Col>
                    <Col >
                        <TextLabelCompent label={"Fundation Donnation"} />
                        <TextInput
                            placeholder={""}
                            name={"client.clientCommisions.fundationDonation"}
                            type={"text"}
                            maxLength={6}
                            append={"%"}
                        />
                    </Col>
                </div>


            </div>
        </div >

    )
}

export default FormClients;

