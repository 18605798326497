import React, { useState, useEffect } from 'react';
import classes from './Menu.module.scss';
import { Avatar, Snackbars } from '../index';
import { Card } from 'react-bootstrap';
//simport onesixthLogo from '../../public/img/onesixth-logo.svg'
//import { ReactComponent as Logo } from '../../public/img/onesixth-logo.svg';
import { PAGES } from '../../utils/Constants/pages';
import _ from 'lodash'
import { useLocation } from 'react-router-dom';

const Menu = ({ goTo }) => {


    const myUser = JSON.parse(localStorage.getItem("userData"));

    const location = useLocation();

    const [itemSelected, setItemSelected] = useState(location.pathname.split("/")[2]);

    const listMenu = [
        [
            {
                key: "dashboard"
            }
        ],
        [
            {
                key: "booking"
            },
            {
                key: "users"
            }
        ],
        [
            {
                key: "contacts"
            },
            {
                key: "clients"

            },
            {
                key: "suppliers",
            },

        ],
        [
            {
                key: "programs"
            },
            {
                key: "destinations"

            },
            {
                key: "tariff",
            },

        ],
        [
            {
                key: "logout",
            }
        ]
    ]

    const handleClickRedirect = (key) => {
        if ("logout" === key) {
            localStorage.clear();
        }

        goTo(PAGES[key].path);

    }

    useEffect(() => {
        setItemSelected(location.pathname.split("/")[2]);
    }, [location])





    return (
        <div className={classes.containerMenu} >

            <i className={classes.Logo} />


            <Avatar classes={{ root: classes.avatar }} firstName={myUser.name} lastName={myUser.lastname} />

            <label>{`${myUser.name} ${myUser.lastname}`}</label>

            <p>{`${_.toUpper(myUser.company_name)}`}</p>

            {listMenu.map(items => {
                return (
                    <Card className={classes.containerListGroup} key={JSON.stringify(items)} >
                        <ul >
                            {items.map((item, index) => <ItemMenu key={item.key} item={item} itemSelected={itemSelected} onClick={handleClickRedirect} />)}
                        </ul>
                    </Card>)

            })}

            <Snackbars />

        </div>
    )
}


export default Menu;





const ItemMenu = ({ item, index, itemSelected, onClick }) => {
    const [selectedOver, setSelecetedOver] = useState(false);

    const handleMouseOver = () => {
        setSelecetedOver(true)

    }

    const handleMouseLeave = () => {
        setSelecetedOver(false);
    }


    const classesHover = selectedOver ? classes.over : classes.leave

    const classesSelected = item.key === itemSelected ? classes.selected : "";
    return (

        <li onClick={() => onClick(item.key)}>
            <div onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave} className={`${classes.elementMenu} ${classesSelected} ${classesSelected ? "" : classesHover} `} >
                {/*  {(classesSelected || classesHover) &&
                    <i className={`icon-arrow ${classes.activeSelect}  `} />
                }*/}
                <div>
                    {PAGES[item.key].label}
                </div>
            </div>
        </li >
    )
}

