import React from 'react'
import classes from './Loading.module.scss'
const Loading = ({ showLoading, className, style }) => {
    return (
        <>
            {showLoading ?
                <div className={`${className ? className : classes.containerLoading}`} style={style}>
                    <div className={classes["lds-ring"]}><div></div><div></div><div></div><div></div></div>
                </div> : null
            }
        </>
    )
}

export default Loading;