
const PAGES = {

    raiz: {
        path: "/",
        label: "Login",
    },
    login:{
        path: "/login",
        label: "Login",
    },
    dashboard: {
        label: "Dashboard",
        path: "/auth/dashboard",
        layout: "DASHBOARD"
    },
    booking: {
        label: "Booking",
        path: "/auth/booking",
        layout: "DASHBOARD"
    },

    users: {

        label: "Users",
        path: "/auth/users",
        layout: "DASHBOARD"
    },
    contacts: {

        label: "Contacts",
        path: "/auth/contacts",
        layout: "DASHBOARD"

    },

    clients: {
        label: "Clients",
        path: "/auth/clients",
        layout: "DASHBOARD"

    },
    suppliers: {
        label: "Suppliers",
        path: "/auth/suppliers",
        layout: "DASHBOARD"
    },
    programs: {
        label: "Programs",
        path: "/auth/programs",
        layout: "DASHBOARD"
    },
    destinations: {
        label: "Destinations",
        path: "/auth/destinations",
        layout: "DASHBOARD"
    },
    tariff: {
        label: "Tariff",
        path: "/auth/tariff",
        layout: "DASHBOARD"
    },
    logout: {

        label: "Logout",
        path: "/login"
    }



}

export {
    PAGES
}
