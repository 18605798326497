import React, { useContext, useEffect, useRef } from 'react';
import classes from "./FileView.module.scss";
import { Avatar } from "../index";
import _ from 'lodash';
import { useField } from 'formik'
import axios from 'axios';
import Uri from '../../utils/API/uris';
import moment from 'moment'




const FileView = ({ file, index, type }) => {
    const [field, meta, helpers] = useField(`${type}.attachments`);
    const token = localStorage.getItem("token");
    const handleDelete = () => {
        let files = field.value;
        files.splice(index, 1);
        helpers.setValue(files);
        axios.delete(Uri.files, {
            data: { event: type, model: file },
            headers: {
                'Accept': 'application/json',
                authentication: token,
            }
        })
            .then(res => {
            })
            .catch(err => {

            });
    }


    return (
        <div className={classes.containerFileView}>
            <div>
                <i className={`icon-note`} />
                <a href={file.file} target="_blank" download="filename.pdf">{file.file.split("/").pop()}</a>
            </div>
            <div>
                <span>Last update {`${moment(file.date_create).format("L")}`}</span>
                <Avatar classes={{root: classes.avatar}} firstName={file.user_create.name} lastName={file.user_create.lastname} color={file.user_create.color} />
                <i className={classes.deleteIcon} onClick={handleDelete} />
            </div>
        </div>)

}



export default FileView;