import * as Yup from 'yup';


const loginValidate ={
    user: Yup.string().required("This field is required."),
    password: Yup.string().required("This field is required.")
}

const forgotValidations = {
    mail: Yup.string().required("This field is required.").email("Invalid email address.")
}

const resetPasswordValidate={
    password: Yup.string().required("This field is required."),
    confirmPassword: Yup.string().required("This field is required.")
}


export  {
    loginValidate,
    forgotValidations,
    resetPasswordValidate
}