import React from 'react';
import classes from './Factory.module.scss'
import _ from 'lodash';

export const TextLabelCompent = ({ label, firstColum = false }) => {


    const firstCol = firstColum ? classes.firstCol :"";
    return (
        <div className={classes.containerLabel}>
            <span className={firstCol} >{label}</span>
        </div>
    )
}

export const TextInfo = ({ path, value, firstColum = false }) => {

    const firstCol = firstColum ? classes.firstCol :"";
    return (
        <>
            {value && _.get(value, path) ?
                <div className={classes.containerTextInfo}>
                    <span className={firstCol}>{path ? _.get(value, path, ' ') : value}</span>
                </div>
                : <div className={classes.block}></div>
            }
        </>
    )
}


