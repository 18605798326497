import React, { useState } from 'react'
import classesView from './Destinations.module.scss'
import Hide from '../../Hide/Hide';
import { Table, TextInputArea, Avatar, AddHightlights, AddRestaurants, AddTags, AddPhotography } from '../../index';
import ModalUpload from '../../FileUpload/ModalUpload';
import _ from 'lodash'
const Content = ({ data, setFieldValue }) => {

  const Highlights = _.get(data,"destinations.destinationHightlights",[]);
  const Restaurants = _.get(data,"destinations.destinationRestaurants",[]);
  const Tags = _.get(data,"destinations.destinationTags",[]);
  const Photography = _.get(data,"destinations.destinationPhotos",[]);

  const handleAddPhotography = (values) => {
    setFieldValue("destinations.destinationPhotos", [...Photography, ...values]);
    setShowUploadFile(false);
  }
  // pendiente: hacer un handleDelete generico
  
  const handleDeleteHighlights = (values) => {
    const newHighlights = data.destinations.destinationHightlights.filter(dh => JSON.stringify(dh) !== JSON.stringify(values));
    setFieldValue(`destinations.destinationHightlights`, newHighlights);
  }
  const handleDeleteRestaurants = (values) => {
    const newRestaurants = data.destinations.destinationRestaurants.filter(dh => JSON.stringify(dh) !== JSON.stringify(values));
    setFieldValue(`destinations.destinationRestaurants`, newRestaurants);
  }
  const handleDeleteTags = (values) => {
    const newTags = data.destinations.destinationTags.filter(dh => JSON.stringify(dh) !== JSON.stringify(values));
    setFieldValue(`destinations.destinationTags`, newTags);
  }
  const handleDeletePhotos = (values) => {
    const newPhotos = data.destinations.destinationPhotos.filter(dh => JSON.stringify(dh) !== JSON.stringify(values));
    setFieldValue(`destinations.destinationPhotos`, newPhotos);
  }

  const [showUploadFile, setShowUploadFile] = useState(false);

  const fieldsHighlights = [
    {
      key: "name",
      decorator: (props) => (
        <span>{props.row.name}</span>
      ),
      classes: classesView.col2
    },
    {
      key: "delete",
      decorator: (props) => (
        <i className={`${classesView.deleteIcon}`} onClick={() => handleDeleteHighlights(props.row)} />
      ),
      classes: classesView.delete
    }
  ]
  const fieldsRestaurants = [
    {
      key: "name",
      decorator: (props) => (
        <span>{props.row.name}</span>
      ),
      classes: classesView.col1
    },
    {
      key: "phone",
      decorator: (props) => (
        <span>{props.row.phone}</span>
      ),
      classes: classesView.col1
    },
    {
      key: "address",
      decorator: (props) => (
        <span>{props.row.address}</span>
      ),
      classes: classesView.col2
    },
    {
      key: "address",
      decorator: (props) => (
        <span>{props.row.food_type}</span>
      ),
      classes: classesView.col2
    },
    {
      key: "delete",
      decorator: (props) => (
        <i className={`${classesView.deleteIcon}`} onClick={() => handleDeleteRestaurants(props.row)}/>
      ),
      classes: classesView.delete
    }
  ]

  const fieldsTags = [
    {
      key: "name",
      decorator: (props) => (
        <span>{props.row.name}</span>
      ),
      classes: classesView.col2
    },
    {
      key: "delete",
      decorator: (props) => (
        <i className={`${classesView.deleteIcon}`} onClick={() => handleDeleteTags(props.row)} />
      ),
      classes: classesView.delete
    }
  ]

  const fieldsPhotos = [
    {
      key: "file",
      decorator: (props) => {
        const fileName = _.get(props, "row.file", "").split("/").pop();
        return (
          <div className={classesView.containerPhoto} >
            <img style={{ width: "2rem", height: "2rem" }} src={props.row.file} /> <a href={props.row.file} target="_blank" download={fileName}>{fileName}</a>
          </div>
        )
      },
      classes: classesView.col1
    },
    {
      key: "delete",
      decorator: (props) => (
        <i className={`${classesView.deleteIcon}`} onClick={() => handleDeletePhotos(props.row) } />
      ),
      classes: classesView.delete
    }
  ]

  return (
    <div className={classesView.containerView}>
      <div className={classesView.containerRowHide}>
        <div className={classesView.containerUser} >
          <Avatar   classes={{ root: classesView.avatarName }}  color={data.destinations.color} initials={data.destinations.claim} />
          <h2>{data.destinations.name}</h2>
        </div>
      </div>
      <br />
      <div className={classesView.containerRowHide}>
        <h2>Description:</h2>
        <TextInputArea className={`${classesView.textArea} `} cols={"100"} rows={"7"} name={"destinations.description"} />
      </div>
      <div className={classesView.containerRowHide}>
        <Hide title={`Highlights: (${Highlights.length})`} disabled={Highlights.length === 0} titleModal={"Highlights"}>
          <Table key={JSON.stringify(Highlights)} data={Highlights} fields={fieldsHighlights} />
          <AddHightlights data={data} setFieldValue={setFieldValue} />
        </Hide>

        <Hide title={`Restaurants: (${Restaurants.length})`} disabled={Restaurants.length === 0} titleModal={"Restaurants"}>
          <Table data={Restaurants} fields={fieldsRestaurants} />
          <AddRestaurants data={data} setFieldValue={setFieldValue} />
        </Hide>

        <Hide title={`Tags: (${Tags.length})`} disabled={Tags.length === 0} titleModal={"Tags"}>
          <Table data={Tags} fields={fieldsTags} />
          <AddTags data={data} setFieldValue={setFieldValue} />
        </Hide>

        <Hide title={`Photography: (${Photography.length})`} onPressAdd={() => setShowUploadFile(true)} disabled={Photography.length === 0} titleModal={"Photography"}>
          <Table data={Photography} fields={fieldsPhotos} />
          {showUploadFile && <ModalUpload
            showModal={showUploadFile}
            closeModal={() => setShowUploadFile(false)}
            id={data.destinations.id}
            entity={"destination"}
            callbak={handleAddPhotography}
          />
          }        </Hide>
      </div>
    </div>
  )
}

export default Content
