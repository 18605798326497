import React, { useState } from "react";
import classes from "./Tabs.module.scss"


const Tabs = (props) => {


    const [tab, setTab] = useState(null);
    const [tabs, setTabs] = useState([]);


    const goTo = (tabKey) => {
        if (props.disabled && props.disabled()) {
            return false;
        }
        props.goTo(tabKey)
    };

    const onClickRight = () => {
        if (props.onClickRight) {
            props.onClickRight();
        }
    }

    const onClickLeft = () => {
        if (props.onClickLeft) {
            props.onClickLeft();
        }
    }

    return (
        <div style={props.style}>
            <div className={props.classesTabs || classes.Tabs}>
                <div className={props.classLetter || classes.Botonera}>
                    {props.tabs.map((tab, i) => {
                        const classSelect = props.selected === tab.key ? classes.selected : null;
                        const classDisabled = props.disabled && props.disabled() ? classes.disabled : null;
                        return (
                            <a key={i} className={`${classes.Tab} ${classSelect} ${classDisabled}`} onClick={(e) => { goTo(tab.key) }}>{tab.name}</a>
                        )
                    })}
                </div>
                {props.showButtons ?
                    <div className={classes.ScrollButtons}>
                        <div id={props.leftId} onClick={() => onClickLeft()} className={`${classes.Button} ${classes.Left} ${classes.Deactive}`}>{'<'}</div>
                        <div id={props.rightId} onClick={() => onClickRight()} className={`${classes.Button} ${classes.Right} ${classes.Active}`}>{'>'}</div>
                    </div>
                    : null}
            </div>
            {props.showHr ?
                <hr style={{ color: '#f5f7f9' }} />
                : null}
        </div>
    );

}


export default Tabs
