import React,{useState} from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import classes from './DateTime.moduile.scss';
import moment from 'moment'

export default function DateTime(props) {
    const { onChagedDate, placeholder, value} = props;
    const [startDate, setStartDate] = useState(value);

    const handleOnChangeDate = (date) => {
        setStartDate(date);
        onChagedDate(moment(date).format("hh:mm a"))
    }

    return (
        <div className={classes.containerDatePicker}>
            <DatePicker
                className={classes.dates}
                selected={startDate}
                onChange={handleOnChangeDate}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption="Time"
                dateFormat="h:mm aa"
                placeholderText={placeholder}
            />
        </div>
    )
}
