import React, { useState, useEffect, useRef, useContext } from 'react';
import { useFormik, Formik, Form } from 'formik';
import * as Yup from 'yup';
import classes from "./FormAddClient.module.scss";
import { Comments, ButtonForm, TextInputForm, Avatar, SwitchForm, SelectForm, ColorPikerForm } from "../../index";
import _ from 'lodash';
import { clientSchema } from '../../../utils/validate/forms';
import { sendNotification } from '../../../utils/helper/notification';
import { context } from '../../../utils/CustomHook';
import { serviceClients } from '../../../utils/API/apiService';

import { Row } from '../../Grid/Grid';
import { TextInput, Select } from '../../NewComponents/index'


const Formclient = ({ data, typeSubmit, closeModal, formRef }) => {

  const { Countries, ClientTypes } = useContext(context);
  const { Languages } = useContext(context);

  const mapListSelect = (list, nameLabel = 'name') => {
    return _.map(list, element => ({ value: element, label: element[nameLabel] }))
  }
  const { dataServer, setDataServer, loading } = useContext(context);

  const selectRegionRef = useRef();

  const initialValues = { client: { color: "#7d7d7d", attachments: [], state: 1 }, comments: [] };

  const getRegionList = ({ country }) => {
    let contrySelected = _.find(Countries, c => c.code === _.get(country, "code"));
    return contrySelected ? _.map(contrySelected.regions, city => ({ label: city.name, value: city })) : [];
  }

  const updateTable = (values) => {

    const index = _.findIndex(dataServer, item => item.id === values.id);
    if (index < 0) {
      setDataServer([...dataServer, values])
    }
    else {
      const newDataServer = dataServer;
      newDataServer[index] = values;
      setDataServer(newDataServer)
    }
  }

  const handleSubmitForm = async values => {

    const { client, comments } = values
    try {
      closeModal();
      loading(true);
      let { inserted_id, response } = await serviceClients("createdClient", { client, comments, event: "client" });
      const user = JSON.parse(localStorage.getItem("userData"));
      values = {
        ...client, region: { ...client.region, country: client.country },
        comments, id: inserted_id, user_create: user
      }
      sendNotification(response, "success", 2000)
      updateTable(values);
      loading(false);

    } catch (error) {
      sendNotification(error.message, "danger", 2000)
      loading(false);
      closeModal();

    }
  }




  const handleSubmitComment = async (comment, values) => {

    let { comments, client } = values
    await serviceClients("createdclient", { client, event: "comment", comment })

    comments.push(comment)
    let data = {
      ...client, region: { ...client.region, country: client.country },
      comments
    };
    updateTable(data);

  }
  const handleDeleteComment = async (comment, values) => {
    const { client, comments } = values

    await serviceClients("deleteComment", { comment })
    data = {
      ...client, region: { ...client.region, country: client.country },
      comments
    };
    updateTable(data);
  }

  return (

    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmitForm}
      validationSchema={Yup.object().shape(clientSchema)}
      innerRef={formRef}
      validateOnChange={false}
      validateOnBlur={false}
      enableReinitialize={true}
    >

      {({ handleSubmit, handleChange, handleBlur, values, setFieldValue, errors }) => {

        const handleSelectCountry = (value) => {
          setFieldValue('client.country', value);
          selectRegionRef.current.handleClearValue();

        }
        return (

          <div className={classes.containerForm}>

            <Row >
              <TextInput
                placeholder={"Full Name..."}
                name={"client.name"}
                type={"text"}
              />
              <TextInput
                placeholder={"Address..."}
                name={"client.address"}
                type={"text"}
              />
            </Row>
            <Row >
             
              <Select
                placeholder={"Select City"}
                disabled={!_.get(values, "client.country")}
                list={getRegionList(values.client)}
                ref={selectRegionRef}
                onChange={value => setFieldValue("client.region", value)}
                value={_.get(values, "client.region")}
                clear
              />

              <Select
                placeholder={"Select Country"}
                list={mapListSelect(Countries)}
                onChange={handleSelectCountry}
                value={_.get(values, "client.country")}
                clear={true}

              />
            </Row >
            <Row >
              <Select
                name={"client.clientType"}
                placeholder={"Select Client type"}
                list={mapListSelect(ClientTypes, "name")}
                onChange={value => setFieldValue("client.clientType", value)}
                value={_.get(values, "client.clientType")}
                clear
             
               
              />
              <TextInput
                placeholder={"Web Site..."}
                name={"client.website"}
                type={"text"}
              />
            </Row >
            <Row >

              <TextInput
                placeholder={"Phone"}
                name={"client.phone"}
                type={"text"}
              />
              <SwitchForm borderBottom name={"client.state"} label={"Active"} />

            </Row >

            {/*  <Comments activeClient={{ valueClient: false }} values={values} name={"comments"} setFieldValue={setFieldValue}
              handleSubmitComment={handleSubmitComment} handleDeleteComment={handleDeleteComment} />
            <pre style={{ position: "absolute", top: "-50px", left: "-500px", color: "#fff" }}>{JSON.stringify(values, null, 2)}</pre>*/}
          </div>

        )

      }}

    </Formik>
  );
}
export default Formclient;