import { Formik } from 'formik';
import React, { useState } from 'react';
import { Acounts, Itinerary, Settings } from '../../../components/index';
import Tabs from '../../Tabs/Tabs';
import Location from '../../TabsView/Booking/Location';
import classes from './ControlledTabsBooking.module.scss';


const ControlledTabsBooking = ({ data, formRef, updateBooking }) => {

    const [key, setKey] = useState('settings');

    const keys = [
        { name: "SETTINGS", key: "settings" },
        { name: "ITINERARY", key: "itinerary" },
        { name: "LOCATION", key: "location" },
        { name: "ACCOUNTS", key: "accounts" }
    ]

    const initialValues = data;

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={updateBooking}
            innerRef={formRef}
            validateOnChange={false}
            validateOnBlur={false}
            enableReinitialize={true}
        >

            {({ values}) => {
                return (
                    <div>
                        <Tabs
                            className={classes.Tabs}
                            tabs={keys}
                            goTo={(value) => {
                                setKey(value);
                            }}
                            selected={key}
                            classLetter={classes.letterTabs}
                            classesTabs={classes.Tabs}
                        />

                        {key === "settings" &&
                            <Settings values={values} />
                        }
                        {key === "itinerary" &&
                            <Itinerary />
                        }
                        {key === "location" &&
                            <Location />
                        }
                        {key === "accounts" &&
                            <Acounts />
                        }
                    </div>
                )
            }}
        </Formik>

    )
}
export default ControlledTabsBooking;