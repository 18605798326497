import React, { useState, useRef } from 'react';
import { createPortal } from 'react-dom'
import classes from './ModalForm.module.scss';
import { ButtonForm, SelectForm } from '../index';
import { useGetRolesUsers } from '../../utils/CustomHook'
import Modal from 'react-bootstrap/Modal'
import { useFormik, Formik } from 'formik';
import _ from 'lodash'

const ModalFilter = ({ setFilter, closeModal, showModal, filter }) => {
    const {roles} = useGetRolesUsers();
    const listRoles = roles.map(r => ({ label: r.role, value: r.role }));
    const listState = [{ value: { state: 1 }, label: "Active" }, { value: { state: 0 }, label: "Inactive" }]

    const formRef = useRef();
    const handleSubmitForm = (values) => {
        _.forEach(values, (value, key) => {
            if (value === null) {
                delete values[key]
            };
        });
        setFilter(values)
        closeModal();
    }

    const submitForm = () => {
        formRef.current.handleSubmit();
    }

    const getState = (value) => {
        return { label: value.state ? "Active" : "Inactive", value };
    }

    return (
        <Modal show={showModal} centered>

            <div className={classes.modalContainer}>
                <Modal.Header style={{ border: "hidden" }} >
                    <Modal.Title id="contained-modal-title-vcenter">
                        <h1>FILTER DATA</h1>
                    </Modal.Title>
                    <button className={classes.Close} onClick={closeModal} />
                </Modal.Header>


                <Formik
                    onSubmit={handleSubmitForm}
                    initialValues={filter}
                    enableReinitialize={true}
                    innerRef={formRef}
                >
                    {({ values, handleReset, handleSubmit }) => {
                        return (
                            <div>
                                <div className={classes.filterContainer}>
                                    <SelectForm
                                        list={listRoles}
                                        label={""}
                                        name={"role"}
                                        isClearable
                                        classic={true}
                                        placeholder={"Filter by Role.."}

                                    />
                                    <SelectForm
                                        list={listState}
                                        name={"state"}
                                        label={"Select Active"}
                                        isClearable
                                        placeholder={"Filter by Active.."}
                                        getValue={getState}
                                    />
                                </div>
                            </div>
                        )

                    }}
                </Formik>
                <Modal.Footer style={{ border: "hidden" }}>
                    <div className={classes.containerButtons}>
                        <ButtonForm label={"FILTER"} handleClik={submitForm} />
                    </div>
                </Modal.Footer>
            </div>




        </Modal>
    )
}




export default ModalFilter;