import React, { useRef, useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import { Button, Spinner } from 'react-bootstrap';
import classes from './Login.module.scss';
import { login } from '../../utils/API/apiService';
import { Snackbars } from '../../components/index'


import { loginValidate } from '../../utils/validate/auth';
//import { ReactComponent as Logo } from '../../public/img/onesixth-logo.svg';
import * as Yup from 'yup';
import { sendNotification } from '../../utils/helper/notification';

const loginValidationSchema = Yup.object().shape(loginValidate);

const Login = (props) => {



    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState("");
    const [password, setPassword] = useState("");
    const inputUser = useRef();
    const inputPassword = useRef();
    const [validation, setValidation] = useState({})


    useEffect(() => {

        if (inputUser.current.value === user && inputPassword.current.value === password) {
            loginValidationSchema.validate({ user, password }, { abortEarly: false })
                .then(resul => {
                    setValidation({
                        isValid: true,
                        error: {}
                    });

                })
                .catch(err => {
                    setValidation({
                        isValid: false,
                        error: {
                            message: err.inner[0].message,
                            label: err.inner[0].path
                        }
                    });
                })

        }

    }, [user, password]);


    const handleRecoverPassword = () => {
        props.history.push("/recoverPassword");
    }



    const handleLogin = () => {

        setLoading(true)
        login({ user, password })

            .then(res => {
                props.history.push("/auth/dashboard")
            })
            .catch(error => {
                setLoading(false);
                sendNotification(error.message, "danger", 2000)
                props.history.push("/login");
            })

    }


    return (
        <div className={classes.divPadre} >

            <i className={classes.logo} />
            <div className={classes.conten}>


                <div className={classes.divHijo} id="divHijo" >

                    <Form >
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>Log In ONESIXTH</Form.Label>
                            <br></br>
                            <Form.Label>Your User</Form.Label>
                            <Form.Control ref={inputUser} onChange={(e) => setUser(e.target.value)} value={user} type="text" placeholder="Enter user" />

                        </Form.Group>

                        <Form.Group controlId="formBasicPassword" className={classes.containerPass} >
                            <Form.Label>Password</Form.Label>
                            <Form.Control ref={inputPassword} type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} value={password} />
                            <Form.Text onClick={handleRecoverPassword} className={` ${classes.resetPassword}`}>
                                Forgot Password?
                                               </Form.Text>
                        </Form.Group>
                        <Button className={classes.sendButton} disabled={!validation.isValid} onClick={handleLogin} id="boton" variant="light" >
                            {!loading ? "Log In" :
                                <>
                                    <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    {"  Loading..."}
                                </>
                            }
                        </Button>
                    </Form>

                </div>
                <div className={classes.Reserved}>
                    <span>Onesixth Expeditions / All Rights Reserved * 2020</span>
                </div>
            </div>
            <Snackbars />
        </div>


    )
}

export default Login;