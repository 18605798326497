import { useFormikContext } from 'formik';
import React, { useContext } from 'react';
import { context } from '../../../../../../utils/CustomHook';
import Avatar from '../../../../../Avatar/Avatar';
import InputCurrency from '../../../../../TextInputs/InputCurrency/InputCurrency';
import classes from './RateAccount.module.scss';

const RateAccount = () => {
    const { values, setFieldValue } = useFormikContext();
    const { BookingRatesStates }  = useContext(context);

    return (
        <div>
            <div className={classes.containerCurrency}>
                <div>
                    <h6 className={classes.h6}>Cost</h6>
                    <InputCurrency
                        className={classes.currencyRate}
                        name={'cost'}
                        type={"number"}
                        onChange={v => {
                            const alicuota = (values.alicuota/100)*v;
                            // const markup = (values.markup/100)*v;
                            const sale = parseFloat((+v + alicuota) / values.markup).toFixed(2);
                            setFieldValue("sale", sale)
                        }}
                        currency={values.currency || 'USD' }
                    />
                    <h6 className={classes.h6}>Alicuota {values.alicuota}%</h6>
                </div>
                <div className={classes.sale}>
                    <h6 className={classes.h6}>Sale</h6>
                    <InputCurrency
                        className={classes.currencyRate}
                        name={'sale'}
                        readOnly={true}
                        type={"number"}
                        currency={values.currency || 'USD' }
                     />
                    <h6 className={classes.h6}>Markup {values.markup}%</h6>
                </div>
            </div>
            <div className={classes.containerAvatarCurrency}>
            {BookingRatesStates.map((e, i) =>                 
                <Avatar
                    key={i}
                    onClick={()=> {setFieldValue('booking_rate_state.id', e.id)}}
                    color={e.id === values.booking_rate_state.id && "#5f9139"}
                    initials={e.code}
                    classes={{ root: classes.avatar }} />)}
            </div>
        </div>

    )
}

export default RateAccount
