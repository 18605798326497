import classes from './Contact.module.scss';

import React, { useState, useEffect } from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';

import { AddFormButton, SearchInput, Table, Avatar, ModalForm, Loading } from "../../components";

import { isEmpty, findIndex } from 'lodash';

import { serviceContact } from '../../utils/API/apiService';
import { context, useGetCountries } from '../../utils/CustomHook'
import FormContact from '../../components/Forms/FormContact/FormContact';


const Contact = (props) => {

  const [dataServer, setDataServer] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [showLoading, setShowLoading] = useState(true);
  const countries = useGetCountries();
  const [rowSelected, setRowSelected] = useState(null);
  const [search, setSearch] = useState("");


  useEffect(() => {

    if (isEmpty(dataServer)) {
      serviceContact("getContacts")
        .then(res => {
          setDataServer(res);
          setShowLoading(false)
        })
        .catch(error => { console.log(error) }
        )

    }


  }, [dataServer])

  const editRow = (values) => {
    setRowSelected(values);
    setModalShow(true);
  }

  const headers = [
    {
      key: "lastName",
      colSpan: 1,
      label: "LAST NAME"

    },
    {
      key: "name",
      colSpan: 1,
      label: "NAME"
    },
    {
      key: "company",
      colSpan: 1,
      label: "COMPANY"

    },
    {
      key: "phone",
      colSpan: 1,
      label: "PHONE"

    },
    {
      key: "email",
      colSpan: 1,
      label: "EMAIL"

    },

    {
      key: "functions",
      colSpan: 1,
      label: ""

    },

  ]

  const fields = [

    {
      key: "name",
      decorator: (props) => (
        <span>{props.row.lastname}</span>
      ),
      classes: classes.col1
    },

    {
      key: "lastname",
      decorator: (props) => (
        <span>{props.row.name}</span>
      ),
      classes: classes.col2
    },
    {
      key: "company_name",
      decorator: (props) => (
        <span>{props.row.company_name}</span>
      ),
      classes: classes.col3
    },
    {
      key: "phone",
      decorator: (props) => (
        <span>{props.row.phone}</span>
      ),
      classes: classes.col4

    },
    {
      key: "mail",
      decorator: (props) => (
        <span>{props.row.mail}</span>
      ),
      classes: classes.col5
    },
    {
      key: "state",
      decorator: (props) => {
        return (<span className={`${classes.status} ${classes[!!props.row.state ? "status-active" : ""]} `} />)
      },
      classes: classes.col6
    },
    {
      key: "",
      decorator: (props) => {
        return <span> <Avatar firstName={props.row.user_create.name} lastName={props.row.user_create.lastname}
          color={props.row.user_create.color} classes={{ root: classes.avatar }} /></span>
      },
      classes: classes.col7
    },
    {
      key: "",
      decorator: (props) => (
        <i className={`icon-edit ${classes.iconEdit}`} onClick={() => editRow(props.row)} />
      ),
      classes: classes.col8
    }


  ]

  const handleOpenModal = () => {
    setRowSelected(null)
    setModalShow(true);
  }

  const handleCloseModal = () => {
    setModalShow(false);
  }

  return (
    <>
      <h2>CONTACT</h2>
      <context.Provider value={{
        dataServer,
        setDataServer,
        loading: setShowLoading,
        handleFilterData: (e) => e,
        countries
      }}>
        <Loading showLoading={showLoading} />
        <div className={classes.containerActionsElements}>

          <SearchInput classes={{ root: classes.searchInput }} handleSearch={(value) => setSearch(value)} placeholder={"Search...."} from_booking={false} />

          <AddFormButton
            classes={{ root: classes.addButtomPosition }}
            label={"ADD CONTACT"}
            handleOnClick={handleOpenModal}
          />

        </div>
        <div className={classes.containerTable} >
          <Table headers={headers} fields={fields} search={search} sort={["name"]} />
        </div>
        <ModalForm showModal={modalShow} closeModal={handleCloseModal} title={"CONTACT FORM"} showDelete
          className={{ modal: classes.modal }} >

          <FormContact data={rowSelected} />

        </ModalForm>
      </context.Provider>
    </>


  )
}

export default Contact;
