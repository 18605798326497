import React from 'react'
import classes from './Checkbox.module.scss'

const Checkbox = ({ onChange,checked, ...props }) => {
    return (
        <div className={classes.container}>
            <input {...props}  checked={checked} type="checkbox" onChange={onChange} ></input>
        </div>
    )
}
export default Checkbox