import { useFormikContext } from 'formik';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { AddFormButton, ModalForm } from '../../../../index';
import Table from '../../../../Table/Table';
import FormPaxs from './FormPaxs';
import classes from './TablePaxs.module.scss';

const TablePaxs = () => {
    const { values, setFieldValue } = useFormikContext();

    const [rowSelect, setRowSelect] = useState(null)

    const [showForm, setShowForm] = useState(false);

    const [paxs, setPaxs] = useState(_.get(values, 'paxs', []))    

    const handleEditPax = (value) => {
        setRowSelect(value);
        setShowForm(true)
        setFieldValue({ ...values, paxs: value });
    }

    const handleAddPax = () => {
        setRowSelect(null);
        setShowForm(true)
        
    }

    const handleDelete = (value) => {
        setPaxs(pre => {
            return _.filter(pre, pax => pax.id !== value.id)
        })
        const valor = _.filter(paxs, currentValue => currentValue.id !== value.id);
        setFieldValue({ ...values, paxs: valor });
    }

    const handleUpdatePaxs = (value) => {
        setPaxs(pre => {
            const indexFind = _.findIndex(pre, pax => pax.id === value.id);
            if (indexFind < 0) {
                return [...paxs, { ...value, id: _.uniqueId() }];
            } else {
                pre[indexFind] = value;
                return _.cloneDeep(pre);
            }
        });
        setFieldValue({ ...values, paxs: [...paxs, value] });
    }

    useEffect(() => {
        setFieldValue('paxs', paxs)
    }, [JSON.stringify(paxs)])


    const columns = useMemo(
        () => [
            {
                Header: ' ',
                columns: [
                    {
                        Header: 'Name',
                        accessor: 'fullName',
                        Cell: ({ row }) => {
                            const { name = "", lastname = "" } = row.original;
                            return (
                                <span  >{`${name} ${lastname}`}</span>
                            )
                        },
                        className: classes.col1
                    },
                    {
                        Header: 'Nationality',
                        accessor: 'nationality',
                        Cell: ({ row }) => {
                            const { nationality } = row.original;
                            return (
                                <span  >{`${nationality.name} `}</span>
                            )
                        },
                        className: classes.col2
                    },
                    {
                        Header: 'Passport Number',
                        accessor: 'passport_number',
                        Cell: ({ row }) => {

                            return (
                                <span  >{`${row.original.passport_number} `}</span>
                            )
                        },
                        className: classes.col3
                    },
                    {
                        Header: 'Phone',
                        accessor: 'mobile_phone',
                        Cell: ({ row }) => {
                            return (
                                <span  >{`${_.get(row.original, 'mobile_phone', '-')} `}</span>
                            )
                        },
                        className: classes.col4
                    },
                    {
                        accessor: "edit",
                        Cell: ({ row }) => (
                            <i className={`icon-edit ${classes.iconEdit}`} onClick={() => handleEditPax(row.original)} />
                        ),
                        className: classes.col6
                    },
                    {
                        accessor: "delete",
                        Cell: ({ row }) => (
                            <i className={` ${classes.deleteIcon}`} onClick={() => handleDelete(row.original)} />
                        ),
                        className: classes.col7
                    }

                ],
            }
        ],
        []
    )

    return (
        <div className={classes.containerTablePaxs}>
            <div className={classes.containerTitleTable}>
                <h2>Paxs</h2>
                <AddFormButton label={'Add Passenger'} handleOnClick={handleAddPax} />
            </div>
            <ModalForm showModal={showForm} closeModal={() => setShowForm(false)} >
                <FormPaxs values={rowSelect} onSubmit={handleUpdatePaxs} />
            </ModalForm >
            <Table data={paxs} columns={columns} />
        </div>
    )
}


export default TablePaxs;