import React from 'react';
import classes from './Comment.module.scss'
import { TextInputComment, TextUserAndDate } from '../index';
import moment from 'moment';
import { FieldArray } from 'formik';
import _ from 'lodash';
import { getDateAndTime } from '../../utils/helper/helper';


const Comments = ({ title, values, name , setFieldValue, handleSubmitComment, handleDeleteComment, classNameTextArea }) => {

    const user = JSON.parse(localStorage.getItem("userData"));
    const handleAddComment = (arrayHelpers) => {

        if (_.get(values, "draftComment", []).length > 0) {

            const { date, time } = getDateAndTime();
            const commentAdd = {
                user_create: user,
                state: 1,
                date_create: date,
                time_create: time,
                comment: values.draftComment,
            }
            arrayHelpers.push(commentAdd);
            setFieldValue("draftComment", "");
            handleSubmitComment(commentAdd, arrayHelpers.form.values);
        }

    }

    const deleteComment = (index, arrayHelpers) => {
        handleDeleteComment(values.comments[index], arrayHelpers.form.values)
        arrayHelpers.remove(index)



    }

    return (
        <div className={classes.containerComments}>
            <FieldArray
                name={name}
                render={arrayHelpers => (

                    <>
                        <h5>{title ? title : name }</h5>

                        <TextInputComment classNameTextArea={classNameTextArea} name={`draftComment`} placeholder={"Type here comment..."}
                            addComment={() => handleAddComment(arrayHelpers)} />
                        <div className={classes.containerLastCommnets}>
                            {values?.comments?.map((comment, index) => {
                                return (<TextLastComent key={comment.id} comment={comment} handleDelete={() => deleteComment(index, arrayHelpers)} />)

                            })
                            }

                        </div>

                    </>
                )} />
        </div>
    )
}

export default Comments;


const TextLastComent = ({ comment, handleDelete }) => {

    const getUserAndDate = ({ date_create, time_create, user_create }) => {
        return `${moment(date_create).format("L")}  ${time_create}
         ${user_create.name}  ${user_create.lastname}  has modified`
    }

    const user = JSON.parse(localStorage.getItem("userData"));
    return (
        <div className={classes.containerLastComment}>
            <div className={classes.lastComment}>
                <p> {comment.comment} </p>
                {user.id === comment.user_create.id && <i className={classes.deleteIcon} onClick={handleDelete} />}

            </div>
            {<span>{`${getUserAndDate(comment)}`}</span>}
        </div>

    )
}