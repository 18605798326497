import { useFormikContext } from "formik";
import _ from "lodash";
import React, { useContext, useState, useEffect } from "react";
import { ModalForm } from "../../";
import { context } from "../../../utils/CustomHook/index";
import AddFormButton from "../../Buttons/AddFormButton/AddFormButton";
import { Avatar, Checkbox, Table } from "../../index";
import CommentsComponents from "../Booking/Components/CommentsComponent/CommentsComponent";
import classes from "./Acounts.module.scss";
import { AccountRowDetail } from "./Components/AccountsComponents/AccountRowDetail";
import FormAddAccount from "./Components/AccountsComponents/FormAddAccount/FormAddAccount";

const Accounts = () => {
    const { Suppliers, BookingRatesStates, Destinations, getBookingAccounts, loading, BedTypes, dataServer = [] } = useContext(context);
    const { values, setFieldValue } = useFormikContext();
    const { accounts } = values;

    const [totalCost, setTotalCost] = useState(0)
    const [totalSale, setTotalSale] = useState(0)

    const handleOpenRow = (row) => {
        let findIndex = _.findIndex(accounts, (rate) => JSON.stringify(row) === JSON.stringify(rate));
        let newTableData = _.cloneDeep(accounts);
        newTableData[findIndex].showRowExtra = !newTableData[findIndex].showRowExtra;

        setFieldValue("accounts", newTableData);
    };

    const formatDate = strDate => strDate?.split("-")?.reverse()?.join("-");
    const reloadAccounts = async () => {
        const newsAccounts = await getBookingAccounts(values);
        const rowOpens = newsAccounts.map((e, i) => ({ ...e, showRowExtra: accounts[i]?.showRowExtra || false }))
        setFieldValue("accounts", rowOpens)
    }

    const fieldsBookingRow = [
        {
            key: "status",
            decorator: (props) => (
                <div>
                    <Checkbox />
                </div>
            ),
            classes: classes.col1,
        },
        {
            key: "date",
            decorator: (props) => (
                <span>
                    <div className={classes.containerTdVertical}>
                        <p className={classes.letterClassTypeRate}>
                            {formatDate(props.row.startDate)}
                        </p>
                        <p>
                            {props.row.startDate !== props.row.endDate && formatDate(props.row.endDate)}
                        </p>
                    </div>
                </span>
            ),
            classes: classes.col2,
        },
        {
            decorator: (props) => (
                <span>
                    <Avatar
                        initials={props.row.supplier.name.substring(0, 2)}
                        color={'#acc'}
                        classes={{ root: classes.avatar }}
                    />
                </span>
            ),
            classes: classes.col3,
        },

        {
            key: "description",
            decorator: (props) => (
                <div className={classes.content}>
                    <span>{props.row?.supplier.name}</span>
                </div>
            ),
            classes: classes.col4,
        },
        {
            key: "info",
            decorator: (props) => {
                return (
                    <div className={classes.content}>
                        <span>{(+props.row?.totalCost || 0).toFixed(2)}</span>
                    </div>
                );
            },
            classes: classes.col5,
        },
        {
            key: "info",
            decorator: (props) => (
                <div className={classes.content}>
                    <span>{(+props.row?.totalSale || 0).toFixed(2)}</span>
                </div>
            ),
            classes: classes.col6,
        },
        {
            key: "status",
            decorator: (props) => (
                <span>
                </span>
            ),
            classes: classes.col7,
        },
        {
            key: "",
            decorator: (props) => (
                <i className={`icon-arrow ${classes.iconArrow}`} onClick={() => handleOpenRow(props.row)} />
            ),
            classes: classes.col8,
        },
    ];

    const RowTableExtra = ({ showRowExtra, ...props }) =>
        !!showRowExtra && (
            <tr>
                <td className={classes.RowTableExtra} colSpan={fieldsBookingRow.length}>
                    <AccountRowDetail
                        {...props}
                        bookingId={values.id}
                        paxs={values.paxs}
                        flights={values.flights}
                        loading={loading}
                        bedTypes={BedTypes}
                        reloadAccounts={reloadAccounts}
                    />
                </td>
            </tr>
        );

    const [accountModal, setAccountModal] = React.useState(false);

    const calculateTotals = () => {
        if (dataServer[0].accounts) {
            let totalCost = 0
            let totalSale = 0
            dataServer[0].accounts.forEach(a => {
                totalCost += a.totalCost
                totalSale += a.totalSale
            })
            setTotalCost(totalCost)
            setTotalSale(totalSale)
        }
    }
    const currency = () => {
        return dataServer[0]?.accounts[0]?.supplier?.currency?.iso || 'USD'
    }

    useEffect(() => {
        calculateTotals()
    }, [])

    return (
        <context.Provider
            value={{
                dataServer: accounts,
                handleFilterData: (e) => e,
                BookingRatesStates,
            }}
        >
            <div className={classes.container}>
                <div className={classes.containerTitleTable}>
                    <h2>Accounts</h2>
                    <AddFormButton label={"Add service"} handleOnClick={() => setAccountModal(true)} />
                </div>
                <div className={classes.containerTable}>
                    <Table fields={fieldsBookingRow} rowExtra={RowTableExtra} />
                </div>
            </div>
            <p className="d-flex flex-row justify-content-end m-0">Total de costo: {currency()} {totalCost.toFixed(2)}</p>
            <p className="d-flex flex-row justify-content-end m-0">Total de venta: {currency()} {totalSale.toFixed(2)}</p>
            <ModalForm showModal={accountModal} closeModal={() => setAccountModal(false)} title={"Add Account"}>
                <FormAddAccount
                    bookingId={values.id}
                    suppliers={Suppliers}
                    destinations={Destinations}
                    closeModal={() => setAccountModal(false)}
                    onSubmit={async () => {
                        setAccountModal(false);
                        reloadAccounts();
                    }}
                    loading={loading}
                />
            </ModalForm>
            <CommentsComponents values={values} />
        </context.Provider>
    );
};

export default Accounts;
