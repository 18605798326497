import React from 'react';
import classes from './InputCurrency.module.scss';
import { InputGroup, FormControl } from 'react-bootstrap'
import { useField } from 'formik'

const InputCurrency = React.memo(({name, onChange, currency,placeholder, ...props }) => {

    const [field, meta, helpers] = useField(name);

    const hadleOnChange = (e) => {
        field.onChange(e);
        if (onChange) {
            onChange(e.target.value)
        }
    }

    return (
        <InputGroup className={classes.containerCurrency} >
            <InputGroup.Text className={[classes.currency, props.className]}>{currency}</InputGroup.Text>
            <FormControl
                readOnly={props.readOnly}
                placeholder={placeholder}
                aria-label={placeholder}
                aria-describedby="basic-addon1"
                className={[classes.input, props.className]}
                name={name}
                {...field}
                onChange={hadleOnChange}
             

            />
        </InputGroup>
    )
})


export default InputCurrency;