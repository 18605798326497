import React, { useState, useEffect } from 'react';
import classes from './Users.module.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  AddFormButton, SearchInput, Table, Avatar, Loading,
  ModalForm, ButtonFilter, FormUser, ModalFilter
} from "../../components";
import _ from 'lodash';
import { serviceUsers } from '../../utils/API/apiService';

import { context } from '../../utils/CustomHook'
// revisar para aplicar useReducer



export const Users = (props) => {

  const [dataServer, setDataServer] = useState([]);
  const [modalFormShow, setModalFormShow] = useState(false);
  const [modalFilterShow, setModalFilterShow] = useState(false);
  const [filter, setFilter] = useState({});
  const [showLoading, setShowLoading] = useState(true);
  const [rowSelected, setRowSelected] = useState(null);
  const [search, setSearch] = useState("");
  const UserContext = context;

  useEffect(() => {
    if (_.isEmpty(dataServer)) {
      serviceUsers("getUsers")
        .then(res => {
          setDataServer(res)
          setShowLoading(false)
        })
        .catch(error => {
          setShowLoading(false);
          console.log(error)
        }
        )

    }

  }, [dataServer])


  const editRow = (values) => {
    setRowSelected(values);
    setModalFormShow(true);
  }

  const headers = [

    {
      key: "name",
      colSpan: 1,
      label: "NAME"
    },

    {
      key: "lastName",
      colSpan: 1,
      label: "LAST NAME"

    },
    {
      key: "companyName",
      colSpan: 1,
      label: "COMPANY NAME"

    },
    {
      key: "email",
      colSpan: 1,
      label: "EMAIL"

    },
    {
      key: "",
      colSpan: 1,
      label: " "
    },
    {
      key: "functions",
      colSpan: 2,
      label: ""

    }
  ]

  const fields = [


    {
      key: "name",
      decorator: (props) => (
        <span>{props.row.name}</span>
      ),
      classes: classes.col1
    },

    {
      key: "lastname",
      decorator: (props) => (
        <span>{props.row.lastname}</span>
      ),
      classes: classes.col2
    },
    {
      key: "company_name",
      decorator: (props) => (
        <span> {props.row.company_name}</span>
      ),
      classes: classes.col4
    },
    {
      key: "mail",
      decorator: (props) => (
        <span>{props.row.mail}</span>
      ),
      classes: classes.col3
    },
    {
      key: "state",
      decorator: (props) => (
        <span className={`${classes.status} ${classes[!!props.row.state ? "status-active" : ""]} `} />
      ),
      classes: classes.col5
    },
    {
      key: "",
      decorator: (props) => (
        <span> <Avatar firstName={props.row.name} lastName={props.row.lastname} color={props.row.color} classes={{ root: classes.avatar }} /></span>
      ),
      classes: classes.col6
    },
    {
      key: "state",
      decorator: (props) => (
        <i className={`icon-edit ${classes.iconEdit}`} onClick={() => editRow(props.row)} />
      ),
      classes: classes.col7
    }


  ]


  const handleOpenModal = () => {
    setRowSelected(null)
    setModalFormShow(true);
  }

  const handleCloseModal = () => {
    setModalFormShow(false);
  }

  const handleFilterData = (data) => {

    if (!_.isEmpty(filter)) {
      const filterValues = _.filter(data, user => {

        let role = true;
        let state = true;
        if (_.has(filter, "role")) {
          role = user.userRole.role === filter.role
        };
        if (_.has(filter, "state")) {
          state = user.state === filter.state.state
        };
        return role && state;
      });
      return filterValues;
    }
    else {
      return data
    }
  }

  const handleCloseModalFilter = () => setModalFilterShow(false);

  return (
    <>
      <h2>USERS</h2>


      <UserContext.Provider
        value={{
          dataServer,
          setDataServer,
          loading: setShowLoading,
          handleFilterData
        }}
      >
        <Loading showLoading={showLoading} />
        <div className={classes.containerActionsElements}>

          <SearchInput classes={{ root: classes.searchInput }} value={search} handleSearch={(value) => setSearch(value)} placeholder={"Search...."} from_booking={false} />

          <div className={classes.containerButtons}>
            <ButtonFilter
              label={"FILTER"}
              onClick={() => setModalFilterShow(true)}
            />
            <AddFormButton
              classes={{ root: classes.addButtomPosition }}
              label={"ADD USER"}
              handleOnClick={handleOpenModal}
            />

          </div>

        </div>
        <div className={`${classes.containerTable}`} >
          <Table headers={headers} fields={fields} search={search} sort={["name"]} />
        </div>
        <ModalForm showModal={modalFormShow} closeModal={handleCloseModal} title={"USERS FORM"}>

          <FormUser data={rowSelected} />

        </ModalForm>

        <ModalFilter filter={filter} showModal={modalFilterShow} closeModal={handleCloseModalFilter} setFilter={(value) => setFilter(value)} />

      </UserContext.Provider>
    </>
  )
}

