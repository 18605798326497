import React from 'react'
import classesView from './Suppliers.module.scss';
import { TextInputArea, Avatar } from '../../index';
import _ from 'lodash'

const Info = ({ data }) => {
    return (
        <div className={classesView.containerView}>
            <div className={classesView.containerRowHide}>
                <div className={classesView.containerHead}>
                    <h2>Description:</h2>
                </div>
                <TextInputArea className={`${classesView.textArea} `} cols={"100"} rows={"7"} name={"suppliers.description"} />
            </div>

        </div>
    )
}


export default Info;