import { useFormikContext } from "formik";
import _ from "lodash";
import React from "react";
import Men from "../../../public/img/usuario.svg";
import { context } from "../../../utils/CustomHook/index";
import { Avatar, Table } from "../../index";
import classes from "./Itinerary.module.scss";
import moment from "moment";

const getItinerary = (accounts, flights) => {
    const formatDate = "YYYY-MM-DD";
    const getFlightById = (fid) => flights.find(({ id }) => id === fid);

    const accountsMapped = [];
    accounts.forEach((supplier) => {
        supplier.accounts.forEach((account) => {
            let data = {};

            if (!_.isUndefined(account?.rate_data?.flight)) {
                const f = getFlightById(account?.rate_data?.flight.value);
                if(!f) return;

                data = {
                    color: "#6e9e4a",
                    startDate: f.date,
                    description: `${f.airport_origin.name} → ${f.airport_destination.name}`,
                    code: f.flight_number,
                    time: f.departure_time,
                };
            }

            const totalSale =
                (account.alicuota / 100) * account.cost + (account.markup / 100) * account.cost + account.cost;

            if (account?.rate_data?.rateType === "BULK") {
                data.cost = totalSale / (account.rate_data?.paxs?.length || 1);
            }

            data = {
                avatar: supplier.supplier.name.substring(0, 2),
                color: "rgb(224 165 26)",
                startDate: account.rate_data.date,
                // endDate: '2001-01-02',
                cost: totalSale,
                sale: totalSale,
                description: account.rate_name,
                info: account?.rate_data?.description,
                paxs: account.rate_data?.paxs,
                status: {
                    color: account.booking_rate_state.code === "OK" ? "#5f9139" : "#7d7d7d",
                    claim: account.booking_rate_state.code,
                },
                ...data,
            };

            accountsMapped.push(data);
        });
    });

    flights.forEach((f) =>
        accountsMapped.push({
            color: "rgb(85 183 166)",
            startDate: f.date,
            avatar: f.airport_destination.name.substring(0, 2),
            description: `${f.airport_origin.name} → ${f.airport_destination.name}`,
            code: f.flight_number,
            time: f.departure_time,

            // endDate: '2001-01-02',
            cost: " ",
            sale: " ",
            info: " ",
            paxs: " ",
            status: {
                color: "rgb(85 183 166)",
                claim: "FL",
            },
        })
    );

    return [...accountsMapped].sort((a, b) =>
        moment(a.startDate, formatDate).diff(moment(b.startDate, formatDate), "days", true)
    );
};

const Itinerary = () => {
    const { values } = useFormikContext();
    const formatDate = (strDate) => strDate.split("-").reverse().join("-");

    const fieldsBookingRow = [
        {
            decorator: (props) => (
                <span>
                    <Avatar initials={props.row.avatar} color={props.row.color} classes={{ root: classes.avatar }} />
                </span>
            ),
            classes: classes.col1,
        },
        {
            key: "date",
            decorator: (props) => (
                <div className={classes.content}>
                    <span>
                        <div className={classes.containerTdVertical}>
                            <p className={classes.letterClassTypeRate}>
                                {props.row?.startDate ? formatDate(props.row.startDate) : ""}
                            </p>
                            <p className={classes.letterClassTypeRate}>
                                {props.row?.endDate ? formatDate(props.row.endDate) : ""}
                            </p>
                        </div>
                    </span>
                </div>
            ),
            classes: classes.col2,
        },
        {
            key: "description",
            decorator: (props) => (
                <div className={classes.content}>
                    <span>{props.row.description}</span>
                </div>
            ),
            classes: classes.col3,
        },
        {
            key: "info",
            decorator: (props) => (
                <div className={classes.content}>
                    {!_.isUndefined(props.row.code, "") ? (
                        <strong>
                            <span>{props.row.code}</span>
                        </strong>
                    ) : (
                        <span style={{ color: "#008000" }}>{props.row.info}</span>
                    )}
                </div>
            ),
            classes: classes.col4,
        },
        {
            key: "time",
            decorator: (props) => (
                <div className={classes.content}>
                    {!_.isUndefined(props.row?.time) ? (
                        <span>{props.row.time}</span>
                    ) : (
                        <>
                            {props?.row?.paxs?.slice(0, 5).map((_, i) => (
                                <img key={i} src={Men} width="20px" height="20px" alt="people" />
                            ))}
                        </>
                    )}
                </div>
            ),
            classes: classes.col5,
        },
        {
            key: "cost",
            decorator: (props) => (
                <div className={classes.content}>
                    <span>{props.row.cost}</span>
                </div>
            ),
            classes: classes.col3,
        },
        {
            key: "sale",
            decorator: (props) => (
                <div className={classes.content}>
                    <span>{props.row.sale}</span>
                </div>
            ),
            classes: classes.col3,
        },
        {
            key: "status",
            decorator: (props) => (
                <div className={classes.content}>
                    <span>
                        <Avatar
                            initials={props.row.status.claim}
                            color={props.row.status.color}
                            classes={{ root: classes.avatar }}
                        />
                    </span>
                </div>
            ),
            classes: classes.col6,
        },
    ];

    return (
        <context.Provider
            value={{
                dataServer: getItinerary(values.accounts, values.flights),
                handleFilterData: (e) => e,
            }}
        >
            <div className={classes.container}>
                <div className={classes.containerTitleTable}>
                    <h2>Itinerary</h2>
                </div>
                <div className={classes.containerTable}>
                    <Table fields={fieldsBookingRow} />
                </div>
            </div>
        </context.Provider>
    );
};
export default Itinerary;
