import React from 'react'
import classes from './CommentsComponent.module.scss';
import { useField, Formik, Form, useFormikContext, } from 'formik';
import { Comments } from '../../../../index';
import { serviceBooking, serviceComponents } from '../../../../../utils/API/apiService';

function CommentsComponent({ values }) {

  const { setFieldValue } = useFormikContext();

  const handleSubmitComment = async (comment, values) => {
    await serviceBooking("CreateCommentBooking", { booking: { ...values }, event: "comment", comment })
      .then(() => {
        setFieldValue('comments', [comment, ...values.comments]);
      })
  };

  const handleDeleteComment = async (comment, values) => {
    await serviceBooking("deleteCommentBooking", { booking: { ...values }, event: "comment", comment }).then(() => {
      setFieldValue('comments', values.comments.filter(c => c !== comment));
    })
  }


  return (
    <div className={classes.containerComments}>
      <div className={classes.containerTitleComments}>
        <h2>Comments</h2>
      </div>
      <Comments
        values={values}
        setFieldValue={setFieldValue}
        handleSubmitComment={handleSubmitComment}
        classNameTextArea={classes.commentsBookingTextArea}
        handleDeleteComment={handleDeleteComment}
      />
    </div>
  )
}

export default CommentsComponent;

