import React from 'react';
import classes from './Content.module.scss';
import { Menu } from '../index';
import { useHistory } from 'react-router-dom'

export default function Content({ children, layout, ...rest }) {

    const handleGoTo = (url) => {
        rest.history.push(url);
    }
    const getLayuot = () => {
        switch (layout) {
            case "DASHBOARD":
                return (
                    <div className={classes.Dashboard}>
                        <div className={classes.containerMenu}>
                            <Menu goTo={handleGoTo} />
                        </div>

                        <div className={classes.zoneAction}>
                            {children}
                        </div>

                    </div>
                )
            default:
                return (
                    <>
                        {children}
                    </>
                )
        }
    }


    return (
        <React.Fragment>
            {getLayuot()}
        </React.Fragment>
    )

}
