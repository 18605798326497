import React, { useState, useEffect } from 'react'
import classes from './Booking.module.scss'
import {
    SearchInput, 
    AddFormButton, 
    ButtonFilter, 
    Table, 
    Avatar, 
    ModalFilterGeneric,
    Itinerary, 
    Checkbox, 
    Acounts, 
    RowTableExtra, 
    ControlledTabsBooking, 
    Loading
} from '../../components/index'
import _ from 'lodash'
import moment from "moment";
import { context, useGetListsForBooking } from '../../utils/CustomHook'
import FormAddBooking from '../../components/Forms/FormAddBooking/FormAddBooking';
import ModalForm from '../../components/Modal/ModalForm';
import { BookingFilter } from '../../components/Forms/Filter/index';
import { serviceBooking } from '../../utils/API/apiService';

const Booking = () => {
    const lists = useGetListsForBooking();
    const [search, setSearch] = useState("");
    const [open, setOpen] = useState(false)
    const [dataRow, setDataRow] = useState(null)
    const [showModal, setShowModal] = useState(false);
    const [showModalFilter, setShowModalFilter] = useState(false);
    const [showLoading, setShowLoading] = useState(true);
    const [filterValues, setFilterValues] = useState({});
    const [dataServer, _setDataServer] = useState([]);

    const setDataServer = (data) => _setDataServer(data?.sort((a,b) => a?.id-b?.id));


    const handleCloseModal = () => {
        setShowModal(false);
    }

    useEffect(() => {
        if (_.isEmpty(dataServer)) {
            setShowLoading(true);
            serviceBooking("getBooking")
                .then(res => setDataServer(res))
                .catch(error => console.log(error))
                .finally(() => setShowLoading(false));
        }
    }, [])



    const getBookingAccounts = async (booking) => {
        if(!booking?.id) return [];
        setShowLoading(true);
        const rates = await serviceBooking("getBookingRates", booking).catch(() => []);
        const rateDataParse = rates.map(a => {
            try {
                const result = ({...a, rate_data: JSON.parse(a.rate_data) })
                return result;
            } catch (error) {
                return a;
            }            
        });

        const combinedBySupplier = rateDataParse.reduce((p, c) => {
            const formatDate = 'YYYY-MM-DD';
            const getMinorDate = (a,b) => moment(a,formatDate).diff(moment(b, formatDate), 'days', true) < 0? a : b;
            const getMajorDate = (a,b) => moment(a,formatDate).diff(moment(b, formatDate), 'days', true) > 0? a : b;
            const index = p.findIndex(pre => pre.supplier.id === c.supplier_rate.supplier.id);
            c.cost = (+c?.cost || 0);
            c.sale = (+c?.sale || 0);

            if(index===-1) {
                return [...p, {
                    supplier: c.supplier_rate.supplier,
                    startDate: c?.rate_data?.date || moment().format(formatDate).toString(),
                    endDate: c?.rate_data?.date || moment().format(formatDate).toString(),
                    totalCost: c.cost,
                    totalSale: c.sale,
                    accounts: [c] 
                }];
            }
            
            p[index].totalCost += c.cost
            p[index].totalSale += c.sale
            p[index].startDate = getMinorDate(p[index].startDate, c.rate_data?.date);
            p[index].endDate = getMajorDate(p[index].endDate, c.rate_data?.date);
            p[index].accounts = [...p[index].accounts, c ];
            return p;
        } ,[])

        setShowLoading(false);
        return combinedBySupplier;
    }

    const handleRow = async (value) => {
        if(!open) {
            value.accounts = await getBookingAccounts(value);
        }

        setDataRow(value)
        setOpen(!open)
    }

    const handleFilterData = (data) => {
        if (!_.isEmpty(filterValues)) {

            return _.filter(data, sup => {

                let isState = true;
                let isDestination = true;

                if (!_.isNil(filterValues.state)) {
                    isState = sup.state == filterValues.state
                }

                if (!_.isNil(filterValues.destination)) {
                    isDestination = sup.destination.id === filterValues.destination.id
                }

                return isDestination && isState;
            })

        }

        return data;
    }

    const headers = [
        {
            key: "",
            colSpan: 1,
            label: ""
        },
        {
            key: "code",
            colSpan: 1,
            label: "CODE"
        },
        {
            key: "reference",
            colSpan: 1,
            label: "REFERENCE"

        },
        {
            key: "pax",
            colSpan: 1,
            label: "PAX"

        },
        {
            key: "start",
            colSpan: 1,
            label: "DATE START"

        },
        {
            key: "end",
            colSpan: 2,
            label: "DATE END"

        },
        {
            key: "client",
            colSpan: 3,
            label: "CLIENT"

        }
    ]

    const fieldsBooking = [
        {
            decorator: (props) => (
                <Checkbox />
            ),
            classes: classes.col1
        },
        {
            key: "code",
            decorator: (props) => (
                <div className={classes.content}>
                    <strong>
                        <span>{props.row.booking_code}</span>
                    </strong>
                </div>
            ),
            classes: classes.col2
        },
        {
            key: "reference",
            decorator: (props) => (
                <div className={classes.content}>
                    <span>{props.row.reference}</span>
                </div>
            ),
            classes: classes.col3
        },
        {
            key: "paxs",
            decorator: (props) => (
                <div className={classes.content}>
                    <span>{props.row.paxs?.length ? `${props.row.paxs.length} pax` : `0 pax`}</span>
                </div>
            ),
            classes: classes.col4
        },
        {
            key: "date_start",
            decorator: (props) => (
                <div className={classes.content}>
                    <span>{props.row.date_in}</span>
                </div>
            ),
            classes: classes.col5
        },
        {
            key: "date_end",
            decorator: (props) => (
                <div className={classes.content}>
                    <span>{props.row.date_out}</span>
                </div>
            ),
            classes: classes.col6
        },
        {
            key: "",
            decorator: (props) => (
                <span> <Avatar firstName={props.row.seller?.name || ''} lastName={props.row.seller?.lastname || ''}
                    color={props.row.color} classes={{ root: classes.avatar }} /></span>
            ),
            classes: classes.col7
        },
        {
            key: "",
            decorator: (props) => (
                <div className={classes.content}>
                    <strong>
                        <span>{props.row.client?.name || ''}</span>
                    </strong>
                </div>
            ),
            classes: classes.col8
        },
        {
            key: "",
            decorator: (props) => (
                <div className={classes.content}>
                    <i className={`icon-arrow  ${classes.iconArrow}`} onClick={() => handleRow(props.row)} />
                </div>
            ),
            classes: classes.col9
        }

    ]

    const updateBooking = (bookingData) => {
        if(showLoading) return;
        setShowLoading(true);
        serviceBooking("updateBooking", { booking: bookingData })
            .then(() => {
                setDataRow({...bookingData});
                setShowLoading(false);
            })
            .catch((e) => console.error("%c⧭", "color: #ff0000", e))
            .finally(() => setShowLoading(false));
    }

    return (
        <context.Provider
            value={{
                dataServer,
                handleFilterData,
                getBookingAccounts,
                loading: setShowLoading,
                ...lists

            }}
        >
            <Loading showLoading={showLoading} ></Loading>
            {!open ? <>
                <h2>Booking</h2>

                <div className={classes.containerActionsElements}>


                    <div className={classes.containerButtons}>

                        <SearchInput classes={{ root: classes.searchInput }} handleSearch={(value) => setSearch(value)} placeholder={"Search...."} from_booking={true} />

                        <div className={classes.containerButtons}>
                            <ButtonFilter
                                label={"FILTER"}
                                onClick={() => setShowModalFilter(true)}
                            />

                            <AddFormButton
                                classes={{ root: classes.addButtomPosition }}
                                label={"NEW BOOKING"}
                                handleOnClick={() => setShowModal(true)}
                            />

                        </div>
                    </div>
                </div>
                <ModalForm showModal={showModal} closeModal={handleCloseModal} title={"NEW BOOKING"} className={{ modal: classes.modal }} >
                    <FormAddBooking dataServer={dataServer} setDataServer={setDataServer} showLoading={showLoading} setShowLoading={setShowLoading}/>
                </ModalForm>


                <ModalFilterGeneric showModal={showModalFilter} closeModal={() => setShowModalFilter(false)} title={"FILTER BOOKING"}>
                    <BookingFilter handleFilterValues={setFilterValues} initialValues={filterValues} />
                </ModalFilterGeneric>



                <div className={classes.containerTable}>
                    <Table headers={headers} fields={fieldsBooking} rowExtra={false} search={search} isBooking={true} />
                </div>
            </> : <>

                    <div className={classes.containerTabsBooking}>

                        <div className={classes.containerRowSelected}>
                            <div className={classes.containerRow} >
                                <Checkbox />
                                <div >
                                    <strong>
                                        <span>{dataRow.booking_code}</span>
                                    </strong>
                                </div>
                                <div className={classes.content}>
                                    <span>{dataRow.reference}</span>
                                </div>

                                <div className={classes.content}>
                                    <span>{dataRow.paxs?.length ? `${dataRow.paxs.length} pax` : `0 pax`}</span>
                                </div>
                                <div className={classes.content}>
                                    <span>{dataRow.date_in}</span>
                                </div>
                                <div className={classes.content}>
                                    <span>{dataRow.date_out}</span>
                                </div>
                                <div >
                                    <span> <Avatar firstName={dataRow.seller?.name || ''} lastName={dataRow.seller?.lastname || ''}
                                        color={dataRow.color} classes={{ root: classes.avatar }} /></span>
                                </div>
                                <div className={classes.content}>
                                    <strong>
                                        <span>{dataRow.client?.name || ''}</span>
                                    </strong>
                                </div>
                                <div className={classes.content}>
                                    <i className={`icon-arrow  ${classes.iconArrow}`} onClick={() => setOpen(false)} />
                                </div>
                            </div>

                            <p className={classes.containerText}>Created 16/85/2020</p>
                        </div>

                        <ControlledTabsBooking data={dataRow} updateBooking={updateBooking} />
                    </div>
                </>}
        </context.Provider>


    )
}
export default Booking
