import React, { useContext, useEffect, useRef } from 'react';
import { useField, Formik, Form, useFormikContext } from 'formik';
import * as Yup from 'yup';
import classes from "./Suppliers.module.scss";
import { TextInputForm, Avatar, SwitchForm, SelectForm } from "../../index";
import { TextInput, Select } from '../../NewComponents';
import { context } from '../../../utils/CustomHook';
import _, { set } from 'lodash';
import { TextLabelCompent, TextInfo } from '../../Text/FactoryText';
import { Row, Col } from '../../Grid/Grid'


const Admin = ({ data }) => {
    const { Countries, Currencies, ClientTypes, IvaConditions } = useContext(context);
    const { DocumentTypes, Languages, RelationsAgency, PaymentMethods } = useContext(context);
    const selectRegionRef = useRef(null);

    const mapListSelect = (list, nameLabel) => {
        return _.map(list, element => ({ value: element, label: element[nameLabel] }))
    }
    const getElementList = (value, labelName = "name") => {
        return value.id ? { value, label: value[labelName] } : null;
    }

    useEffect(() => {

        if (_.has(data, "suppliers.country") && Countries) {
            getRegionList(data.suppliers);
        }

    }, [Countries])

    const { values, setFieldValue } = useFormikContext();

    const getRegionList = ({ country }) => {
        let contrySelected = _.find(Countries, c => c.name === _.get(country, "name"));
        return contrySelected ? _.map(contrySelected.regions, city => ({ label: city.name, value: city })) : [];
    }

    return (
        <div className={classes.containerForm} >

            <div className={classes.containerHead} >
                <h2>{data.suppliers.name}</h2>
            </div>



            <Row >
                <TextInput
                    placeholder={"Direccion de Facturacion..."}
                    name={"suppliers.address"}
                    type={"text"}
                    label={"Direccion de Facturacion:"}
                    classNameLabel={`firstCol`}

                />
            </Row>

            <Row grid={'grid-3'} >
                <Col>
                    <TextInput
                        placeholder={"Razon Social..."}
                        name={"suppliers.businessName"}
                        type={"text"}
                        label={'Razon Social:'}
                        classNameLabel={`firstCol`}
                    />

                </Col>
                <Col>
                    <TextLabelCompent label={"Ciudad:"} />
                    <Select
                        placeholder={"Selecciona Ciudad"}
                        disabled={!_.get(data, "suppliers.country")}
                        onChange={value => setFieldValue("suppliers.region", value)}
                        list={getRegionList(data.suppliers)}
                        value={_.get(values, 'suppliers.region')}
                        innerRef={selectRegionRef}
                        clear={true}
                    />
                </Col>
                <Col>
                    <TextLabelCompent label={"Pais:"} />
                    <Select
                        placeholder={"Selecciona Pais"}
                        list={mapListSelect(Countries, "name")}
                        onChange={value => setFieldValue('suppliers.country', value)}
                        value={_.get(values, 'suppliers.country')}
                        callback={() => selectRegionRef.current.select.clearValue()}
                        clear={true}
                    />
                </Col>


            </Row>

            <Row >

                <TextLabelCompent label={"Relacion Emisor Receptor:"} firstColum={true} />
                <Select
                    placeholder={"Selecciona el tipo de relacion"}
                    list={mapListSelect(RelationsAgency, "name")}
                    onChange={value => setFieldValue('suppliers.relationAgency', value)}
                    value={_.get(values, 'suppliers.relationAgency')}
                    clear={true}
                />

            </Row>
            <Row grid={'grid-3'} >
                <Col>
                    <TextLabelCompent label={"Idioma:"} firstColum={true} />
                    <Select
                        placeholder={"Selecciona idioma"}
                        list={mapListSelect(Languages, "name")}
                        onChange={value => setFieldValue('suppliers.language', value)}
                        value={_.get(values, 'suppliers.language')}
                        clear={true}

                    />
                </Col>
                <Col>
                    <TextLabelCompent label={"Moneda:"} />
                    <Select
                        name={"suppliers.currency"}
                        placeholder={"Selecciona Moneda"}
                        list={mapListSelect(Currencies, "iso")}
                        onChange={value => setFieldValue('suppliers.currency', value)}
                        value={_.get(values, 'suppliers.currency')}
                        clear={true}
                    />
                </Col>

                <TextInfo />

            </Row>
            <Row grid={'grid-3'} >
                <Col>
                    <TextLabelCompent label={"Documento:"} firstColum={true} />
                    <Select
                        name={"suppliers.documentType"}
                        placeholder={"Selecciona Documento"}
                        list={mapListSelect(DocumentTypes, "name")}
                        onChange={value => setFieldValue('suppliers.documentType', value)}
                        value={_.get(values, 'suppliers.documentType')}
                        clear={true}
                    />
                </Col>
                <TextInput
                    placeholder={"Numero de documento..."}
                    name={"suppliers.document"}
                    type={"text"}
                    label={'Numero de Documento:'}
                />
                <TextInfo />




            </Row>
            <Row grid={'grid-3'} >
                <Col>
                    <TextLabelCompent label={"Forma de Pago:"} firstColum={true} />
                    <Select
                        name={"suppliers.paymentMethod"}
                        placeholder={"Selecciona Forma De Pago"}
                        list={mapListSelect(PaymentMethods, "name")}
                        onChange={value => setFieldValue('suppliers.paymentMethod', value)}
                        value={_.get(values, 'suppliers.paymentMethod')}
                        clear={true}
                    />
                </Col>
                <TextInput
                    placeholder={"El vencimiento es un entero..."}
                    name={"suppliers.expiration"}
                    type={"number"}
                    label={'Vencimiento'}
                />
                <TextInfo />

            </Row>

            <div className={classes.containerCommisions}>
                <h3>Comission</h3>
                <div className={classes.rowComission}>
                    <Col >
                        <TextLabelCompent label={"Fundation Donnation"} firstColum />
                        <TextInput
                            placeholder={""}
                            name={"suppliers.supplierCommisions.fundationDonation"}
                            type={"text"}
                            maxLength={6}
                            append={'%'}
                        />
                    </Col>
                    <Col >
                        <TextLabelCompent label={"Markup"} />
                        <TextInput
                            placeholder={""}
                            name={"suppliers.supplierCommisions.markup"}
                            type={"text"}
                            maxLength={6}
                            append={'%'}
                        />
                    </Col>
                </div>

            </div>
        </div>

    )
}

export default Admin;


