import { Formik } from "formik";
import _, { isEmpty } from "lodash";
import moment from "moment";
import React, { useContext } from "react";
import * as Yup from "yup";
import { context } from "../../../../../utils/CustomHook";
import { Row } from "../../../../Grid/Grid";
import { Select, TextInput } from "../../../../NewComponents/index";
import DateInput from "../../../../TextInputs/DateInput/DateInput";
import DateTime from "../../../../TextInputs/DateTime/DateTime";
import classes from "./FormFlights.module.scss";

const FormFlights = ({ values = {}, formRef, closeModal, onSubmit, ...props }) => {
    const { Airports } = useContext(context);

    const mapListSelect = (list, nameLabel = "name") => {
        return _.map(list, (element) => ({ value: element, label: element[nameLabel] }));
    };

    const submitForm = (values) => {
        const parseTimeToMilitary = (time) => moment(`01 Jan 2021 ${time}`).format("HH:mm");
        values.departure_time = parseTimeToMilitary(values.departure_time);
        values.arrival_time = parseTimeToMilitary(values.arrival_time);
        values.state = !!values.state ? 1 : 0;
        onSubmit(values);
        closeModal();
    };

    const initialValues = isEmpty(values) ? { state: 1 } : values;
    const parseTime = (time) => Date.parse(`01 Jan 2021 ${time}`);

    const FlightSchema = Yup.object().shape({
        flight_number: Yup.string().required("Required"),
        reservation_number: Yup.string().required("Required"),
        airport_origin: Yup.object().required("Required"),
        airport_destination: Yup.object().required("Required"),
        date: Yup.date().required("Required"),
        departure_time: Yup.string().required("Required"),
        arrival_time: Yup.string().required("Required"),
    });

    return (
        <Formik initialValues={initialValues} validationSchema={FlightSchema} innerRef={formRef} onSubmit={submitForm}>
            {({ handleSubmit, handleChange, handleBlur, values, setFieldValue, errors }) => {
                return (
                    <div className={classes.containerFormPaxs}>
                        <Row>
                            <TextInput placeholder={"Number Flight..."} name={"flight_number"} type={"text"} />
                            <TextInput
                                placeholder={"Number Reservation..."}
                                name={"reservation_number"}
                                type={"text"}
                            />
                        </Row>

                        <Row>
                            <Select
                                placeholder={"Select Airport Origin...."}
                                list={mapListSelect(Airports)}
                                onChange={(value) => setFieldValue("airport_origin", value)}
                                value={_.get(values, "airport_origin")}
                                clear
                            />
                            <Select
                                placeholder={"Select Airport Destination...."}
                                list={mapListSelect(Airports)}
                                onChange={(value) => setFieldValue("airport_destination", value)}
                                value={_.get(values, "airport_destination")}
                                clear
                            />
                        </Row>
                        <Row>
                            <DateInput
                                placeholder={"Date..."}
                                name={"date"}
                                onChagedDate={(value) => setFieldValue("date", value)}
                                value={Date.parse(_.get(values, "date"))}
                            />
                        </Row>
                        <Row className={classes.dateRow}>
                            <DateTime
                                placeholder={"Departure Time..."}
                                name={""}
                                onChagedDate={(value) => setFieldValue("departure_time", value)}
                                value={parseTime(_.get(values, "departure_time"))}
                            />

                            <DateTime
                                placeholder={"Arrival Time..."}
                                name={""}
                                onChagedDate={(value) => setFieldValue("arrival_time", value)}
                                value={parseTime(_.get(values, "arrival_time"))}
                            />
                        </Row>
                    </div>
                );
            }}
        </Formik>
    );
};

export default FormFlights;
