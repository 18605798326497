import { Formik } from 'formik';
import _, { isEmpty } from 'lodash';
import React, { useContext } from 'react';
import * as Yup from "yup";
import { context } from '../../../../../utils/CustomHook';
import { Row } from '../../../../Grid/Grid';
import { Select, TextInput } from '../../../../NewComponents/index';
import DateInput from '../../../../TextInputs/DateInput/DateInput';
import classes from './FormPaxs.module.scss';


const FormPaxs = ({ values = {}, formRef, closeModal, onSubmit, ...props }) => {
    const { Countries } = useContext(context);

    const mapListSelect = (list, nameLabel = 'name') => {
        return _.map(list, element => ({ value: element, label: element[nameLabel] }))
    }
    const handleSelectCountry = (value, setFieldValue) => {
        setFieldValue('nationality', value);
    }

    const submitForm = (values) => {
        onSubmit(values)
        closeModal()
    }

    const initialValues = isEmpty(values) ? { state: 1 } : values

    const PaxSchema = Yup.object().shape({
        lastname: Yup.string().required("Required"),
        mobile_phone: Yup.string().required("Required"),
        name: Yup.string().required("Required"),
        nationality: Yup.object().required("Required"),
        born_date: Yup.string().required("Required"),
        passport_number: Yup.string().required("Required"),
    });

    return (
        <Formik
            validationSchema={PaxSchema}
            initialValues={initialValues}
            innerRef={formRef}
            onSubmit={submitForm}
        >
            {({ handleSubmit, handleChange, handleBlur, values, setFieldValue, errors }) => {
                return (
                    <div className={classes.containerFormPaxs} >
                        <Row >
                            <TextInput
                                placeholder={"First Name..."}
                                name={"name"}
                                type={"text"}
                            />
                            <TextInput
                                placeholder={"Last Name..."}
                                name={"lastname"}
                                type={"text"}
                            />
                        </Row>

                        <Row >
                            <Select
                                placeholder={"Select nationality"}
                                list={mapListSelect(Countries)}
                                onChange={(value) => handleSelectCountry(value, setFieldValue)}
                                value={_.get(values, "nationality")}
                                clear={true}

                            />
                        </Row >
                        <Row >
                            <TextInput
                                placeholder={"Passport Number..."}
                                name={"passport_number"}
                                type={"text"}
                            />
                            <TextInput
                                placeholder={"Phone..."}
                                name={"mobile_phone"}
                                type={"text"}
                            />
                        </Row>
                        <Row className={classes.rowDatesFormPax}>
                            <DateInput
                                classNameDate={classes.dateFormAddPax}
                                placeholder={"Born Date..."}
                                name={"born_date"}
                                onChagedDate={(value) => setFieldValue("born_date", value)}
                                value={Date.parse(_.get(values, "born_date"))}
                            />
                            <DateInput
                                classNameDate={classes.dateFormAddPax}
                                placeholder={"Passport Emision Date..."}
                                name={"passport_emision_date"}
                                onChagedDate={(value) => setFieldValue("passport_emision_date", value)}
                                value={Date.parse(_.get(values, "passport_emision_date"))}
                            />
                        </Row>
                    </div>
                )
            }}
        </Formik>
    )
}


export default FormPaxs;