import React, { useState, useRef } from 'react';
import classes from './TextAddServices.module.scss';
import { FieldArray } from 'formik';
import _ from 'lodash';
import { ButtonForm, SwitchForm, InputCurrency, SelectForm } from '../../index'
import { TextInput } from '../../NewComponents/index'
import { Select } from '../../NewComponents/index'
const TextAddServices = ({ name, rateTypes }) => {

    const containerRef = useRef();

    const mapListSelect = (list, nameLabel) => {
        return _.map(list, element => ({ value: element, label: element[nameLabel] }))
    }
    const getElementList = (value, labelName = "name") => {
        return value.id ? { value, label: value[labelName] } : null;
    }


    return (
        <FieldArray
            name={name}
            render={arrayHelpers => {
                const items = _.get(arrayHelpers, `form.values[${name}]`, []);
                const handleAdd = () => {
                    arrayHelpers.push({ state: true })


                }
                const { setFieldValue, values } = arrayHelpers.form;
                const handleUpdateAmount = (name, value) => {

                    const total = _.toNumber(value) + ((value * _.toNumber(_.get(values, 'porcentajeIVA', 1))) / 100);
                    setFieldValue(name, total);
                }
                return (
                    <>
                        <div className={`scrollbar ${classes.containerItems}`} >
                            {

                                items.map((item, index) => {
                                    return (
                                        <div className={classes.item} key={index + "item"}>
                                            <SwitchForm className={classes.switch} borderBottom={true} name={`${name}.${index}.state`} />
                                            <TextInput name={`${name}[${index}].name`} type={"text"} placeholder={"Item Services..."} />
                                            <InputCurrency name={`${name}[${index}].amount`} type={"number"} currency={'USD'} placeholder={""}
                                                onChange={(value) => handleUpdateAmount(`${name}[${index}].amountIVA`, value)} />
                                            <InputCurrency name={`${name}[${index}].amountIVA`} step="0.01" readOnly={true} currency={'USD'} type={"number"} placeholder={""} />
                                            <Select

                                                placeholder={"Select"}
                                                list={mapListSelect(rateTypes, "name")}
                                                value={_.get(values, `${name}[${index}].rateTypes`)}
                                                onChange={value => setFieldValue(`${name}[${index}].rateTypes`, value)}
                                                search={false}

                                            />
                                        </div>
                                    )
                                })
                            }
                        </div>

                        <ButtonForm className={classes.buttonAdd} handleClik={handleAdd} label={"ADD RATE"} />

                    </>
                )
            }} />

    )
}


export default TextAddServices;