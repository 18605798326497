import Form from 'react-bootstrap/Form';
import React from 'react';
import { InputGroup, FormControl } from 'react-bootstrap'
import { useField } from 'formik';
import classes from './TextInput.module.scss';


export default function TextInput({ label, type = 'text', classNameLabel, placeholder, append, name, ...props }) {

    const [field] = useField(name);

    return (
        <>

            <InputGroup className={classes.container} >
               {label && <InputGroup.Text bsPrefix={`${classes.label} ${classNameLabel}`} >{label}</InputGroup.Text>}
                <FormControl
                    placeholder={placeholder}
                    aria-label={placeholder}
                    className={classes.input}
                    type={type}
                    name={name}
                    {...field}
                    value={field.value ||"" }
                    
                    {...props}
                />
                {append &&
                    <InputGroup.Append>
                        <InputGroup.Text bsPrefix={`${classes.label}`} >{append}</InputGroup.Text>
                    </InputGroup.Append>
                }

            </InputGroup>
            {/* <Form.Control.Feedback type="invalid" tooltip>Looks good!</Form.Control.Feedback>
*/}
        </>
    )
}
