import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Login from '../containers/Login/Login';
import RecoverPassword from '../containers/Login/RecoverPassword';
import Dashboard from '../containers/Dasboard/Dashboard';
import ResetPassword from '../containers/Login/ResetPassword';
import Contact from '../containers/Contact/Contact';
import Clients from '../containers/Clients/Clients';
import Booking from '../containers/Booking/Booking'
import Suppliers from '../containers/Suppliers/Suppliers'
import Destinations from '../containers/Destinations/Destinations'
import ManagerRoute from '../components/Managers/ManagerRoute';
import { Users } from '../containers/Users/Users';
import { PAGES } from '../utils/Constants/pages'


//poner un contador y volar la session

const PagedNotFound = () => {
    return (
        <div>
            Paged Not Found
        </div>
    )
}

const RouterApp = (props) => {

    return (

        <Router >
            <Switch>
                <Route path={PAGES.raiz.path} exact strict component={Login} />

                <Route path={PAGES.login.path} exact strict component={Login} />

                <ManagerRoute path={PAGES.dashboard.path} layout={PAGES.dashboard.layout} >
                    <Dashboard />
                </ManagerRoute >
                <ManagerRoute path={PAGES.users.path} layout={PAGES.dashboard.layout}>
                    <Users />
                </ManagerRoute>
                <ManagerRoute path={PAGES.contacts.path} layout={PAGES.contacts.layout} >
                    <Contact />
                </ManagerRoute>
                <ManagerRoute path={PAGES.booking.path} layout={PAGES.booking.layout} >
                    <Booking data={props} />
                </ManagerRoute >
                <ManagerRoute path={PAGES.clients.path} layout={PAGES.clients.layout} >
                    <Clients />
                </ManagerRoute >
                <ManagerRoute path={PAGES.suppliers.path} layout={PAGES.suppliers.layout}  >
                    <Suppliers />
                </ManagerRoute >
                <ManagerRoute path={PAGES.programs.path} layout={PAGES.programs.layout}  >
                    <div>programs</div>
                </ManagerRoute >
                <ManagerRoute path={PAGES.destinations.path} layout={PAGES.destinations.layout}  >
                    <Destinations />
                </ManagerRoute >
                <ManagerRoute path={PAGES.tariff.path} layout={PAGES.tariff.layout}  >
                    <div>Tarifas</div>
                </ManagerRoute >


                <Route path='/recoverPassword' component={RecoverPassword} />

                <Route path='/reset-password/:token' component={ResetPassword} />

                <Route path='*' component={PagedNotFound} />

            </Switch>
        </Router>

    )
}


export default RouterApp;