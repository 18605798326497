import React from 'react'
import classesButton from './ButtonForm.module.scss';


const ButtonForm = ({ label, handleClik, className, classes, type, ...rest }) => {

    const rootClasses = classes ? classes.root : "";


    return (
        <button type={type} onClick={handleClik} {...rest} className={`${classesButton.submitButton} ${rootClasses} ${className}`}>
            {label}
        </button>
    )
}


export default ButtonForm;