
import classes from './ControlledTabSup.module.scss';
import React, { useState, useContext } from 'react';
import Tabs from '../../Tabs/Tabs'
import { Formik } from 'formik';
import Comments from '../../Comments/Comments';
import { Admin, Legal, Supplier, Rates, Info } from '../../TabsView/Suppliers';
import { serviceSuppliers } from '../../../utils/API/apiService';
import { context } from '../../../utils/CustomHook';
import { sendNotification } from '../../../utils/helper/notification';
import 'bootstrap/dist/css/bootstrap.min.css';

import _ from 'lodash'



const ControlledTabSup = ({ data, closeModal, formRef }) => {
  const [key, setKey] = useState('suppliers');

  const keys = [
    { name: "SUPPLIERS", key: "suppliers" },
    { name: "ADMIN", key: "admin" },
    { name: "INFO", key: "info" },
    { name: "LEGAL", key: "legal" },
    { name: "RATES", key: "rates" }

  ]

  const tranformRatesInitial = (rate) => {
    return _.map(rate, r => ({ ...r, data: JSON.parse(r.data) }));
  }

  const tranformRatesFinish = (rate) => {
    return _.map(rate, r => ({ ...r, data: JSON.stringify(r.data) }));
  }


  const tranforInitialData = (values) => {
    return {
      suppliers: {
        ..._.omit(values, "comments"),
        country: _.get(values, "region.country"),
        rates: tranformRatesInitial(_.get(values, "rates"))
      }, ..._.pick(values, "comments")
    }
  }

  const initialValues = _.isEmpty(data) ? { suppliers: { color: "#7d7d7d" }, comments: [] } : tranforInitialData(data);
  const { dataServer, loading, setDataServer } = useContext(context);


  const updataData = (suppliers, comments) => {
    const copyDataServer = _.cloneDeep(dataServer)
    copyDataServer[_.findIndex(dataServer, cli => cli.id === suppliers.id)] = {
      ...suppliers, region: {
        ...suppliers.region,
        country: suppliers.country
      }, comments
    }
    setDataServer(copyDataServer);
  }

  const handleSubmitComment = async (comment, values) => {
    let { comments, suppliers: supplier } = values
    supplier = { ...supplier, rates: tranformRatesFinish(_.get(supplier, "rates", [])) };
    await serviceSuppliers("createdSuppliers", { supplier, event: "comment", comment })

    comments.push(comment);
    updataData(supplier, comments);

  }
  const handleDeleteComment = async (comment, values) => {
    const { suppliers, comments } = values

    await serviceSuppliers("deleteCommentSupplier", { comment })
    updataData(suppliers, comments)
  }
  const handleSubmitForm = async (values) => {
    try {
      loading(true);
      closeModal();
      let { suppliers: supplier, comments } = values;
      supplier = { ...supplier, rates: tranformRatesFinish(_.get(supplier, "rates", [])) };

      const { response } = await serviceSuppliers("updateSuppliers", { supplier, event: "supplier", comments });

      updataData(supplier, comments);

      sendNotification(response, "success", 2000);

      loading(false);
    } catch (error) {
      console.log(error)
      loading(false);
      sendNotification(error.message, "danger", 2000)

    }

  }


  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmitForm}
      innerRef={formRef}
      validateOnChange={false}
      validateOnBlur={false}
      enableReinitialize={true}
    >

      {({ handleSubmit, handleChange, handleBlur, values, setFieldValue, errors }) => {

        return (
          <>

            <Tabs
              className={classes.Tabs}
              tabs={keys}
              goTo={(value) => {
                setKey(value);
              }}
              selected={key}
              classLetter={classes.letterTabs}
              classesTabs={classes.Tabs}
            />
            <div className={`${classes.containerForm} scrollbar `} >

              {key === "suppliers" &&

                <Supplier data={values} setFieldValue={setFieldValue} />

              }
              {key === "admin" &&
                <Admin data={values} setFieldValue={setFieldValue} />
              }

              {key === "legal" &&
                <Legal data={values} setFieldValue={setFieldValue} />
              }
              {key === "rates" &&
                <Rates data={values} loading={loading} setFieldValue={setFieldValue} />
              }
              {key === "info" &&
                <Info data={values} setFieldValue={setFieldValue} />
              }
              <Comments values={values} name={"comments"} setFieldValue={setFieldValue}
                handleSubmitComment={handleSubmitComment} handleDeleteComment={handleDeleteComment} />
            </div>
          </>

        )

      }}
    </Formik>
  );
}

export default ControlledTabSup;