import React, { useImperativeHandle, useContext } from 'react';
import { Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';
import classes from "./FormUser.module.scss";
import { ButtonForm, TextInputForm, Avatar, SwitchForm, SelectForm, ColorPikerForm } from "../../index";
import { Select, TextInput } from '../../NewComponents/index';
import { Row } from '../../Grid/Grid'
import _ from 'lodash';
import { userSchema } from '../../../utils/validate/forms';
import { sendNotification } from '../../../utils/helper/notification';
import { useGetRolesUsers } from '../../../utils/CustomHook';
import { context } from '../../../utils/CustomHook';



import { serviceUsers } from '../../../utils/API/apiService';


const userValidation = Yup.object().shape(userSchema);

const FormUser = ({ data, typeSubmit, closeModal, formRef }) => {

  const { dataServer, setDataServer, loading } = useContext(context);
  const initialValues = _.isEmpty(data) ? { color: "#7d7d7d", state: true } : { ...data, state: !!data.state };
  const { roles, loading: loadingData } = useGetRolesUsers()
  const listRoles = roles.map(rol => ({ value: rol, label: rol.role }));

  const handleSubmitForm = async values => {
    loading(true);
    closeModal();
    try {

      if (typeSubmit === "CREATED") {

        let { inserted_id, response } = await serviceUsers("createdUser", values);

        values = { ...values, id: inserted_id }
        sendNotification(response, "success", 2000)
      }
      else {

        let { response } = await serviceUsers("updatedUser", values);
        sendNotification(response, "success", 2000)

      }
      updateTable(values);
      loading(false);

    } catch (error) {
      loading(false);
      sendNotification(error.message, "danger", 2000)
    }



  }

  const updateTable = (values) => {
    const index = _.findIndex(dataServer, item => item.id === values.id);
    if (index < 0) {
      setDataServer([...dataServer, values])
    }
    else {
      const newDataServer = dataServer;
      newDataServer[index] = values;
      setDataServer(newDataServer)

    }

  }

  const getValue = (value) => {
    return { value, label: value.role }
  }

  return (

    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmitForm}
      validationSchema={userValidation}
      validateOnChange={false}
      validateOnBlur={false}
      enableReinitialize={true}
      innerRef={formRef}
    >

      {({ handleSubmit, handleChange, handleBlur, setFieldValue, values, errors }) => {
        return (


          <div className={classes.containerForm}>
            <div className={classes.containerAvatar}>

              <ColorPikerForm name={"color"} >
                <Avatar
                  classes={{ root: classes.avatar }}
                  firstName={_.get(values, "name", null)}
                  lastName={_.get(values, "lastname", null)}
                  color={values.color} />

              </ColorPikerForm>

            </div>
            <Row >
              <TextInput
                placeholder={"First Name..."}
                name={"name"}
                type={"text"}
              />

              <TextInput
                placeholder={"Last Name..."}
                name={"lastname"}
                type={"text"}
              />
            </Row>
            <Row>
              <TextInput
                placeholder={"User..."}
                name={"user"}
                type={"text"}
              />

              <TextInput
                placeholder={"Password..."}
                name={"password"}
                type={"password"}
              />
            </Row>
            <Row>
              <TextInput

                placeholder={"Email..."}
                name={"mail"}
                type={"email"}
              />

              <Select
                name={"userRole"}
                placeholder={"Select Role..."}
                list={listRoles}
                onChange={value => setFieldValue('userRole', value)}
                value={_.get(values, 'userRole')}
                loading={loadingData}
                clear
              />

            </Row>
            <Row >

              <SwitchForm name={"state"} label={"Active"} borderBottom />

              <TextInput
                placeholder={"Company Name"}
                name={"company_name"}
                type={"text"}
              />

            </Row>


          </div>
        )
      }}

    </Formik>
  );
}
export default FormUser;