import React from 'react'
import { useField } from 'formik'
import classes from './TextInputArea.module.scss';


const TextInputArea = ({ name,cols,rows, placeholder='',className,borderBottom=false  }) => {

    const [field, meta, helpers] = useField(name);
    return (
        <div className={`${classes.containerTextArea} ${className} ${ borderBottom && classes.borderBottom }`}>
            <textarea
                cols={cols}
                rows={rows}
                {...field}
                className={`scrollbar`}
                placeholder={placeholder}
            />

        </div>
    )
}
export default TextInputArea