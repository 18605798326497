import { useFormikContext } from "formik";
import _ from "lodash";
import React, { useMemo, useState } from "react";
import { AddFormButton, ModalForm } from "../../../../index";
import Table from "../../../../Table/Table";
import FormFlights from "./FormFlights";
import classes from "./TableFlights.module.scss";

const TableFlights = () => {
    const { values, setFieldValue } = useFormikContext();
    const [rowSelect, setRowSelect] = useState(null);
    const [showForm, setShowForm] = useState(false);

    const updateFlights = (newFlights) => {
        setFieldValue("flights", newFlights);
        _.set(values, "flights", newFlights);
        setRowSelect(null);
        setShowForm(false);
    };

    const openModalWithValue = (index) => {
        setRowSelect(index);
        setShowForm(true);
    };
    const openModalClean = () => {
        setRowSelect(null);
        setShowForm(true);
    };

    const handleDelete = (index) => updateFlights(values.flights.filter((_, i) => i !== index));
    const handleAddOrUpdate = (value) => {
        if (rowSelect === null) {
            return updateFlights([...values.flights, value]);
        }
        updateFlights(values.flights.concat([]).map((f, i) => (i === rowSelect ? value : f)));
    };

    const columns = useMemo(
        () => [
            {
                Header: " ",
                columns: [
                    {
                        Header: "Nº",
                        accessor: "index",
                        Cell: ({ row }) => <span>{row.index}</span>,
                        className: `${classes.col1}`,
                    },
                    {
                        Header: 'Flight',
                        accessor: "flight_number",
                        Cell: ({ row }) => <span>{row.original.flight_number}</span>,
                        className: classes.col1,
                    },
                    {
                        Header: 'Reservation',
                        accessor: "reservation_number",
                        Cell: ({ row }) => <span>{_.get(row.original, "reservation_number", "-")}</span>,
                        className: classes.col2,
                    },
                    {
                        Header: 'Date',
                        accessor: "date",
                        Cell: ({ row }) => <span>{row.original.date}</span>,
                        className: classes.col3,
                    },
                    {
                        Header: 'Origin',
                        accessor: "airport_origin",
                        Cell: ({ row }) => <span>{`${_.get(row.original, "airport_origin.code", "-")} `}</span>,
                        className: classes.col4,
                    },
                    {
                        Header: 'Destination',
                        accessor: "airport_destination",
                        Cell: ({ row }) => <span>{`${_.get(row.original, "airport_destination.code", "-")} `}</span>,
                        className: classes.col5,
                    },
                    {
                        Header: 'Departure',
                        accessor: "departure_time",
                        Cell: ({ row }) => <span>{`${_.get(row.original, "departure_time", "-").substr(0, 5)} `}</span>,
                        className: classes.col6,
                    },
                    {
                        Header: 'Arrival',
                        accessor: "arrival_time",
                        Cell: ({ row }) => <span>{`${_.get(row.original, "arrival_time", "-").substr(0, 5)} `}</span>,
                        className: classes.col7,
                    },
                    {
                        Header: 'Days',
                        accessor: "days_difference",
                        Cell: ({ row }) => <span>{`${_.get(row.original, "days_difference", "-")} `}</span>,
                        className: classes.col8,
                    },
                    {
                        accessor: "edit",
                        Cell: ({ row }) => (
                            <i
                                className={`icon-edit ${classes.iconEdit}`}
                                onClick={() => openModalWithValue(row.index)}
                            />
                        ),
                        className: classes.colEdit,
                    },
                    {
                        accessor: "delete",
                        Cell: ({ row }) => (
                            <i className={` ${classes.deleteIcon}`} onClick={() => handleDelete(row.index)} />
                        ),
                        className: classes.colDelete,
                    },
                ],
            },
        ],
        []
    );

    return (
        <div className={classes.containerTablePaxs}>
            <div className={classes.containerTitleTable}>
                <h2>Flights</h2>
                <AddFormButton label={"Add Flights"} handleOnClick={openModalClean} />
            </div>

            <ModalForm showModal={showForm} closeModal={() => setShowForm(false)}>
                <FormFlights values={values.flights[rowSelect] || null} onSubmit={handleAddOrUpdate} />
            </ModalForm>
            <Table data={values.flights} columns={columns} />
        </div>
    );
};

export default TableFlights;
