
import classes from './Clients.module.scss';
import React, { useState, useEffect, useContext } from 'react';
import Tabs from '../../Tabs/Tabs'
import ViewClient from '../ViewClient'
import { Legal } from '../../index'
import { FormClient } from '../../index'
import { Formik } from 'formik';
import Comments from '../../Comments/Comments';
import { serviceClients } from '../../../utils/API/apiService';
import { sendNotification } from '../../../utils/helper/notification';
import { context } from '../../../utils/CustomHook';
import { clientSchemaEdit } from '../../../utils/validate/forms';
import * as Yup from 'yup'

import 'bootstrap/dist/css/bootstrap.min.css';

import _ from 'lodash'



const ControlledTabs = ({ data, formRef, closeModal }) => {
  const [key, setKey] = useState('clientInfo');

  const keys = [
    { name: "CLIENT INFO", key: "clientInfo" },
    { name: "ADMIN", key: "admin" },
    { name: "LEGAL", key: "legal" }
  ]

  const tranforInitialData = (values) => {
    return { client: { ..._.omit(values, "comments"), country: _.get(values, "region.country") }, ..._.pick(values, "comments") }
  }

  const initialValues = _.isEmpty(data) ? { contact: { color: "#7d7d7d" }, comments: [] } : tranforInitialData(data);

  const handleSubmitComment = async (comment, values) => {
    let { comments, client } = values
    await serviceClients("createdClient", { client, event: "comment", comment })

    comments.push(comment)

    const copyDataServer = _.cloneDeep(dataServer)
    copyDataServer[_.findIndex(dataServer, cli => cli.id === client.id)] = {
      ...client, region: {
        ...client.region,
        country: client.country
      }, comments
    }
    setDataServer(copyDataServer);
  }
  const handleDeleteComment = async (comment, values) => {
    const { client, comments } = values

    await serviceClients("deleteCommentClient", { comment })
    const copyDataServer = _.cloneDeep(dataServer)
    copyDataServer[_.findIndex(dataServer, cli => cli.id === client.id)] = {
      ...client, region: {
        ...client.region,
        country: client.country
      }, comments
    }
  }
  const { dataServer, loading, setDataServer } = useContext(context);

  const handleSubmitForm = async (values) => {
    try {
      loading(true);
      closeModal();
      const { response } = await serviceClients("updateClient", { ...values, event: "client" });
      let { client, comments } = values
      const copyDataServer = _.cloneDeep(dataServer)
      copyDataServer[_.findIndex(dataServer, cli => cli.id === client.id)] = {
        ...client, region: {
          ...client.region,
          country: client.country
        }, comments
      }
      setDataServer(copyDataServer);




      sendNotification(response, "success", 2000);

      loading(false);
    } catch (error) {
      loading(false);
      sendNotification(error.message, "danger", 2000)


    }

  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmitForm}
      validationSchema={Yup.object().shape(clientSchemaEdit)}
      innerRef={formRef}
      validateOnChange={false}
      validateOnBlur={false}
      enableReinitialize={true}
    >

      {({ handleSubmit, handleChange, handleBlur, values, setFieldValue, errors }) => {

        return (

          <>
            <Tabs
              className={classes.Tabs}
              tabs={keys}
              goTo={(value) => {
                setKey(value);
              }}
              selected={key}
              classLetter={classes.letterTabs}
              classesTabs={classes.Tabs}
            />

            <div className={`${classes.containerForm} scrollbar `} >



              {key === "clientInfo" &&

                <ViewClient key={"clientInfo"} data={values} setFieldValue={setFieldValue} />

              }
              {key === "admin" &&
                <FormClient key={"admin"} data={values} setFieldValue={setFieldValue} />
              }

              {key === "legal" &&
                <Legal key={"legal"} data={values} />
              }
              <Comments values={values} name={"comments"} setFieldValue={setFieldValue}
                handleSubmitComment={handleSubmitComment} handleDeleteComment={handleDeleteComment} />



            </div>
            </>

        )
          

      }}
    </Formik>
  );
}

export default ControlledTabs;