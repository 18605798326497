import React, { useEffect, useState } from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';
import Content from '../../components/Content/Content';

 const ManagerRoute = ({ children, ...rest }) => {
    const location = useLocation();
    const hasAccess = (url) => {

        let session = localStorage.getItem("userData");
        let needLogger = false;

        if (location.pathname.indexOf("/auth/") >= 0) {
            needLogger = true;

        }

        if (needLogger && !session) {

            return false;
        }
        
        return needLogger;
    }





    return (
        <Route
            exact
            path={rest.path}
            render={props =>
                hasAccess() ?

                    <Content {...props} layout={rest.layout}>
                        {children}
                    </Content>

                    :
                    <Redirect to={"/login"} />
            }

        />

    )
}

export default ManagerRoute;