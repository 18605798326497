import React from 'react';
import classes from './Components.module.scss';
import { Modal as ModalBoostrap } from 'react-bootstrap';

export const Header = ({ title, onClose }) => {
    return (
        <div className={classes.Header}  >
            <h1>{title}</h1>
            <button className={classes.Close} onClick={onClose} />
        </div>

    )
}



export const Footer = ({ children }) => {
    return (
        <div className={classes.Footer} >
            {children}
        </div>

    )
}


export const Modal = ({ showModal, sizes, className, children, size, ...props }) => {

    return (
        <ModalBoostrap show={showModal} className={classes.containerPortal} dialogClassName={`${classes.ModalTabs} ${classes[sizes]}`}  {...props} >
            {children}
        </ModalBoostrap>

    )
}


export const Errors = ({errors = {}}) => <div className={classes.Errors}>{Object.keys(errors).map(k => <span>{k}: {errors[k]}</span>)}</div>
