import React, { useState, useRef } from 'react';
import { createPortal } from 'react-dom'
import classes from './ModalForm.module.scss';
import { ButtonForm, SelectForm } from '../index';
import { useGetRolesUsers } from '../../utils/CustomHook'

import { useFormik, Formik } from 'formik';
import _ from 'lodash'
import { Header, Footer, Modal } from './Components/Components'

const ModalFilterGeneric = ({ title, children, setFilter, closeModal, showModal, filter, className }) => {

    const submitForm = () => {

        formRef.current.handleSubmit();

    }

    const formRef = React.createRef();

    const newChildren = React.cloneElement(children, { closeModal, formRef });

    return (
        <Modal show={showModal} sizes={'medium'}  >
            <div className={classes.modalBox} >
                <Header title={title} onClose={closeModal} />


                <div className={`${classes.formContainer} ${_.get(className, "modal")}`} >
                    {newChildren}
                </div>


                <Footer >
                    <ButtonForm label={"FILTER"} handleClik={submitForm} />
                </Footer>
            </div>
        </Modal >
    )
}




export default ModalFilterGeneric;