import classes from './Suppliers.module.scss';
import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  Loading, SearchInput, Table, Avatar, ModalTabsSuppliers, ModalAddSuppliers, FormAddSuppliers,
  ControlledTabSup, AddFormButton, ButtonFilter, ModalFilterGeneric
} from "../../components";
import { serviceSuppliers } from '../../utils/API/apiService';
import { context, useGetListsForSuppliers } from '../../utils/CustomHook'
import _ from 'lodash';
import { SuppliersFilter } from '../../components/Forms/Filter/index'

const Suppliers = (props) => {

  const [dataServer, setDataServer] = useState([]);
  const [search, setSearch] = useState("");
  const [showLoading, setShowLoading] = useState(true);
  const [showModal, setShowModal] = useState(false)
  const [rowSelected, setRowSelected] = useState(null);
  const [modalShow, setModalShow] = useState(false);

  const [filterValues, setFilterValues] = useState({});

  const [openFilter, setOpenFilter] = useState(false)


  const lists = useGetListsForSuppliers();

  useEffect(() => {

    if (_.isEmpty(dataServer)) {
      serviceSuppliers("getSuppliers")
        .then(res => {
          setDataServer(res);
          setShowLoading(false)
        })
        .catch(error => { console.log(error) }
        )
    }

  }, [])

  const handleOpenModal = () => {
    setModalShow(true);
  }
  const handleCloseModal = () => {
    setModalShow(false);
  }

  const handleEdit = (data) => {
    setRowSelected(data);
    setShowModal(true)
  }

  const handleFilterData = (data) => {
    if (!_.isEmpty(filterValues)) {

      return _.filter(data, sup => {

        let isState = true;
        let isDestination = true;

        if (!_.isNil(filterValues.state)) {
          isState = sup.state == filterValues.state
        }

        if (!_.isNil(filterValues.destination)) {
          isDestination = sup.destination.id === filterValues.destination.id
        }

        return isDestination && isState;
      })

    }

    return data;
  }

  const headers = [
    {
      key: "",
      colSpan: 1,
      label: " "
    },
    {
      key: "name",
      colSpan: 1,
      label: "NAME"
    },
    {
      key: "address",
      colSpan: 1,
      label: "ADDRESS"

    },
    {
      key: "phone",
      colSpan: 1,
      label: "PHONE"
    },
    {
      key: "website",
      colSpan: 1,
      label: "WEB SITE"
    },
    {
      key: "functions",
      colSpan: 2,
      label: ""

    }
  ]

  const fields = [
    {
      key: "",
      decorator: (props) => (
        <span> <Avatar initials={_.get(props, "row.destination.claim", "")}
          color={_.get(props, "row.destination.color", "")} classes={{ root: classes.avatarThreeLetter }} /></span>
      ),
      classes: classes.col1
    },

    {
      key: "name",
      decorator: (props) => (
        <span>{props.row.name}</span>
      ),
      classes: classes.col2
    },

    {
      key: "address",
      decorator: (props) => (
        <span>{props.row.address}</span>
      ),
      classes: classes.col3
    },

    {
      key: "phone",
      decorator: (props) => (
        <span>{props.row.phone}</span>
      ),
      classes: classes.col4
    },

    {
      key: "website",
      decorator: (props) => (
        <span>{props.row.website}</span>
      ),
      classes: classes.col5
    },
    {
      key: "state",
      decorator: (props) => (
        <span className={`${classes.status} ${classes[!!props.row.state ? "status-active" : ""]} `} />
      ),
      classes: classes.col7
    },

    {
      key: "",
      decorator: (props) => (
        <span> <Avatar firstName={props.row.user_create.name} lastName={props.row.user_create.lastname}
          color={props.row.user_create.color} classes={{ root: classes.avatar }} /></span>
      ),
      classes: classes.col8
    },

    {
      key: "state",
      decorator: (props) => (
        <i className={`icon-edit ${classes.iconEdit}`} onClick={() => handleEdit(props.row)} />
      ),
      classes: classes.col9
    }
  ]

  return (

    <context.Provider
      value={{
        dataServer,
        setDataServer,
        loading: setShowLoading,
        handleFilterData,
        ...lists
      }}
    >

      <h2>SUPPLIERS</h2>
      <Loading showLoading={showLoading} />
      <div className={classes.containerActionsElements}>
        <SearchInput classes={{ root: classes.searchInput }} handleSearch={(value) => setSearch(value)} placeholder={"Search...."} from_booking={false} />

        <div className={classes.containerButtons}>
          <ButtonFilter
            label={"FILTER"}
            onClick={() => setOpenFilter(true)}
          />
          <AddFormButton
            classes={{ root: classes.addButtomPosition }}
            label={"ADD SUPPLIERS"}
            handleOnClick={handleOpenModal}
          />
        </div>
      </div>

      <ModalAddSuppliers showModal={modalShow} closeModal={handleCloseModal} title={"SUPPLIERS FORM"} showDelete className={{ modal: classes.modal }} >
        <FormAddSuppliers data={rowSelected} />
      </ModalAddSuppliers>

      <ModalTabsSuppliers title={"SUPPLIERS"} showModal={showModal} closeModal={() => setShowModal(false)} >
        <ControlledTabSup data={rowSelected} />
      </ModalTabsSuppliers>

      <ModalFilterGeneric showModal={openFilter} closeModal={() => setOpenFilter(false)} title={"FILTER SUPPLIERS"}>
        <SuppliersFilter handleFilterValues={setFilterValues} initialValues={filterValues} />
      </ModalFilterGeneric>

      <div className={classes.containerTable} >
        <Table headers={headers} fields={fields} search={search} sort={["name"]} />
      </div>
    </context.Provider>

  )
}

export default Suppliers;


