import { useFormikContext } from "formik";
import React from "react";
import { TextInput } from "../../../../../NewComponents";
import { Select } from "../../../../../Select/Select";
import DateInput from "../../../../../TextInputs/DateInput/DateInput";
import classes from "../AccountRowDetail.module.scss";
import moment from "moment";

export const AccountAccomodationForm = () => {
    const { values, setFieldValue } = useFormikContext();

    return (
        <div>
            <div className={classes.section}>
                Product Name
                <TextInput value={values?.rate_name} onChange={(e) => setFieldValue("rate_name", e.target.value)} />
            </div>
            <div className={classes.section}>
                Date
                <label className={classes.dateFormAddAccount}>
                    <DateInput
                        placeholder="Select Date"
                        onChagedDate={(date) => setFieldValue("rate_data.date", date)}
                        value={moment(values?.rate_data?.date).toDate()}
                    />
                </label>
                Nights
                <TextInput
                    min={0}
                    value={values?.rate_data?.nights}
                    type="number"
                    onChange={(e) => setFieldValue("rate_data.nights", e.target.value)}
                />
                Bed Types
                <div className={classes.select}>
                    <Select
                        options={values.bedTypes}
                        defaultValue={values.rate_data.bed_type}
                        onChange={(r) => {
                            setFieldValue("rate_data.bed_type", r);
                        }}
                    />
                </div>
            </div>

            <div className={classes.section}>
                Assosiated Pax
                <div className={classes.select}>
                    <Select
                        isMulti
                        options={values.paxs}
                        defaultValue={values.rate_data.paxs}
                        onChange={(r) => {
                            setFieldValue("rate_data.paxs", r);
                        }}
                    />
                </div>
            </div>
        
            <div className={`${classes.section} ${classes.borderNone}`}>
                Description
                <TextInput value={values?.rate_data?.description} onChange={(e) => setFieldValue("rate_data.description", e.target.value)} />
            </div>
        </div>
    );
};
