import React from 'react'
import classesAvatar from './Avatar.module.scss';
import {get} from 'lodash'

const  Avatar = ({classes, firstName, lastName, color ,initials="" , onClick}) => {

    const rootClass = get(classes,"root",null);
    const label= firstName && lastName ? firstName[0]+ lastName[0] : "";

    return (
        <div className= {`${classesAvatar.Avatar} ${rootClass}`} style={{background:color}} onClick={onClick}>
            <span >{ initials.toUpperCase() || label.toUpperCase()}</span>
        </div>
    )
};

export default Avatar;
