import React, { useState, useEffect, useRef } from 'react';
//import './App.css';
import classes from './Login.module.scss'
import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button'
import { loginRecover } from '../../utils/API/apiService';
import { forgotValidations } from '../../utils/validate/auth';
import * as Yup from 'yup'

//import { ReactComponent as Logo } from '../../public/img/onesixth-logo.svg';
import { Snackbars } from '../../components/index';
import { sendNotification } from '../../utils/helper/notification';



const forgotValidationSchema = Yup.object().shape(forgotValidations);
const RecoverPassword = (props) => {

    const [mail, setMail] = useState('');
    const [loading, setLoading] = useState(false);
    const [resetPasswordSuccessful, setResetPasswordSuccessful] = useState(false);
    const inputEmail = useRef();
    const [validation, setValidation] = useState({})

    const handleCancel = () => {
        props.history.push("/login");
    };


    useEffect(() => {
        setTimeout(() => {
            if (inputEmail.current.value === mail) {
                forgotValidationSchema.validate({ mail }, { abortEarly: false })
                    .then(resul => {
                        setValidation({
                            isValid: true,
                            error: {}
                        });

                    })
                    .catch(err => {
                        setValidation({
                            isValid: false,
                            error: {
                                message: err.inner[0].message,
                                label: err.inner[0].path
                            }
                        });
                    })

            }
        }, 600)

    }, [mail]);

    const handleSendEmail = () => {

        setLoading(true)
        loginRecover({ mail })
            .then(res => {
                setResetPasswordSuccessful(true)
            })
            .catch(error => {

                setLoading(false);
                sendNotification(error.message, "danger", 2000)
                props.history.push("/recoverPassword");
            })

    };
    const style = resetPasswordSuccessful ? { height: "16rem", marginTop: "15vh " } : {};


    return (
        <div className={classes.divPadre} >

            <i className={classes.logo} />

            <div className={classes.conten}>
                <div className={classes.divHijo} style={style} id="divHijo" >
                    {!resetPasswordSuccessful ?
                        <Form>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label className={classes.title}>Forgot your password?</Form.Label>
                                <br />
                                <Form.Label className={classes.subTitle} >We can help you reset it and get back</Form.Label>
                                <br></br>
                                <Form.Control ref={inputEmail} onChange={(e) => setMail(e.target.value)} value={mail} type="mail" placeholder="Enter your email address ...." />

                            </Form.Group>
                            <Button id="boton" disabled={!validation.isValid} className={classes.sendButton} variant="light" onClick={handleSendEmail}>
                                Reset password
                     </Button>
                            <Button onClick={handleCancel} id="boton" variant="light" className={classes.buttonCancel}>
                                Cancel
                     </Button>
                        </Form> :
                        <>
                            <h1 style={{ marginTop: "20px", }} >Thank you!</h1>
                            <p className={classes.subTitle}>We've sent you an email with instructions to reset your password.
                          You may now close this browser tab.</p>
                        </>

                    }
                </div>
            </div>
            <Snackbars />
        </div>

    );
}




export default RecoverPassword;