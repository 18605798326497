
import classes from './Destinations.module.scss';

import React, { useState, useEffect } from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';

import { Loading, SearchInput, Table, Avatar, ModalTabs, FormAddDestination, ModalAddDestination, Tabs, ViewClient, ControlledDestinations, AddFormButton, FormAddClient } from "../../components";

import { serviceDestinations } from '../../utils/API/apiService';
import { context } from '../../utils/CustomHook'
import _ from 'lodash';
import { Form } from 'react-bootstrap';
import { useGetListsForDestinations } from '../../utils/CustomHook'

const Destinations = (props) => {

  const [dataServer, setDataServer] = useState([]);
  const [search, setSearch] = useState("");
  const [showLoading, setShowLoading] = useState(true);
  const [showModal, setShowModal] = useState(false)
  const [rowSelected, setRowSelected] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const list = useGetListsForDestinations();

  const agregateInDataOptionShowRowExtra = (data) => {
    return _.map(data, destination => ({
      ...destination,
      showRowExtra: _.has(destination, "showRowExtra") ? destination.showRowExtra : false,
      preShowRowExtra: _.has(destination, "preShowRowExtra") ? destination.preShowRowExtra : true
    }));
  }


  useEffect(() => {

    if (_.isEmpty(dataServer)) {
      serviceDestinations("getDestinations")
        .then(res => {
          setDataServer(agregateInDataOptionShowRowExtra(res));
          setShowLoading(false)
        })
        .catch(error => { console.log(error) }
        )
    }
  }, [dataServer])

  const handleEdit = (data) => {
    setRowSelected(data);
    setShowModal(true)
  }
  const headers = [
    {
      key: "name",
      colSpan: 2,
      label: "NAME"
    },
    {
      key: "region",
      colSpan: 1,
      label: "REGION"

    },
    {
      key: "country",
      colSpan: 1,
      label: "COUNTRY"

    },
    {
      key: "airportname",
      colSpan: 1,
      label: "AIRPOT NAME"

    },
    {
      key: "functions",
      colSpan: 3,
      label: ""

    }
  ]



  const handleOpenRow = (row) => {
    const index = _.findIndex(dataServer, destination => destination.id === row.id);
    const copyData = { ...dataServer };
    copyData[index].showRowExtra = !dataServer[index].showRowExtra;
    setDataServer(agregateInDataOptionShowRowExtra(copyData))
  }



  const IconArrow = (props) => {

    const [nowPosition, setNowPosition] = useState(props.row.preShowRowExtra);

    const handleOnClick = () => {
      setNowPosition(!nowPosition);
      handleOpenRow(props.row);
    }

    useEffect(() => {
      if (props.row.showRowExtra !== props.row.preShowRowExtra) {
        setTimeout(() => {
          setNowPosition(props.row.showRowExtra);
          props.row.preShowRowExtra = props.row.showRowExtra;
        }, 1);
      }

    }, [props.row]);

    return <i className={`icon-arrow ${classes.iconArrow} ${nowPosition ? classes.openRow : classes.closeRow} `} onClick={handleOnClick} />

  }


  const fields = [
    {
      key: "",
      decorator: (props) => (
        <span> <Avatar initials={_.get(props,"row.claim")}
          color={props.row.color} classes={{ root: classes.avatar }} /></span>
      ),
      classes: classes.col1
    },
    {
      key: "name",
      decorator: (props) => (
        <span>{props.row.name}</span>
      ),
      classes: classes.col2
    },
    {
      key: "region",
      decorator: (props) => (
        <span>{props.row.region.name}</span>
      ),
      classes: classes.col3
    },

    {
      key: "country",
      decorator: (props) => (
        <span>{props.row.region.country.name}</span>
      ),
      classes: classes.col4
    },

    {
      key: "airport",
      decorator: (props) => (
        <span>{`${props.row.airport.code}  ${props.row.airport.name}`}</span>
      ),
      classes: classes.col5
    },
    {
      key: "state",
      decorator: (props) => {
        return (<span className={`${classes.status} ${classes[!!props.row.state ? "status-active" : ""]} `} />)
      },
      classes: classes.col8
    },
    {
      key: "edit",
      decorator: IconArrow,
      classes: classes.col9
    },
    {
      key: "edit",
      decorator: (props) => (
        <i className={`icon-edit ${classes.iconEdit} `} onClick={() => handleEdit(props.row)} />
      ),
      classes: classes.col10
    }


  ]

  const handleOpenModal = () => {
    setModalShow(true);

  }

  const handleCloseModal = () => {
    setModalShow(false);
  }






  return (

    <context.Provider
      value={{
        dataServer,
        ...list,
        setDataServer,
        loading: setShowLoading,
        handleFilterData: (e) => e
      }}
    >

      <h2>DESTINATIONS</h2>
      <Loading showLoading={showLoading} />
      <div className={classes.containerActionsElements}>
        <SearchInput classes={{ root: classes.searchInput }} handleSearch={(value) => setSearch(value)} placeholder={"Search...."} from_booking={false} />
        <AddFormButton
          classes={{ root: classes.addButtomPosition }}
          label={"ADD DESTINATIONS"}
          handleOnClick={handleOpenModal}
        />
      </div>

      <ModalTabs title={"DESTINATIONS"} showModal={showModal} closeModal={() => setShowModal(false)} >
        <ControlledDestinations data={rowSelected} />
      </ModalTabs>

      <ModalAddDestination showModal={modalShow} closeModal={handleCloseModal} title={"DESTINATION FORM"} showDelete className={{ modal: classes.modal }} >
        <FormAddDestination />
      </ModalAddDestination>

      <div className={classes.containerTable} >
        <Table headers={headers} rowExtra={RowTableExtra} fields={fields} search={search} sort={["name"]} />
      </div>
    </context.Provider>

  )
}

export default Destinations;




const RowTableExtra = (row) => {

  const [nowPosition, setNowPosition] = useState(row.preShowRowExtra);
  const [display, setDisPlay] = useState(row.preShowRowExtra);

  useEffect(() => {

    if (row.showRowExtra !== row.preShowRowExtra) {
      setTimeout(() => {
        setNowPosition(row.showRowExtra);
      }, 10);

      setTimeout(() => {
        setDisPlay(row.showRowExtra);
      }, 1);
    }

  }, [row]);

  return (
    <tr key={JSON.stringify(row.id)} style={{ background: "#eee", display: display ? "" : "none" }} className={`${classes.rowExtra} ${nowPosition ? classes.open : classes.close}`}  >
      <td colSpan={9} style={{ background: "#eee" }} >{
        <div className={`${classes.containerTextRowExtra} `}>{row.description.length === 0 ? "No Description Available" : row.description}</div>
      }</td>
    </tr>

  )
}
