import React, { useRef, useEffect, useState } from 'react';
import classes from './TextInputCommetn.module.scss';
import _ from 'lodash';
import { useField } from 'formik'

const TextInputComment = ({ classNameTextArea, activeClient, name, addComment, className, ...inputProps }) => {

    const [field, meta, helpers] = useField(name);
    let cursorPointer = _.isEmpty(field.value) ? classes.cursorPointerDisable : "";
    const containerCommentRef = useRef(null);
    const [positionIcon, setPositionIcon] = useState({});

    useEffect(() => {
        if (containerCommentRef) {
            let { offsetWidth, offsetLeft } = containerCommentRef.current
            setPositionIcon({
                left: `${ offsetWidth + offsetLeft - 44.5}px`
            })
        }

    }, [containerCommentRef])

    return (
        <div ref={containerCommentRef} className={`${classes.containerInputText} ${_.get(className, "containerInputText")}`}>
            <i  onClick={addComment} className={`${cursorPointer}`} />
            <textarea
                className={classNameTextArea}
                {...inputProps}
                {...field}
            />
        </div>
    )
}

export default TextInputComment;
//active client, es usado especialmente para modificar algunos estilos, del componente cuando es usado por client