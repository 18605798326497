import React, { useContext, useRef } from 'react';
import classesView from './Suppliers.module.scss'
import _ from 'lodash'
import { ColorPikerForm, Avatar, SwitchForm } from '../../index';
import AddContact from '../../Buttons/AddContact/AddContact';
import { context } from '../../../utils/CustomHook';
import { Select } from '../../NewComponents';
import { TextInfo } from '../../Text/FactoryText';
import { Row } from '../../Grid/Grid'


const Viewsuppliers = ({ data, setFieldValue }) => {

    const { SupplierTypes, AcomodationsTypes } = useContext(context);

    const getElementList = (value, labelName = "name") => {
        return { value, label: value[labelName] }
    }

    const mapListSelect = (list, nameLabel) => {
        return _.map(list, element => ({ value: element, label: element[nameLabel] }))
    }

    const refSelect = useRef();

    const handleChangeAcomodationType = (value) => {
        setFieldValue('suppliers.supplierType', value)
        if (!value || value.id !== 1) {
            refSelect.current.handleClearValue();
        }
    }



    return (
        <div className={classesView.containerView}>
            <div className={classesView.containerHead}>
                <ColorPikerForm name={"data.suppliers.user_create.color"} >
                    <Avatar
                        classes={{ root: classesView.avatar }}
                        firstName={_.get(data, "suppliers.name", "")[0]}
                        lastName={""}
                        color={data.suppliers.user_create.color} />
                </ColorPikerForm>

                
                    <h2> {data.suppliers.name} </h2>
                
            </div>
            <Row >
                <TextInfo firstColum={true} value={data} path={'suppliers.name'} > </TextInfo>
                <Select
                    hiddenBorder={true}
                    placeholder={"Select Supplier Type"}
                    list={mapListSelect(SupplierTypes, "name")}
                    onChange={handleChangeAcomodationType}
                    value={_.get(data, 'suppliers.supplierType')}
                    clear={true}
              

                />


                <Select

                    placeholder={"Select Acomodation Types"}
                    list={mapListSelect(AcomodationsTypes, "name")}
                    onChange={value => setFieldValue('suppliers.accomodationType', value)}
                    value={_.get(data, 'suppliers.accomodationType')}
                    clear={true}
                    disabled={_.get(data, 'suppliers.supplierType.id') !== 1}
                    ref={refSelect}

                />

            </Row>
            <Row >
                <TextInfo firstColum={true} value={data} path={'suppliers.address'} > </TextInfo>
                <TextInfo value={data} path={'suppliers.region.name'} > </TextInfo>
                <TextInfo value={data} path={'suppliers.country.name'} > </TextInfo>

            </Row>
            <Row >
                <TextInfo firstColum={true} value={data} path={'suppliers.website'} > </TextInfo>
                <TextInfo value={data} path={'suppliers.phone'} > </TextInfo>
                <TextInfo />
            </Row>
            <Row >
                <AddContact
                    values={data}
                    name={"suppliers.contacts"}
                    label={"Contact:"}
                    firstColum
                />
            </Row>
            <Row>
                <SwitchForm classNameLabel={`firstCol`} name={'suppliers.state'} borderBottom={true} label={"Active  suppliers:"} />
                <TextInfo />
                <TextInfo />
            </Row>
        </div>
    )
}

export default Viewsuppliers;



