import React, { useState, useEffect, useRef, useContext } from 'react';
import { useFormik, Formik, Form } from 'formik';
import * as Yup from 'yup';
import classes from "./FormContact.module.scss";
import { Comments, ButtonForm, TextInputForm, Avatar, SwitchForm, SelectForm, ColorPikerForm } from "../../index";
import _ from 'lodash';
import { contactSchema } from '../../../utils/validate/forms';
import { sendNotification } from '../../../utils/helper/notification';
import { useGetCountries, context } from '../../../utils/CustomHook';
import { serviceContact } from '../../../utils/API/apiService';

import { Row } from '../../Grid/Grid';
import { TextInput, Select } from '../../NewComponents/index'

const FormContact = ({ data, typeSubmit, closeModal, formRef }) => {

  const { dataServer, setDataServer, loading, countries } = useContext(context);
  const [listCountries, setListCountries] = useState([]);
  const selectRegionRef = useRef();

  const tranforInitialData = (values) => {
    return { contact: { ..._.omit(values, "comments"), country: _.get(values, "region.country") }, ..._.pick(values, "comments") }
  }
  const initialValues = _.isEmpty(data) ? { contact: { color: "#7d7d7d" }, comments: [] } : tranforInitialData(data);

  useEffect(() => {
    const getContriesList = () => {
      if (!_.isEmpty(countries)) {
        setListCountries(_.map(countries, country => ({ label: country.name, value: _.omit(country, "region") })))
      };
    }

    getContriesList();

    if (_.has(initialValues, "country") && countries) {
      getRegionList(initialValues);
    }

  }, [countries])


  const mapListSelect = (list, nameLabel = 'name') => {
    return _.map(list, element => ({ value: element, label: element[nameLabel] }))
  }

  const getRegionList = ({ country }) => {
    let contrySelected = _.find(countries, c => c.code === _.get(country, "code"));
    return contrySelected ? _.map(contrySelected.regions, city => ({ label: city.name, value: city })) : [];
  }

  const updateTable = (values) => {

    const index = _.findIndex(dataServer, item => item.id === values.id);
    if (index < 0) {
      setDataServer([...dataServer, values])
    }
    else {
      const newDataServer = dataServer;
      newDataServer[index] = values;
      setDataServer(newDataServer)

    }
  }

  const handleSubmitForm = async values => {
    const { contact, comments } = values
    try {
      closeModal();
      loading(true);
      if (typeSubmit === "CREATED") {
        let { inserted_id, response } = await serviceContact("createdContact", { ...values, event: "contact" });
        const user = JSON.parse(localStorage.getItem("userData"));
        contact.state = 1
        values = {
          ...contact, region: { ...contact.region, country: contact.country },
          comments, id: inserted_id, user_create: user
        }
        sendNotification(response, "success", 2000)
      }
      else {

        let { response } = await serviceContact("updateContact", { event: "contact", ...values });
        sendNotification(response, "success", 2000)
        values = {
          ...contact, region: { ...contact.region, country: contact.country },
          comments
        };
      }
      updateTable(values);
      loading(false);



    } catch (error) {
      sendNotification(error.message, "danger", 2000)
      loading(true);
      closeModal();

    }
  }


  const getCountry = (value) => {
    return { value, label: value.name }
  }
  const getRegion = (value) => {
    return { value, label: value.name }
  }



  const handleSubmitComment = async (comment, values) => {

    let { comments, contact } = values
    await serviceContact("createdContact", { contact, event: "comment", comment })

    comments.push(comment)
    let data = {
      ...contact, region: { ...contact.region, country: contact.country },
      comments
    };
    updateTable(data);

  }

  const handleDeleteComment = async (comment, values) => {
    const { contact, comments } = values;
    await serviceContact("deleteCommentContact", { comment })
    data = {
      ...contact, region: { ...contact.region, country: contact.country },
      comments
    };
    updateTable(data);
  }

  return (

    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmitForm}
      validationSchema={Yup.object().shape(contactSchema)}
      innerRef={formRef}
      validateOnChange={false}
      validateOnBlur={false}
      enableReinitialize={true}
    >

      {({ handleSubmit, handleChange, handleBlur, values, setFieldValue, errors }) => {


        const handleSelectCountry = (value) => {
          setFieldValue('contact.country', value);
          selectRegionRef.current.handleClearValue();

        }
        return (

          <div className={classes.containerForm}>
            {< div className={classes.containerAvatar}>

              <ColorPikerForm name={"contact.color"} >
                <Avatar
                  classes={{ root: classes.avatar }}
                  firstName={_.get(values, "contact.name", null)}
                  lastName={_.get(values, "contact.lastname", null)}
                  color={values.contact.color} />

              </ColorPikerForm>

            </div >}

            <Row className={classes.row}  >
              <TextInput
                placeholder={"First Name..."}
                name={"contact.name"}
                type={"text"}
              />

              <TextInput
                placeholder={"Last Name..."}
                name={"contact.lastname"}
                type={"text"}
              />
            </Row>
            <Row className={classes.row} >
              <TextInput
                placeholder={"Phone Number.."}
                name={"contact.phone"}
                type={"text"}
              />

              <TextInput
                placeholder={"Email..."}
                name={"contact.mail"}
                type={"email"}
              />
            </Row >
            <Row className={classes.row} >
              <TextInput
                placeholder={"Charge.."}
                name={"contact.work_area"}
                type={"text"}
              />

              <TextInput
                placeholder={"Company..."}
                name={"contact.company_name"}
                type={"text"}
              />
            </Row>
            <Row className={classes.row} >

              <Select
                placeholder={"Select Country"}
                list={mapListSelect(countries)}
                onChange={handleSelectCountry}
                value={_.get(values, "contact.country")}
                clear={true}

              />

              <Select
                placeholder={"Select Province"}
                disabled={!_.get(values, "contact.country")}
                list={getRegionList(values.contact)}
                ref={selectRegionRef}
                onChange={value => setFieldValue("contact.region", value)}
                value={_.get(values, "contact.region")}
                clear
              />

            </Row >
            <Row className={classes.row} >
              <TextInput
                placeholder={"Email business..."}
                name={"contact.mail_business"}
                type={"text"}
              />

              <TextInput
                placeholder={"Web Site..."}
                name={"contact.website"}
                type={"text"}
              />
            </Row >
            <Row className={classes.row} >
              <SwitchForm borderBottom name={"contact.state"} label={"Active"} />
            </Row>

            {typeSubmit !== "CREATED" &&
              <Comments activeClient={{ valueClient: false }} values={values} name={"comments"} setFieldValue={setFieldValue}
                handleSubmitComment={handleSubmitComment} handleDeleteComment={handleDeleteComment} />
            }
            {/* <pre style={{ position: "absolute", top: "-50px", left: "-500px", color: "#fff" }}>{JSON.stringify(values, null, 2)}</pre>*/}
          </div>
        )
      }}
    </Formik>
  );
}
export default FormContact;