import React, { useState, useEffect, useRef } from 'react';
import classes from './Login.module.scss';
import { useParams } from "react-router-dom"
import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import { Button, Spinner } from 'react-bootstrap';
//import { ReactComponent as Logo } from '../../public/img/onesixth-logo.svg';
import { resetPassword } from "../../utils/API/apiService";

import * as Yup from 'yup';
import { resetPasswordValidate } from '../../utils/validate/auth'

const resetPasswordValidationSchema = Yup.object().shape(resetPasswordValidate);

const ResetPassword = (props) => {

    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [loading, setLoading] = useState(false)

    const inputPassword = useRef();
    const inputConfirmPassword = useRef();
    const [validation, setValidation] = useState({});

    let { token } = useParams();

    const handleValidate = () => {
        return inputPassword.current.value === password && inputConfirmPassword.current.value === confirmPassword && confirmPassword === password;
    }

    useEffect(() => {
        setTimeout(() => {
            if (handleValidate()) {

                resetPasswordValidationSchema.validate({ password, confirmPassword }, { abortEarly: false })
                    .then(resul => {
                        setValidation({
                            isValid: true,
                            error: {}
                        });

                    })
                    .catch(err => {
                        setValidation({
                            isValid: false,
                            error: {
                                message: err.inner[0].message,
                                label: err.inner[0].path
                            }
                        });
                    })

            } else (setValidation(false))
        }, 600)

    }, [password, confirmPassword]);


    const handleResetPassword = () => {

        setLoading(true);
        resetPassword(password, token)
            .then(res => {
                props.history.push("/login");

            })
            .catch(error => {
                alert(error);
                setLoading(false);
            })
    }

    return (
        <div className={classes.divPadre}>
            <i className={classes.logo} />

            <div className={classes.conten}>
                <div className={classes.divHijo}  >

                    <Form style={{ width: "26vw" }} >
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label style={{ marginBottom: "5vh" }}>Reset Password in  ONE SIX TH</Form.Label>
                            <br></br>

                            <Form.Control
                                ref={inputPassword}
                                style={{ marginBottom: "3vh" }}
                                onChange={(e) => setPassword(e.target.value)}
                                value={password}
                                type="text"
                                placeholder="Create your password" />

                        </Form.Group>

                        <Form.Group controlId="formBasicPassword">

                            <Form.Control

                                ref={inputConfirmPassword}
                                style={{ marginBottom: "5vh" }}
                                type="password"
                                placeholder="Repeat Password"
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                value={confirmPassword} />

                        </Form.Group>

                        <Button className={classes.sendButton} disabled={!validation.isValid} onClick={handleResetPassword} id="boton" variant="light" >



                            {!loading ? "Reset Password" :
                                <>
                                    <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    {"  Loading..."}
                                </>
                            }
                        </Button>
                    </Form>

                </div>
            </div>
        </div>
    )
}


export default ResetPassword