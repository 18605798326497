import React, { useState, useEffect, useCallback, useContext } from 'react';
import Table from 'react-bootstrap/Table'
import classes from './Table.module.scss';
//import { ReactComponent as NotFound } from '../../public/img/notFound.svg';
import _ from 'lodash';
import { context } from '../../utils/CustomHook'
const Tables = ({ className, fields, search, sort, data = [], rowExtra, headers, isBooking }) => {
    const { dataServer = [] } = useContext(context);
    const [dataTable, setDataTable] = useState([]);

    useEffect(() => {
        const handleSearch = (data, fields, filter) => {

            if (search && search !== "") {

                let dataFilter = _.filter(data, (element) => {
                    var find = _.find(fields, field => {
                        if (field.key && element[field.key]) {
                            return element[field.key].toString().toLowerCase().indexOf(filter.toLowerCase()) >= 0;
                        }
                        return false

                    })
                    return find;
                });
                return dataFilter;
            }
            return data;

        }
        if (_.isEmpty(data)) {
            setDataTable(handleSearch(dataServer, fields, search));
        } else {
            setDataTable(data)
        }
    }, [search, dataServer, fields])


    const classContainer = !isBooking ? classes.containerTable : classes.containerTableBooking

    return (
        <div className={`${classContainer}`}>
            {search && _.isEmpty(dataTable) ? <div className={classes.notFound} /> :
                <Table className={className}>
                    <TableHead fields={fields} headers={headers} />
                    <TableBody fields={fields} data={dataTable} sort={sort} rowExtra={rowExtra} />
                </Table>}
        </div>
    )
}
export default Tables;



const TableHead = ({ fields, headers = [] }) => {

    const rest = fields?.length - headers?.length;

    return (
        <thead>
            <tr>
                {headers.map((head, index) => {
                    const classesHead = head.classes ? head.classes : classes.defaulHeaders;
                    return (
                        <th colSpan={head.colSpan} key={head.key+index} className={classesHead} >
                            {head && head.decorator ? head.decorator() : <span >{head.label}</span>}
                        </th>
                    )
                })}
            </tr>
        </thead>
    )

}

const TableBody = ({ data, fields, sort, rowExtra }) => {


    const { handleFilterData } = useContext(context);

    const newData = handleFilterData(_.sortBy(data, sort))

    return (
        <tbody>
            <TableRows data={newData} rowExtra={rowExtra} fields={fields} key={JSON.stringify()} />
        </tbody>
    )
}


const TableRows = ({ fields, data, rowExtra }) => {
    return data.map((row,index) => {

        return (
            <React.Fragment key={JSON.stringify(row)+ index} >
                <tr>
                    {fields.map((field, j) => {
                        let value = "";
                        if (field.key) {
                            value = row[field.key];

                        }
                        if (field.decorator) {
                            value = React.createElement(field.decorator, { value, row });
                        }
                        return (
                            <td key={j} className={field.classes} >
                                {value}
                            </td>


                        )

                    })}

                </tr>
                
                {rowExtra &&  rowExtra(row)}
               
                    
            </React.Fragment>
        )
    })

}  