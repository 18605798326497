import { useFormikContext } from 'formik'
import React from 'react'
import ButtonForm from '../../Buttons/ButtonForm/ButtonForm'
import CommentsComponents from './Components/CommentsComponent/CommentsComponent'
import SettingsComponent from './Components/SettingsComponent/SettingsComponent'
import TableFlights from './Components/TableFlights/TableFlights'
import TablePaxs from './Components/TablePaxs/TablePaxs'
import classes from './Settings.module.scss'

const Settings = ({ values, ...props }) => {
    const { submitForm  } = useFormikContext();

    return (
        <div className={classes.containerSettings}>

            <TablePaxs />

            <TableFlights />

            <SettingsComponent/>

            <ButtonForm className={classes.buttonForm} label="CONFIRM" handleClik={submitForm} />

            <CommentsComponents values={values}/>
            
        </div>


    )
}
export default Settings;