import React, { useState, useEffect, useRef } from 'react';
import classesSearch from './SearchInput.module.scss';


const SearchInput = ({ value, classes, handleSearch, placeholder, from_booking }) => {

    const [valueText, setValueText] = useState(value);
    const inputRef = useRef();

    const handleOnChanged = (e) => {

        setValueText(e.target.value)
    }

    useEffect(() => {

        setTimeout(() => {
            if (inputRef.current.value === valueText) {
                handleSearch(valueText)
            }
        }, 300);

    }, [valueText, value])

    const rootClass = classes && classes.root ? classes.root : null;
    const classContainer = from_booking ? classesSearch.container_booking : classesSearch.container
    return (
        <div className={`${classContainer} ${rootClass}`} >

            <input
                ref={inputRef}
                value={valueText}
                onChange={handleOnChanged}
                placeholder={placeholder}
            />
        </div>
    )

}

export default SearchInput;