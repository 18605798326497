import React, { useRef, useContext } from 'react';
import { Formik } from 'formik';
import classes from "./FormAddSuppliers.module.scss";
import { SwitchForm } from "../../index";
import _ from 'lodash';
import { sendNotification } from '../../../utils/helper/notification';
import { context } from '../../../utils/CustomHook';
import { serviceSuppliers } from '../../../utils/API/apiService';
import { TextInfo } from '../../Text/FactoryText'

import { Row } from '../../Grid/Grid';
import { TextInput, Select } from '../../NewComponents/index'


const Formsuppliers = ({ data, typeSubmit, closeModal, formRef }) => {


  const { dataServer, setDataServer, Services, Countries, loading, SupplierTypes, Currencies, DestinationActive } = useContext(context);

  const selectRegionRef = useRef();


  const getRegionList = ({ country = {} }) => {
    let contrySelected = _.find(Countries, c => c.code === _.get(country, "code"));
    return contrySelected ? _.map(contrySelected.regions, city => ({ label: city.name, value: city })) : [];
  }


  const updateTable = (values) => {

    const index = _.findIndex(dataServer, item => item.id === values.id);
    if (index < 0) {
      setDataServer([...dataServer, values])
    }
    else {
      const newDataServer = dataServer;
      newDataServer[index] = values;
      setDataServer(newDataServer)
    }
  }

  const handleSubmitForm = async values => {

    const { suppliers, comments } = values

    try {
      closeModal();
      loading(true);
      let { inserted_id, response } = await serviceSuppliers("createdSuppliers", { supplier: suppliers, comments, event: "supplier" });
      const user = JSON.parse(localStorage.getItem("userData"));
      values = {
        ...suppliers,
        region: { ...suppliers.region, country: suppliers.country },
        comments, id: inserted_id, user_create: user
      }


      sendNotification(response, "success", 2000)

      updateTable(values);
      loading(false);

    } catch (error) {
      sendNotification(error.message, "danger", 2000)
      loading(false);
      closeModal();
    }
  }

  const getElementList = (value, labelName = "name") => {
    return { value, label: value[labelName] }
  }

  const mapListSelect = (list, nameLabel = 'name') => {
    return _.map(list, element => ({ value: element, label: element[nameLabel] }))
  }

  return (

    <Formik
      initialValues={{ suppliers: { color: "#7d7d7d", attachments: [], state: 1 }, comments: [] }}
      onSubmit={handleSubmitForm}
      //validationSchema={Yup.object().shape(suppliersSchema)}
      innerRef={formRef}
      validateOnChange={false}
      validateOnBlur={false}
      enableReinitialize={true}
    >

      {({ handleSubmit, handleChange, handleBlur, values, setFieldValue, errors }) => {

        const handleSelectCountry = (value) => {
          setFieldValue('suppliers.country', value);
          selectRegionRef.current.handleClearValue();

        }
        return (

          <div className={classes.containerForm}>
            <Row >
              <TextInput
                placeholder={"Name..."}
                name={"suppliers.name"}
                type={"text"}
              />
              <TextInput
                placeholder={"Adress..."}
                name={"suppliers.address"}
                type={"text"}
              />
            </Row >
            <Row >
              <TextInput
                placeholder={"Phone"}
                name={"suppliers.phone"}
                type={"text"}
              />
              <TextInput
                placeholder={"Web Site..."}
                name={"suppliers.website"}
                type={"text"}
              />
            </Row >
            <Row >

              <Select
                placeholder={"Select Country"}
                list={mapListSelect(Countries)}
                onChange={handleSelectCountry}
                value={_.get(values, "suppliers.country")}
                clear={true}

              />
              <Select
                placeholder={"Select City"}
                disabled={!_.get(values, "suppliers.country")}
                list={getRegionList(values.suppliers)}
                ref={selectRegionRef}
                onChange={value => setFieldValue("suppliers.region", value)}
                value={_.get(values, "suppliers.region")}
                clear
              />


            </Row>

            <Row>

              <Select
                placeholder={"Select Destination..."}
                list={mapListSelect(DestinationActive, "name")}
                value={_.get(values, 'suppliers.destination')}
                onChange={value => setFieldValue('suppliers.destination', value)}
                clear={true}
              />

              <Select
                name={"suppliers.currency"}
                placeholder={"Select Currency..."}
                list={mapListSelect(Currencies, "iso")}
                onChange={value => setFieldValue('suppliers.currency', value)}
                value={_.get(values, "suppliers.currency")}
                clear={true}
              />
            </Row>
            <Row>

              <Select
                name={"suppliers.supplierType"}
                placeholder={"Select Supplier type..."}
                list={mapListSelect(SupplierTypes, "name")}
                onChange={value => setFieldValue('suppliers.supplierType', value)}
                value={_.get(values, "suppliers.supplierType")}
                clear={true}
              />


            </Row>
            <Row>
              <SwitchForm borderBottom name={"suppliers.state"} label={"Active"} />
              <TextInfo />

            </Row>
            {/* <pre style={{ position: "absolute", top: "-50px", left: "-500px", color: "#fff" }}>{JSON.stringify(values, null, 2)}</pre>*/}
          </div>
        )
      }}
    </Formik>
  );
}
export default Formsuppliers;