import React, { useContext } from 'react'
import Classes from './AddHightlights.module.scss'
import { TextInputForm } from '../../../index'
import { Formik } from 'formik'
import { serviceDestinations } from '../../../../utils/API/apiService'
import { sendNotification } from '../../../../utils/helper/notification';
import { useGetCountries, context } from '../../../../utils/CustomHook/index';
import { TextInput } from '../../../NewComponents/index'
import _ from 'lodash';

const AddHightlights = ({ data, closeModal, formRef, setFieldValue }) => {

  const handleSubmitForm = async values => {
    setFieldValue(`destinations.destinationHightlights[${data.destinations.destinationHightlights.length}]`, values);
    closeModal();
  }

  return (

    <Formik
      initialValues={{}}
      onSubmit={handleSubmitForm}
      innerRef={formRef}
    >
      {({ values, setFieldValue }) => {
        return (
          <div className={Classes.containerForm} >
            <div className={Classes.RowTwoColumn}>
              <TextInput
                placeholder={"Name..."}
                name={"name"}
                type={"text"}
              />
            </div>
          </div>
        )
      }}
    </Formik>
  )
}

export default AddHightlights