import React from 'react'
import Classes from './AddRestaurants.module.scss'
import { TextInputForm } from '../../../index'
import { Formik } from 'formik'
import { TextInput } from '../../../NewComponents/index'

const AddRestaurants = ({ formRef, setFieldValue, closeModal,data }) => {

    const handleSubmitForm = async (values) => {
        setFieldValue(`destinations.destinationRestaurants[${data.destinations.destinationRestaurants.length}]`,values);
        closeModal();
    }

    return (
        <Formik
            initialValues={{}}
            onSubmit={handleSubmitForm}
            innerRef={formRef}
            validateOnChange={false}
            validateOnBlur={false}
            enableReinitialize={true}
        >
            {({ values}) => {
                return (
                    <div className={Classes.containerForm} >
                        <div className={Classes.RowTwoColumn}>
                            <TextInput
                                placeholder={"Name..."}
                                name={"name"}
                                type={"text"}
                            />
                            <TextInput
                                placeholder={"Phone..."}
                                name={"phone"}
                                type={"text"}
                            />
                        </div>
                        <div className={Classes.RowTwoColumn}>
                            <TextInput
                                placeholder={"Address..."}
                                name={"address"}
                                type={"text"}
                            />
                            <TextInput
                                placeholder={"Type Food..."}
                                name={"food_type"}
                                type={"text"}
                            />
                        </div>
                    </div>
                )
            }}

        </Formik>
    )
}

export default AddRestaurants