import React, { useEffect, useRef, useState, forwardRef, useImperativeHandle } from 'react'
import classes from './Select.module.scss';
import _ from 'lodash';
import { InputGroup, FormControl } from 'react-bootstrap'

const InputSelect = forwardRef(({ mode, loading, compare = 'id', search = true, clear = false, ComponentMenu = null, menuProps = {}, ComponentNotFound = null,
    value, list, onChange, onBlour, disabled, type, placeholder, callbakcs, name, handleFilterList = null }, ref) => {
    //  const placeholderTranslate = useTextLang(placeholder);
    const [filter, setFilter] = useState("");
    const [open, setOpen] = useState(false);
    const [hasMouse, setHasMouse] = useState(false)
    const refModal = useRef()

    const getFilterList = (list) => {
        if (!list) {
            list = [];
        }
        if (!filter || filter === "") {
            return list;
        }
        return _.filter(list, (element) => {

            return !element.value || element.label.toLowerCase().indexOf(filter.toLowerCase()) >= 0;
        })
    };

    useImperativeHandle(ref, () => ({
        handleClearValue
    }),
        [],
    )





    useEffect(() => {
        if (mode === 'classic' || value && value[compare]) {

            const element = _.find(list, element => {

                return mode === 'classic' ? element.value === value : _.isEqual(element.value[compare], value[compare]);
            });

            if (element) {

                setFilter(element.label)
            }
        }
    }, [loading])

    const handleFocus = () => {
        setOpen(true)
    }
    const handleChange = (e) => {
        setFilter(e.target.value);

    }
    const handleBlur = () => {
        if (!hasMouse) {
            setOpen(false)
        }
    }

    const handleMouseOverModal = () => {
        if (!hasMouse) {
            setHasMouse(true);
        }
    }
    const handleMouseOutModal = () => {
        if (hasMouse) {
            setHasMouse(false);
        }
    }

    const handleSelected = (element) => {
        onChange(element.value);
        setOpen(false);
        setFilter(element.label)
        if (_.has(callbakcs, "updateValues")) {
            const updateValues = _.get(callbakcs, 'updateValues');
            updateValues()
        }
    }

    const handleClearValue = () => {
        setFilter("")
        onChange(null)
    }

    const NotFound = ComponentNotFound;

    const Menu = ComponentMenu;

    const renderList = (list) => {
        let listFilter = search ? getFilterList(list) : list;
        if (handleFilterList) {
            listFilter = handleFilterList(listFilter);
        }

        return (
            <React.Fragment >
                {listFilter.length > 0 ? (
                    <React.Fragment>
                        {Menu ?
                            <Menu list={listFilter} handleMenu={setOpen} handleSelectedItem={handleSelected} />
                            : <div className={classes.zoneList}>
                                {listFilter.map((element, i) => {
                                    return <div
                                        className={classes.classic}
                                        onClick={() => handleSelected(element)}
                                        key={i}>
                                        {element.label}
                                    </div>

                                })}
                            </div>
                        }
                        <hr className={classes.Separator} />
                    </React.Fragment>
                ) : <>{NotFound ? <NotFound /> : <span>Not Found</span>}</>}
            </React.Fragment>
        )

    }

    const showModal = open ? classes.openModal : "";
    const uniqueId = _.uniqueId();
    return (
        <InputGroup className={classes.ClaraSelectContainer}>
            <span>
                <FormControl
                    id={uniqueId}
                    className={`${classes.claraSelectInput} `}
                    type={type}
                    disabled={disabled}
                    value={filter}
                    onChange={handleChange}
                    placeholder={placeholder}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    autoComplete={`autocomplete-inpit-${name}`}
                />


                <label 
                    className={`${classes.claraSelectArrow} ${open && classes.openMenu} ${filter && search && classes.claraSelectSelected}  icon-arrow`} 
                    htmlFor={uniqueId}
                />
                {clear && filter && !disabled &&
                    < div onClick={() => { handleClearValue() }} className={`${classes.claraButtonClearValue}`}>
                    </div>
                }

                {open &&
                    <div className={`${classes.Modal} ${showModal}  scrollbar`}
                        onMouseOver={handleMouseOverModal}
                        onMouseOut={handleMouseOutModal}
                        ref={refModal}
                    >
                        {renderList(list)}
                    </div>
                }
            </span>
        </InputGroup >
    )
})


export default InputSelect