import React, { useEffect } from 'react';
import classesView from './View.module.scss'
import _ from 'lodash'
import Avatar from '../../components/Avatar/Avatar'
import ColorPikerForm from '../../components/ColorPiker/ColorPikerForm';
import AddContact from '../Buttons/AddContact/AddContact';
import { SwitchForm } from '../index';
import { TextInfo } from '../Text/FactoryText';
import { Row } from '../Grid/Grid'
const ViewClient = ({ data, setFieldValue }) => {
    return (
        <div className={classesView.containerView}>
            <div className={classesView.containerHead}>


                <ColorPikerForm name={"client.color"} >
                    <Avatar
                        classes={{ root: classesView.avatar }}
                        firstName={_.get(data, "client.name", "")[0]}
                        lastName={""}
                        color={data.client.color} />
                </ColorPikerForm>
                    <h2> {data.client.name} </h2>
               
            </div>



            <Row>
                <TextInfo firstColum value={data} path={`client.name`} />
                <TextInfo value={data} path={`client.document`} ></TextInfo>
                <TextInfo > </TextInfo>

            </Row>
            <Row >
                <TextInfo firstColum value={data} path={`client.region.name`} ></TextInfo>
                <TextInfo value={data} path={`client.country.name`}></TextInfo>
                <TextInfo> </TextInfo>

            </Row>
            <Row >
                <TextInfo firstColum value={data} path={`client.website`}></TextInfo>
                <TextInfo value={data} path={`client.phone`}> </TextInfo>
                <TextInfo > </TextInfo>
            </Row>
            <Row>
                <AddContact
                    values={data}
                    name={"client.contacts"}
                    label={"Contact:"}
                    firstColum
                />
            </Row>
            <Row>

                <SwitchForm classNameLabel={`firstCol`} name={'client.state'} label={"Active client"} borderBottom />
                <TextInfo />
                <TextInfo />

            </Row>
        </div>
    )
}

export default ViewClient



