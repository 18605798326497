import * as Yup from 'yup';

const regexPhone = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const decimalNumberPercente = /[0-9]+(\.[0-9][0-9]?)?/
const userSchema = {
    userRole: Yup.object().required("This field is required."),
    user: Yup.string().required("This field is required."),
    password: Yup.string().required("This field is required."),
    name: Yup.string().required("This field is required."),
    lastname: Yup.string().required("This field is required."),
    mail: Yup.string().required("This field is required.").email("Invalid email address."),
    state: Yup.boolean()
}

const contactSchema = {
    contact: Yup.object().shape({
        name: Yup.string().required("This field is required."),
        lastname: Yup.string().required("This field is required."),
        mail: Yup.string().required("This field is required.").email("No is a email valid"),
        phone: Yup.string().matches(regexPhone, { message: "Phone number is not valid" }).required("This field is required."),
        work_area: Yup.string().required("This field is required."),
        company_name: Yup.string().required("This field is required.")
    })

}
const clientSchema = {
    client: Yup.object().shape({
        name: Yup.string().required("This field is required."),
        website: Yup.string().required("This field is required."),
        phone: Yup.string().matches(regexPhone, { message: "Phone number is not valid" }).required("This field is required."),
        country: Yup.object().required("This field is required."),
        region: Yup.object().required("This field is required."),
        clientType: Yup.object().required("This field is required."),
        address: Yup.string().required("This field is required."),
        state: Yup.boolean()

    })

}
const validateNumberDecimalOnexist = Yup.lazy(value => {
    if (typeof value === "number") {
        return Yup.number().min(0).max(100).positive().required("This field is required.");
    }
    return Yup.string().required("This field is required.")
})

const clientSchemaEdit = {
    client: Yup.object().shape({
        clientCommisions: Yup.object().shape({
            services: validateNumberDecimalOnexist,
            cruices: validateNumberDecimalOnexist,
            hotels: validateNumberDecimalOnexist,
            fundationDonation: validateNumberDecimalOnexist
        })
    })
}

const accountAddSchema = { 
    bookingId: Yup.number().required("This field is required."),
    destinationId: Yup.number().required("This field is required."),
    supplierId: Yup.number().required("This field is required."),
    rateId: Yup.number().required("This field is required."),
    date: Yup.string(),
    accountName: Yup.string(),
};

export {
    userSchema,
    contactSchema,
    clientSchema,
    clientSchemaEdit,
    accountAddSchema
}
