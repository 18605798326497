import React, { useContext, useEffect, useRef } from 'react';
import { useFormik, Formik, Form, } from 'formik';
import * as Yup from 'yup';
import classes from "./Legal.module.scss";
import { TextInputArea, FileUpload, FileView } from "../../index";
import { context } from '../../../utils/CustomHook';
import _ from 'lodash';
import { useField } from 'formik'
import axios from 'axios';
import Uri from '../../../utils/API/uris';
import moment from 'moment'


const Legal = ({ data }) => {


    return (
        <div className={`${classes.containerLegal} `} >
            <div className={classes.containerText}>

                <h2>{data.client.name}</h2>

                <TextInputArea className={classes.containerTextArea} cols={"100"} rows={"18"} name={"client.legalDescription"} />

            </div>

            <div className={classes.containerFiles} >
                {data.client.attachments.map((file, index) => <FileView type={"client"} file={file} index={index} key={file.id} />)}
            </div>
            <FileUpload name={"client.attachments"} entity={"client"} id={data.client.id} />


        </div>
    )
}


export default Legal;