import { Formik } from "formik";
import React from "react";
import { serviceBooking } from "../../../../../utils/API/apiService";
import ButtonForm from "../../../../Buttons/ButtonForm/ButtonForm";
import { Modal } from "../../../../Modal/Components/Components";
import classes from "./AccountRowDetail.module.scss";
import { AccountAccomodationForm } from "./DetailForms/AccountAccomodationForm";
import { AccountFlightForm } from "./DetailForms/AccountFlightForm";
import RateAccount from "./RateAccount/RateAccount";

export const AccountRowDetail = ({ accounts, paxs, flights, supplier, loading, bookingId, bedTypes, reloadAccounts }) => {
    const type = supplier?.supplierType?.name;
    const mapToSelect = (arr) => arr?.map((l) => ({ value: l.id, label: l.name }));
    const optionsPaxs = mapToSelect(paxs);
    const optionBedTypes = mapToSelect(bedTypes);

    const optionsFlights = mapToSelect(
        flights.map((f) => ({
            ...f,
            name: `${f.flight_number} ${f.airport_destination.code} ${f.departure_time} ${f.arrival_time}`,
        }))
    );

    const [indexToDelete, setIndexToDelete] = React.useState(-1);

    const handleDelete = () => {
        if(indexToDelete === -1) return;
        const booking_rate = accounts[indexToDelete];

        setIndexToDelete(-1);
        loading(true);
        serviceBooking("deleteBookingRates", { booking_rate })
            .then(async () => await reloadAccounts())
            .catch((e) => {})
            .finally(() => loading(false));
    }
    return (
        <div className={classes.accountRowDetail}>
            <div className={classes.title}>
                <h4>{supplier.name}</h4>
            </div>
            {accounts?.map((account, i) => (
                <Formik
                    key={i}
                    initialValues={{
                        id: account.id,
                        booking: { id: bookingId },
                        rate_name: account.rate_name,
                        rate_data: account.rate_data,
                        cost: account.cost,
                        date: account.date,
                        sale: account.sale,
                        // sale:
                        //     (account.alicuota / 100) * account.cost +
                        //     (account.markup / 100) * account.cost +
                        //     account.cost,
                        alicuota: account.alicuota,
                        markup: account.markup,
                        supplier_rate: { id: account.supplier_rate.id },
                        booking_rate_state: {
                            id: account.booking_rate_state.id,
                        },
                        currency: supplier.currency.iso,
                        paxs: optionsPaxs,
                        flights: optionsFlights,
                        bedTypes: optionBedTypes,
                    }}
                    onSubmit={(data) => {
                        data.rate_data = JSON.stringify(data.rate_data, "");
                        loading(true);
                        serviceBooking("updateBookingRates", { booking_rate: data })
                            .then(async () => await reloadAccounts())    
                            .catch((e) => {})
                            .finally(() => loading(false));
                    }}
                >
                    {({ handleSubmit }) => (
                        <div className={classes.formDetail}>
                            {FormByType(type)}
                            <RateAccount />
                            <div className={classes.containerActionsRowRate}>
                                <i className={`icon-edit ${classes.iconEdit}`} onClick={handleSubmit} />
                                <i className={`${classes.deleteIcon}`} onClick={() => setIndexToDelete(i)} />
                            </div>
                        </div>
                    )}
                </Formik>
            ))}

            <Modal show={indexToDelete !== -1} sizes={"medium"}>
                <div className={classes.modalBox}>
                    <p>¿Are you sure to delete  account '{accounts[indexToDelete]?.rate_name}'?</p>
                    <div className={classes.containerButtonModal}>
                        <ButtonForm type="button" className={classes.buttonModalDelete} label="Cancel" handleClik={() => setIndexToDelete(-1)} />
                        <ButtonForm type="button" className={classes.buttonModalDelete}  label="Confirm" handleClik={handleDelete}/>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

const FormByType = (type) =>
    ({
        Cruices: <AccountFlightForm />,
        Accomodation: <AccountAccomodationForm />,
    }[type] || <AccountFlightForm />);
