import React, { useContext } from 'react';
import { Row } from '../../Grid/Grid';
import { Formik } from 'formik';
import classes from './Filter.module.scss';
import { Select } from '../../NewComponents/index';
import { context } from '../../../utils/CustomHook';
import _ from 'lodash';

const SuppliersFilter = ({ handleFilterValues, initialValues = {}, formRef, closeModal, ...props }) => {



    const { DestinationActive } = useContext(context);

    const mapListSelect = (list, nameLabel) => {
        return _.map(list, element => ({ value: element, label: element[nameLabel] }))
    }

    const listState = [{ value: 1, label: "Active" }, { value: 0, label: "Inactive" }]

    const handleSubmitForm = (value) => {
        handleFilterValues(value)
        closeModal();
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmitForm}
            innerRef={formRef}
            validateOnChange={false}
            validateOnBlur={false}
            enableReinitialize={true}
        >

            {({ handleSubmit, handleChange, handleBlur, values, setFieldValue, errors }) => {
                return (
                    <div className={classes.containerForm}>
                        <Row>
                            <Select
                                hiddenBorder={true}
                                placeholder={"Select Destination"}
                                list={mapListSelect(DestinationActive, "name")}
                                onChange={(value) => setFieldValue('destination', value)}
                                value={_.get(values, 'destination')}
                                clear={true}
                            />
                            <Select
                                hiddenBorder={true}
                                placeholder={"Select Supplier Type"}
                                list={listState}
                                onChange={(value) => setFieldValue('state', value)}
                                value={_.get(values, 'state')}
                                clear={true}
                             
                                mode={"classic"}
                                
                            />
                        </Row>

                    </div>)
            }}
        </Formik>
    )
}

export default SuppliersFilter