import React, { useState, useEffect } from 'react';
import classes from './Dashboard.module.scss';


const Dashboard = (props) => {


  return (
    <div >
      Dashboard
    </div>
  )
}

export default Dashboard;
